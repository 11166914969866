import React, { useEffect, useState } from "react";
import ButtonPrimary from "../buttons/ButtonPrimary";
import ButtonLight from "../buttons/buttonLight";
import "../safer-gambling/SaferGambling.css";
import { redirect } from "react-router-dom";
import axios from "axios";
import Parser from "html-react-parser";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";

export default function RealityCheck(props) {
  const [realityCheckStaticContent, setRealityCheckStaticContent] =
    useState("");
  const [loader, showLoader, hideLoader] = useLoading();
  const [alert, alert_notify] = useAlert();
  const [realityCheck, setRealityCheck] = useState([]);
  var body, headers;
  var url = process.env.REACT_APP_API_URL;
  var session_key = localStorage.getItem("session_key");

  const getStaticContent = () => {
    var static_content_url =
      "" + url + "/api/static_content/get_all_static_content";
    if (props.type === "description") {
      body = {
        page_meta_key: "sg_reality_check",
      };
      headers = {};
    } else {
      body = {
        page_meta_key: "sg_reality_check",
      };
      headers = {
        session_key: session_key,
      };
    }
    showLoader();
    axios
      .post(static_content_url, body, { headers })
      .then(function (response) {
        if (response.status === 200) {
          var content = response.data.Data.content.page_content;
          setRealityCheckStaticContent(content);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          redirect("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  const submitRealityCheck = () => {
    var select_time = document.getElementById("select_time_val").value;
    if (select_time === "1") {
      body = {
        duration: select_time,
        unit: "HOURS",
      };
    } else {
      body = {
        duration: select_time,
        unit: "MINUTES",
      };
    }
    const headers = {
      session_key: session_key,
    };
    var url = `${process.env.REACT_APP_API_URL}/api/safer_gambling/set_reality_check`;
    showLoader();
    axios
      .post(url, body, { headers })
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          redirect("/submited/safer_gambling/realitycheck");
        } else if (response.status === 401) {
          redirect("/login");
        } else {
          console.log(response.error);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          redirect("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Message);
        }
        hideLoader();
      });
  };

  const getRealityCheckInfo = () => {
    var reality_check_url = "" + url + "/api/safer_gambling/get_reality_check";

    var body = {};
    var headers = { session_key: session_key };
    showLoader();
    axios
      .post(reality_check_url, body, { headers })
      .then(function (response) {
        if (response.status === 200) {
          var data = response.data;
          if (data.Data.Reality_check_info !== undefined) {
            setRealityCheck(data.Data.Reality_check_info[0]);
          }
        }
        hideLoader();
      })
      .catch((err) => {
        // console.log(err.response)
        if (err.response.status === 401) {
          redirect("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Message);
        }
        hideLoader();
      });
  };

  const resetRealityCheck = () => {
    var reset_realitycheck_url =
      "" + url + "/api/safer_gambling/delete_reality_check";

    var body = {};
    var headers = { session_key: session_key };
    showLoader();
    axios
      .post(reset_realitycheck_url, body, { headers })
      .then((response) => {
        if (response.status === 200) {
          window.location.reload();
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          redirect("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.ServiceName);
        }
        hideLoader();
      });
  };

  useEffect(() => {
    getStaticContent();
    if (props.type !== "description") {
      getRealityCheckInfo();
    }
  }, []);

  return (
    <React.Fragment>
      <div className="reality_check safer_gambling_container mt-3 primary-background">
        {props.type === "gambling" ? (
          <div className="gambling_container">
            <div className="safer_title">
              {Parser(realityCheckStaticContent)}
            </div>
            <div className="gambling_center">
              <div className="gambling_select mb-4 mt-4">
                <label className="text-white">
                  {!props.labels.safer_gambling_period
                    ? "Period of Time"
                    : props.labels.safer_gambling_period.label_name}
                </label>
                <select id="select_time_val">
                  <option value="0">Select Time Here </option>
                  <option value="1">1 Hour</option>
                  <option value="30">30 Minutes</option>
                  <option value="20">20 Minutes</option>
                </select>
              </div>
              <div className="gambling_btns">
                <ButtonLight
                  classValue="btn_light btn_reset_deposit_limit"
                  textValue={
                    !props.labels.safer_gambling_reset
                      ? "Reset"
                      : props.labels.safer_gambling_reset.label_name
                  }
                  onClick={resetRealityCheck}
                />
                <ButtonPrimary
                  classValue="btn_primary btn_submit_deposit_limit"
                  textValue={
                    !props.labels.safer_gambling_submit
                      ? "Submit"
                      : props.labels.safer_gambling_submit.label_name
                  }
                  onClick={submitRealityCheck}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="safer_title">{Parser(realityCheckStaticContent)}</div>
        )}
      </div>
      {loader}
      {alert}
    </React.Fragment>
  );
}
