import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Moment from "react-moment";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";

export default function Transactions(props) {
  const [userTransactions, setUserTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalTransactions, setTotalTransactions] = useState();
  const [activePage, setActivePage] = useState(1);
  const navigate = useNavigate();
  const [loader, showLoader, hideLoader] = useLoading();
  const [alert, alert_notify] = useAlert();
  var totalPages = 0;
  var pagesLength = 0;
  var pagesArray = [];

  var titles = [
    "Competition ID",
    "Competition",
    "Description/Result",
    "Payment Type",
    "Type",
    "Amount",
    "Date",
  ];
  var url = process.env.REACT_APP_API_URL;
  var session_key = localStorage.getItem("session_key");

  const getTotalTransactions = () => {
    var transactions_url =
      "" + url + "/api/my_account/user_transaction_history";

    var body = {};

    const headers = {
      session_key: session_key,
    };
    showLoader();
    axios
      .post(transactions_url, body, { headers })
      .then(function (response) {
        if (response.status === 200) {
          setTotalTransactions(response.data.Data.total);
          setLoading(false);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
        setLoading(true);
      });
  };

  const fetchTransactions = (e) => {
    var index = e.target.getAttribute("data-id");
    var mode = e.currentTarget.getAttribute("data-mode");

    if (document.getElementById("select_payment_for") !== null) {
      var selected_payment_for =
        document.getElementById("select_payment_for").value;
    } else {
      var selected_payment_for = -1;
    }

    if (document.getElementById("select_payment_type") !== null) {
      var selected_payment_type = document.getElementById(
        "select_payment_type"
      ).value;
    } else {
      var selected_payment_type = -1;
    }

    if (!mode) {
      var data = {
        current_page: index,
        items_perpage: 10,
        payment_for: selected_payment_for,
        payment_type: selected_payment_type,
      };
      getUserTransactions(data);
      setActivePage(Number(index));
    } else {
      var active_index = document
        .querySelector(".page_number.transaction_page.active")
        .getAttribute("data-id");
      if (mode == "prev") {
        if (active_index > 1) {
          var new_index = Number(active_index) - 1;
          var data = {
            current_page: new_index,
            items_perpage: 10,
            payment_for: selected_payment_for,
            payment_type: selected_payment_type,
          };
          getUserTransactions(data);
          setActivePage(new_index);
        } else {
          var new_index = 1;
        }
      } else {
        if (active_index < pagesLength) {
          var new_index = Number(active_index) + 1;
          var data = {
            current_page: new_index,
            items_perpage: 10,
            payment_for: selected_payment_for,
            payment_type: selected_payment_type,
          };
          getUserTransactions(data);
          setActivePage(new_index);
        } else {
          var new_index = pagesLength;
        }
      }
    }
  };

  const getUserTransactions = (data) => {
    if (document.getElementById("select_payment_for") !== null) {
      var selected_payment_for =
        document.getElementById("select_payment_for").value;
    } else {
      var selected_payment_for = -1;
    }

    if (document.getElementById("select_payment_type") !== null) {
      var selected_payment_type = document.getElementById(
        "select_payment_type"
      ).value;
    } else {
      var selected_payment_type = -1;
    }
    var transactions_url =
      "" + url + "/api/my_account/user_transaction_history";

    if (!data) {
      var body = {
        current_page: 1,
        items_perpage: 10,
        payment_for: selected_payment_for,
        payment_type: selected_payment_type,
      };
    } else {
      var body = data;
    }

    const headers = {
      session_key: session_key,
    };
    showLoader();
    axios
      .post(transactions_url, body, { headers })
      .then(function (response) {
        if (response.status === 200) {
          var data = response.data.Data.result;
          setUserTransactions(data);
          setTotalTransactions(response.data.Data.total);
          setLoading(false);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  useEffect(() => {
    getTotalTransactions();
    getUserTransactions();
  }, []);

  totalPages = totalTransactions;
  pagesLength = Math.ceil(totalPages / 10);
  for (var i = 0; i < pagesLength; i++) {
    pagesArray.push(i + 1);
  }
  return (
    <React.Fragment>
      <div className="transactions_container">
        <div className="transactions_center">
          <div className="row mt-row justify-content-end">
            <div className="col-12 col-xl-3 col-lg-3">
              <select
                className="account_input "
                id="select_payment_for"
                onChange={() => getUserTransactions()}
              >
                <option value="-1">
                  {!props.labels.my_account_all_types
                    ? "All Types"
                    : props.labels.my_account_all_types.label_name}
                </option>
                <option value="1">Entry Fee</option>
                <option value="2">Deposit</option>
                <option value="3">Withdrawal</option>
                <option value="4">Free Refund</option>
                <option value="5">Winnings</option>
                <option value="6">Credit Adjust</option>
              </select>
            </div>
            <div className="col-12 col-xl-3 col-lg-3">
              <select
                className="account_input"
                id="select_payment_type"
                onChange={() => getUserTransactions()}
              >
                <option value="-1">
                  {!props.labels.my_account_transactiontype
                    ? "Transaction Type"
                    : props.labels.my_account_transactiontype.label_name}
                </option>
                <option value="1">Debit</option>
                <option value="2">Credit</option>
              </select>
            </div>
          </div>
          <div className="account_transactions">
            <div className="row transaction titles">
              <div className="transactions_competition_id">
                {!props.labels.my_account_competition_id
                  ? "Competition ID"
                  : props.labels.my_account_competition_id.label_name}
              </div>
              <div className="col">
                {!props.labels.my_account_competition
                  ? "Competition"
                  : props.labels.my_account_competition.label_name}
              </div>
              <div className="col">
                {!props.labels.my_account_desc_result
                  ? "Description/Result"
                  : props.labels.my_account_desc_result.label_name}
              </div>
              <div className="transactions_payment_type">
                {!props.labels.my_account_payment_type
                  ? "Payment Type"
                  : props.labels.my_account_payment_type.label_name}
              </div>
              <div className="transactions_type">
                {!props.labels.my_account_type
                  ? "Type"
                  : props.labels.my_account_type.label_name}
              </div>
              <div className="transactions_amount">
                {!props.labels.my_account_amount
                  ? "Amount"
                  : props.labels.my_account_amount.label_name}
              </div>
              <div className="col" style={{ maxWidth: "110px" }}>
                {!props.labels.my_account_date
                  ? "Date"
                  : props.labels.my_account_date.label_name}
              </div>
            </div>
            {!loading
              ? userTransactions.map((value, index) => {
                  var payment_type = "";
                  var transaction_type = "";

                  if (!value.contest_name) {
                    value.contest_name = "-";
                  }

                  if (!value.contest_uid) {
                    value.contest_uid = "-";
                  }

                  if (value.payment_for === "1") {
                    payment_type = "Entry Fee";
                  } else if (value.payment_for === "2") {
                    payment_type = "Deposit - " + value.transaction_status;
                  } else if (value.payment_for === "3") {
                    payment_type = "Withdrawal- " + value.transaction_status;
                  } else if (value.payment_for === "4") {
                    payment_type = "Fee Refund";
                  } else if (value.payment_for === "5") {
                    payment_type = "Won Game";
                  } else if (value.payment_for === "6") {
                    payment_type = "Credit Adjust";
                  }

                  if (value.payment_type === "1") {
                    transaction_type = "Debit";
                  } else if (value.payment_type === "2") {
                    transaction_type = "Credit";
                  }

                  return (
                    <div
                      className="row transaction m-0"
                      row-index={index}
                      key={index}
                      data-contestid={value.contest_id}
                    >
                      <div className="transactions_competition_id d-flex">
                        <span className="transaction_title col-6 col-lg-12 col-xl-12 text-white">
                          {titles[0]}
                        </span>
                        <span className="transaction_value col-6 col-lg-12 col-xl-12 text-white">
                          {value.contest_uid}
                        </span>
                      </div>
                      <div className="col d-flex">
                        <span className="transaction_title col-6 col-lg-12 col-xl-12 text-white">
                          {titles[1]}
                        </span>
                        <span className="transaction_value col-6 col-lg-12 col-xl-12 text-white">
                          {value.contest_name}
                        </span>
                      </div>
                      <div className="col d-flex">
                        <span className="transaction_title col-6 col-lg-12 col-xl-12 text-white">
                          {titles[2]}
                        </span>
                        <span className="transaction_value col-6 col-lg-12 col-xl-12 text-white">
                          {value.description}
                        </span>
                      </div>
                      <div className="transactions_payment_type d-flex">
                        <span className="transaction_title col-6 col-lg-12 col-xl-12 text-white">
                          {titles[3]}
                        </span>
                        <span className="transaction_value col-6 col-lg-12 col-xl-12 text-white">
                          {payment_type}
                        </span>
                      </div>
                      <div className="transactions_type d-flex">
                        <span className="transaction_title col-6 col-lg-12 col-xl-12 text-white">
                          {titles[4]}
                        </span>
                        <span className="transaction_value col-6 col-lg-12 col-xl-12 text-white">
                          {transaction_type}
                        </span>
                      </div>
                      <div className="transactions_amount d-flex">
                        <span className="transaction_title col-6 col-lg-12 col-xl-12 text-white">
                          {titles[5]}
                        </span>
                        <span className="transaction_value col-6 col-lg-12 col-xl-12 text-white">
                          {value.total_transaction_amount}
                        </span>
                      </div>
                      <div className="col d-flex transaction_datetime">
                        <span className="transaction_title col-6 col-lg-12 col-xl-12 text-white">
                          {titles[6]}
                        </span>
                        <span className="transaction_value col-6 col-lg-12 col-xl-12 text-white">
                          <Moment format="DD MMM YYYY">
                            {value.created_date}
                          </Moment>
                        </span>
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
          {pagesArray.length > 1 ? (
            <div className="pagination d-flex justify-content-end mt-3">
              <div
                className="page"
                data-mode="prev"
                onClick={fetchTransactions}
              >
                <span>
                  <img src="/assets/icons/arrow-left.svg" alt="arrow-left" />
                </span>
              </div>
              {pagesArray.map((row, index) => {
                return (
                  <div
                    className={
                      activePage === index + 1
                        ? "page page_number transaction_page active"
                        : "page page_number transaction_page"
                    }
                    key={index}
                    data-id={index + 1}
                    onClick={fetchTransactions}
                  >
                    {row}
                  </div>
                );
              })}
              <div
                className="page"
                data-mode="next"
                onClick={fetchTransactions}
              >
                <span>
                  <img src="/assets/icons/arrow-right.svg" alt="arrow-right" />
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {loader}
    </React.Fragment>
  );
}
