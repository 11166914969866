import React, { useEffect, useState } from "react";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import ButtonPrimary from "../buttons/ButtonPrimary";
import "./CreateClubFundraiser.css";
import { useNavigate, useParams } from "react-router-dom";
import ProfileAccount from "../account/ProfileAccount";
import axios from "axios";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import SuccesNotification from "../../hooks/SuccesNotification";

export default function CreateClubFundraiser() {
  var url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  // let payoutsTemp = {
  //   entryFee: 1,
  //   entrants: 2,
  //   maxPercent: 100,
  //   position: {
  //     first: 50,
  //     second: 25,
  //     third: 15,
  //     club: 10,
  //   },
  //   value: {
  //     first: "",
  //     second: "",
  //     third: "",
  //     club: "",
  //   },
  // };

  var session_key = localStorage.getItem("session_key");
  const { sportId, leagueId } = useParams();  
  const [loader, showLoader, hideLoader] = useLoading();
  const [leagues, setLeagues] = useState([]);
  // const [payouts, setPayouts] = useState(payoutsTemp);
  const [sports, setSports] = useState([]);
  const [alert, alert_notify] = useAlert();
  const [loading, setLoading] = useState(true);

  const [successStatus, setSuccesStatus] = useState(false);
  const [validSport, setValidSport] = useState(false);
  const [validLeague, setValidLeague] = useState(false);
  const [validOrgName, setValidOrgName] = useState(false);
  const [validEntryFee, setValidEntryFee] = useState(false);
  const [validAccountName, setValidAccountName] = useState(false);
  const [validSortCode, setValidSortCode] = useState(false);
  const [validAccNumb, setValidAccNumb] = useState(false);
  const [validCompTitle, setValidCompTitle] = useState(false);
  const [currency, setCurrency] = useState('NZD')
  const [cfLabels, setCfLabels] = useState([])

  const [entryFee, setEntryFee] = useState(1);
  const [entrants, setEntrants] = useState(2);
  const [sportsFirst, setSportsFirst] = useState(50);
  const [sportsSecond, setSportsSecond] = useState(25);
  const [sportsThird, setSportsThird] = useState(15);
  const [sportsClub, setSportsClub] = useState(10);

  const getClubFundraiserData = () => {
    var create_club_fundraiser_sports = document.getElementById(
      "create_club_fundraiser_sports"
    ).value;
    var create_club_fundraiser_leagues = document.getElementById(
      "create_club_fundraiser_leagues"
    ).value;
    var organisation_name_valid =
      document.getElementById("organisation_name").value;
    var account_name_valid = document.getElementById("account_name").value;
    var sort_code_valid = document.getElementById("sort_code").value;
    var account_number_valid = document.getElementById("account_number").value;
    var create_club_fundraiser_entry_fee = document.getElementById("create_club_fundraiser_entry_fee").value;
    var competition_title_valid =
      document.getElementById("competition_title").value;

    if (organisation_name_valid !== "") {
      setValidOrgName(true);
    } else {
      setValidOrgName(false);
    }

    if (account_name_valid !== "") {
      setValidAccountName(true);
    } else {
      setValidAccountName(false);
    }

    if (sort_code_valid !== "") {
      setValidSortCode(true);
    } else {
      setValidSortCode(false);
    }

    if (account_number_valid !== "") {
      setValidAccNumb(true);
    } else {
      setValidAccNumb(false);
    }

    if (competition_title_valid !== "") {
      setValidCompTitle(true);
    } else {
      setValidCompTitle(false);
    }

    if (create_club_fundraiser_sports !== "0") {
      setValidSport(true);
    } else {
      setValidSport(false);
    }

    if (create_club_fundraiser_leagues !== "0") {
      setValidLeague(true);
    } else {
      setValidLeague(false);
    }

    if (create_club_fundraiser_entry_fee !== "0") {
      setValidEntryFee(true);
    } else {
      setValidEntryFee(false);
    }
  };

  const calculateMaxPayout = (percentage) => {
    return (((entryFee * entrants) / 100) * percentage).toFixed(2);
}


  const submitCreateClubFundraiser = (e) => {
    e.preventDefault();
    var create_club_fundraiser_url =
      "" + url + "/api/create_contest/request_contest";

    var organisation_name = document.getElementById("organisation_name").value;
    var account_name = document.getElementById("account_name").value;
    var sort_code = document.getElementById("sort_code").value;
    var account_number = document.getElementById("account_number").value;
    var competition_title = document.getElementById("competition_title").value;
    var create_club_fundraiser_sports = document.getElementById(
      "create_club_fundraiser_sports"
    ).value;
    var create_club_fundraiser_leagues = document.getElementById(
      "create_club_fundraiser_leagues"
    ).value;
    var create_club_fundraiser_entry_fee = document.getElementById(
      "create_club_fundraiser_entry_fee"
    ).value;
    var create_club_fundraiser_entrants = document.getElementById(
      "create_club_fundraiser_entrants"
    ).value;
    var create_club_fundraiser_sports_first = document.getElementById(
      "create_club_fundraiser_sports_first"
    ).value;
    var create_club_fundraiser_sports_second = document.getElementById(
      "create_club_fundraiser_sports_second"
    ).value;
    var create_club_fundraiser_sports_third = document.getElementById(
      "create_club_fundraiser_sports_third"
    ).value;
    var create_club_fundraiser_club = document.getElementById(
      "create_club_fundraiser_club"
    ).value;


    if (create_club_fundraiser_entry_fee === "") {
      create_club_fundraiser_entry_fee = 1;
    }
    if (create_club_fundraiser_entrants === "") {
      create_club_fundraiser_entrants = 2;
    }
    if (create_club_fundraiser_sports_first === "") {
      create_club_fundraiser_sports_first = 0.5;
    }
    if (create_club_fundraiser_sports_second === "") {
      create_club_fundraiser_sports_second = 0.25;
    }
    if (create_club_fundraiser_sports_third === "") {
      create_club_fundraiser_sports_third = 0.15;
    }
    if (create_club_fundraiser_club === "") {
      create_club_fundraiser_club = 0.10;
    }
    if(Number(sportsFirst) + Number(sportsSecond) + Number(sportsThird) + Number(sportsClub) !== 100){
      alert_notify("error", "Payouts must equal 100%");
      return false;
    }

    var league_el = document.getElementById("create_club_fundraiser_leagues");
    var sport_el = document.getElementById("create_club_fundraiser_sports");
    create_club_fundraiser_leagues =
      league_el.options[league_el.selectedIndex].getAttribute("data-id");
    create_club_fundraiser_sports =
      sport_el.options[league_el.selectedIndex].getAttribute("data-id");

    if (
      validOrgName &&
      validAccountName &&
      validSortCode &&
      validAccNumb &&
      validCompTitle &&
      validSport &&
      validLeague && validEntryFee
    ) {
      var body = {
        organisation_name: organisation_name,
        bankaccount_name: account_name,
        bankaccount_number: sort_code,
        bankaccount_sortcode: account_number,
        competition_title: competition_title,
        contest_type: "club",
        league_id: create_club_fundraiser_leagues,
        entry_fee: create_club_fundraiser_entry_fee,
        num_of_entrants: create_club_fundraiser_entrants,
        payout_1st: create_club_fundraiser_sports_first,
        payout_2nd: create_club_fundraiser_sports_second,
        payout_3rd: create_club_fundraiser_sports_third,
        payout_club: create_club_fundraiser_club,
        sport_id: create_club_fundraiser_sports,
        currency : currency
      };


      var headers = {
        session_key: session_key,
      };
      showLoader();
      axios
        .post(create_club_fundraiser_url, body, { headers })
        .then((response) => {
          if (response.status === 200) {
            setSuccesStatus(true);
            alert_notify(
              "success",
              "Club Fundraiser request has been made successfully."
            );
          }
          hideLoader();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate("/login");
            localStorage.removeItem("session_key");
          } else {
            alert_notify("error", err.response.data.Message);
          }
          hideLoader();
        });
    } else {
      alert_notify("error", "All fields required !");
    }
  };

  const handleSportChange = () => {
    var leagues_html = `<option value="0">League</option>`;
    var sport_id = document.getElementById(
      "create_club_fundraiser_sports"
    ).value;
    var sport_leagues = leagues.filter((row) => {
      return row.sport_id === sport_id;
    });
    sport_leagues.map((league, index) => {
      return (leagues_html += `<option data-id="${league.league_id}" value="${league.league_id}" key="${index}">${league.league_name}</option>`);
    });
    document.getElementById("create_club_fundraiser_leagues").innerHTML =
      leagues_html;
  };

  const clubFundraiserLabels = () =>{
    var cf_labels_url = ""+url+"/api/static_content/get_all_labels"
    var body = {
      "page_name" : "create club fundraiser"
    }
    showLoader()
    axios.post(cf_labels_url, body)
    .then((response) =>{
      if(response.status === 200){
        var data = response.data.Data.content;
        setCfLabels(data)
      }
      hideLoader()
    })
    .catch((err)=>{
      console.log(err)
      hideLoader()
    })
  }

  useEffect(() => {
    clubFundraiserLabels()
    var session_key = localStorage.getItem("session_key");
    var view_all_sport_url = `${url}/api/common/sidebar`;

    const body = {};
    const headers = {
      session_key: session_key,
    };
    showLoader();
    axios
      .get(view_all_sport_url, body, { headers })
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          data.sort((a, b) => (a.sport_name > b.sport_name ? 1 : -1));
          setSports(data);
          var leagues_array = [];
          for (var i = 0; i < data.length; i++) {
            var row = data[i];
            var states = row.leagues;
            if (states != null) {
              for (const property in states) {
                for (var k = 0; k < states[property].length; k++) {
                  leagues_array.push(states[property][k]);
                  setLeagues(leagues_array);
                  setLoading(false);
                }
              }
            }
          }
        }
        hideLoader();
      })
      .catch((err) => {
        hideLoader();
        console.log(err);
        navigate("/login");
        localStorage.removeItem("session_key");
      });
    setSuccesStatus(false);
  }, [leagueId]);

  // const calcPayouts = (payouts) => {
  //   let type = e.target.getAttribute('data-type');
  //   let value = e.target.value;
  //   payoutsTemp = { ...payouts };
  //   var entryFeeOldValue = document.querySelector('#create_club_fundraiser_entry_fee').getAttribute('data-oldvalue')
  //   switch (type) {
  //     case "entryfee":
  //       payoutsTemp.entryFee = Number(value);
  //       e.target.setAttribute('data-oldvalue', Number(value))
  //       break;
  //     case "entrants":
  //       payoutsTemp.entrants = (Number(value));
  //       break;
  //     case "sports_first":
  //       payoutsTemp.value.first = Number(value);
  //       payoutsTemp.position.first = Number(value)
  //       break;
  //     case "sports_second":
  //       payoutsTemp.value.second = Number(value);
  //       payoutsTemp.position.second = Number(value)

  //       break;
  //     case "sports_third":
  //       payoutsTemp.value.third = Number(value);
  //       payoutsTemp.position.third = Number(value)
  //       break;
  //     case "sports_club":
  //       payoutsTemp.value.club = Number(value);
  //       payoutsTemp.position.club = Number(value)

  //       break;
  //     default:
  //       break;
  //   }
    
  //   if (payoutsTemp.entryFee && payoutsTemp.entryFee > 0) {
  //       if (type === "entryfee" ) {
  //           payoutsTemp.value.first =
  //             (payoutsTemp.entryFee * payoutsTemp.position.first) / 100;
        
  //           payoutsTemp.value.second =
  //             (payoutsTemp.entryFee * payoutsTemp.position.second) / 100;
        
  //           payoutsTemp.value.third =
  //             (payoutsTemp.entryFee * payoutsTemp.position.third) / 100;
        
  //           payoutsTemp.value.club =
  //             (payoutsTemp.entryFee * payoutsTemp.position.club) / 100;
        
  //         }else if(type === 'entrants'){
  //             payoutsTemp.entryFee = Number(entryFeeOldValue * value).toFixed(2);
          
  //             payoutsTemp.value.first =
  //             (payoutsTemp.entryFee * payoutsTemp.position.first) / 100;
          
  //             payoutsTemp.value.second =
  //         (payoutsTemp.entryFee * payoutsTemp.position.second) / 100;
    
  //       payoutsTemp.value.third =
  //         (payoutsTemp.entryFee * payoutsTemp.position.third) / 100;
    
  //       payoutsTemp.value.club =
  //         (payoutsTemp.entryFee * payoutsTemp.position.club) / 100;
  //     }else {
  //         if(type === 'sports_first'){
  //             // var newEntryFee = (Number(value) * 2).toFixed(2)
  //             payoutsTemp.value.first = Number(value);
  //             payoutsTemp.value.second = (Number(value) * Number(payoutsTemp.position.first) ).toFixed(2);
  //             payoutsTemp.value.third = (Number(value) * 0.3).toFixed(2);
  //             payoutsTemp.value.club = (Number(value) * 0.2).toFixed(2);
  //           }
  //           if(type === 'sports_second'){
  //               // var newEntryFee = (Number(value) * 4).toFixed(2)
  //               payoutsTemp.value.second = Number(value);
  //               payoutsTemp.value.first = (Number(value) * 2).toFixed(2);
  //               payoutsTemp.value.third = (Number(value) * 0.6).toFixed(2);
  //               payoutsTemp.value.club = (Number(value) * 0.4).toFixed(2);
  //             }
  //             if(type === 'sports_third'){
  //                 var newEntryFee = ((Number(value) * 4) /  0.6).toFixed(2)
  //                 payoutsTemp.value.third = Number(value);
  //                 payoutsTemp.value.first = (Number(value) / 0.3).toFixed(2);
  //                 payoutsTemp.value.second = (Number(value) / 0.6).toFixed(2);
  //                 payoutsTemp.value.club = (Number(value) / 1.5).toFixed(2);
  //               }
  //               if(type === 'sports_club'){
  //                   var newEntryFee = (Number(value) * 10).toFixed(2)
  //                   payoutsTemp.value.club =  Number(value);
  //                   payoutsTemp.value.first =  (Number(value) / 0.2).toFixed(2);
  //                   payoutsTemp.value.second =  (Number(value) / 0.4).toFixed(2);
  //                   payoutsTemp.value.third =  (Number(value) * 1.50).toFixed(2);
  //                 }
  //               }
  //             }
  // };

  const handleCurrencyChange = (e) =>{
    var currency = e.target.value
    setCurrency(currency)
  }

  // var initialEntryFee
  // payouts.entryFee !== '' ? initialEntryFee =  Number(payouts.entryFee).toString() : initialEntryFee = 'Entry Fee ('+currency+')'

 
  
  return (
    <React.Fragment>
      <Header />
      <ProfileAccount headerValue={
        !cfLabels.create_club_fundraiser_page_label ? 'CREATE CLUB FUNDRAISER' : cfLabels.create_club_fundraiser_page_label.label_name
      } />
      <div className="create_club_fundraiser_container">
        {successStatus ? (
          <SuccesNotification content="Club Fundraising sign up has been sent successfully." />
        ) : (
        <div className="page_content_center">
          <div className="row m-0 ">
            <div className="club_fundraiser_container_tittle col-12">
              <h5 className="club_fundraiser_tittle">
                Enter your details below to sign up as a club fundraiser so we
                can verify your bank details.
              </h5>
            </div>
          </div>
          <div className="row m-0">
            <div className="col-12">
              <span className="club_fundraiser_details">
                A one-off refundable deposit of <span className="fundraiser_currency_sign">{(currency)}</span>5.00 will need to be made
                to:
                <br />
              </span>
              <br />
              <span className="club_fundraiser_details">
              {
                !cfLabels.create_club_fundraiser_account_name ? 'Account Name' : cfLabels.create_club_fundraiser_account_name.label_name
              }:{" "}
                <span className="club_fundraiser_bold">
                  TPG Technology NZ Limited
                </span>{" "}
                <br /> {
                  !cfLabels.create_club_fundraiser_account_number ? 'Account Number' : cfLabels.create_club_fundraiser_account_number.label_name
                }:{" "}
                <span className="club_fundraiser_bold">12-3148-0156793-00</span>{" "}
                <br />
              </span>
              <br />
              <span className="club_fundraiser_details">
                Once we have verified your account we will set-up the
                competition.
                <br />
              </span>
              <div className="mobile_club_fundraiser_details">
                <span className="club_fundraiser_details">
                  Please be aware that we can only accept payments from{" "}
                  <span className="club_fundraiser_bold ">New Zealand</span>{" "}
                  accounts at this time.
                </span>
              </div>
            </div>
          </div>
          <form id="form_club_fundraiser" onSubmit={submitCreateClubFundraiser}>
            <div className="create_club_fundraiser_info">
              <div className="row mt-row">
                <div className="col-12 col-xl-6 col-lg-6">
                  <input
                    type="text"
                    id="organisation_name"
                    className="organization_name_input"
                    maxLength="50"
                    placeholder={
                      !cfLabels.create_club_fundraiser_organisation_name ? 'Organisation Name' : cfLabels.create_club_fundraiser_organisation_name.label_name
                    }
                  />
                </div>
                <div className="col-12 col-xl-6 col-lg-6">
                  <input
                    type="text"
                    id="account_name"
                    className="account_name_input"
                    maxLength="50"
                    placeholder={
                      !cfLabels.create_club_fundraiser_account_name ? 'Account Name' : cfLabels.create_club_fundraiser_account_name.label_name
                    }
                  />
                </div>
              </div>
              <div className="row mt-row">
                <div className="col-12 col-xl-6 col-lg-6">
                  <input
                    type="text"
                    id="sort_code"
                    className="sort_code_input"
                    maxLength="50"
                    placeholder={
                      !cfLabels.create_club_fundraiser_sort_code ? 'Sort Code' : cfLabels.create_club_fundraiser_sort_code.label_name
                    }
                  />
                </div>
                <div className="col-12 col-xl-6 col-lg-6">
                  <input
                    type="text"
                    id="account_number"
                    className="account_number_input"
                    maxLength="50"
                    placeholder={
                      !cfLabels.create_club_fundraiser_account_number ? 'Account Number' : cfLabels.create_club_fundraiser_account_number.label_name
                    }
                  />
                </div>
              </div>
              <div className="row mt-row">
              <div className="col-12 col-xl-6 col-lg-6">
                  <select 
                    className="currency_select"
                    id="fundraiser_currency"
                    placeholder="Currency"
                    onChange={handleCurrencyChange}
                    >
                      <option value="NZD" selected={currency == 'NZD' ? true : false}>NZD</option>
                      <option value="CAD"  selected={currency == 'CAD' ? true : false}>CAD</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="club_fundraiser_selections">
              <div className="club_fundraiser_select_options_tittle col-12">
                <span className="club_fundraiser_details">
                  Please select one option only from each of the following:
                </span>
              </div>
              <div className="row mt-row">
                <div className="col-12 col-xl-6 col-lg-5">
                  <div className="col-12 mt-4">
                    <input
                      type="text"
                      id="competition_title"
                      className="competition_title_input"
                      maxLength="50"
                      placeholder={
                        !cfLabels.create_club_fundraiser_competition_title ? 'Competition Title' : cfLabels.create_club_fundraiser_competition_title.label_name
                      }
                    />
                  </div>
                  <div className="col-12 mt-4">
                    <select
                      className="create_club_fundraiser_select"
                      id="create_club_fundraiser_sports"
                      onChange={handleSportChange}
                    >
                      <option value="0">
                      {
                        !cfLabels.create_club_fundraiser_sport ? 'Sport' : cfLabels.create_club_fundraiser_sport.label_name
                      }
                      </option>
                      {sports.map((sport, index) => {
                        var selected = sport.sport_id == sportId ? true : false;
                        if (sport.leagues) {
                          return (
                            <option
                              data-id={sport.sport_id}
                              key={index}
                              value={sport.sport_id}
                              selected={selected}
                            >
                              {sport.sport_name}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </div>
                  <div className="col-12 mt-4">
                    <select
                      className="create_club_fundraiser_select"
                      id="create_club_fundraiser_leagues"
                    >
                      <option value="0">
                      {
                        !cfLabels.create_club_fundraiser_league ? 'League' : cfLabels.create_club_fundraiser_league.label_name
                      }
                      </option>
                      {leagues.map((league, index) => {
                        if (league.sport_id == sportId) {
                          var selected =
                            league.league_id == leagueId ? true : false;
                          return (
                            <option
                              data-id={league.league_id}
                              data-value={league.league_id}
                              key={index}
                              selected={selected}
                            >
                              {league.league_name}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </div>
                  <div className="col-12 mt-4">
                    <input
                      type="number"
                      id="create_club_fundraiser_entry_fee"
                      className="create_club_fundraiser_select"
                      placeholder= {
                        !cfLabels.create_club_fundraiser_entryfee ? 'Entry Fee ('+currency+')' : cfLabels.create_club_fundraiser_entryfee.label_name + ' ('+currency+')'
                      }
                      data-type="entryfee"
                      step="1"
                      min="1"
                      onChange={(e) => {
                          return setEntryFee(e.target.value);
                      }}
                    />
            
                  </div>
                  <div className="col-12 mt-4">
                    <input
                    type="number"
                    id="create_club_fundraiser_entrants"
                    className="create_club_fundraiser_select"
                    placeholder={
                      !cfLabels.create_club_fundraiser_entrants ? 'Entrants' : cfLabels.create_club_fundraiser_entrants.label_name
                    }
                    data-type="entrants"
                    step="1"
                    min="2"
                    onChange={(e) => {
                        return setEntrants(e.target.value);
                    }}
                    />
                  </div>
                </div>
                <div className="col-12 col-xl-6 col-lg-7">
                  <div className="col-12 mt-4">
                    <span className={Number(sportsFirst) + Number(sportsSecond) + Number(sportsThird) + Number(sportsClub) === 100 ? '' : 'red_color'}>{
                      !cfLabels.create_club_fundraiser_payouts ? 'Payouts' : cfLabels.create_club_fundraiser_payouts.label_name
                    } {Number(sportsFirst) + Number(sportsSecond) + Number(sportsThird) + Number(sportsClub)}(%)</span>
                  </div>
                  <div className="col-12 mt-4 club_fundraiser_col fundraiser_currency_col">
                    <span className="light_color">{
                        !cfLabels.create_club_fundraiser_1stplace ? '1st Place' : cfLabels.create_club_fundraiser_1stplace.label_name
                      }</span>
                    <input
                    type="number"
                    maxLength="50"
                    id="create_club_fundraiser_sports_first"
                    className="create_club_fundraiser_select_mini mini_margin_left col-xl-4 col-lg-4"
                    placeholder="50"
                    value={sportsFirst}
                    data-type="sports_first"
                    min="0"
                    max="100"
                    onChange={(e) => {
                        return setSportsFirst(e.target.value);
                    }}
                    />

                    <span className="mini_margin_left club_fundraiser_right">
                      <span className="mini_margin_left club_fundraiser_right">{(currency)} {calculateMaxPayout(sportsFirst)}</span>
                    </span>
                  </div>
                  <div className="col-12 mt-4 club_fundraiser_col fundraiser_currency_col">
                    <span className="light_color">{
                        !cfLabels.create_club_fundraiser_2ndplace ? '2nd Place' : cfLabels.create_club_fundraiser_2ndplace.label_name
                      }</span>
                    <input
                      type="number"
                      maxLength="50"
                      id="create_club_fundraiser_sports_second"
                      className="create_club_fundraiser_select_mini mini_margin_left col-xl-4 col-lg-4"
                      placeholder="25"
                      value={sportsSecond}
                      data-type="sports_second"
                      min="0"
                      max="100"
                      onChange={(e) => {
                          return setSportsSecond(e.target.value);
                      }}
                      />
                    <span className="mini_margin_left club_fundraiser_right">
                      <span className="mini_margin_left club_fundraiser_right">{(currency)} {calculateMaxPayout(sportsSecond)}</span> 
                    </span>
                  </div>
                  <div className="col-12 mt-4 club_fundraiser_col fundraiser_currency_col">
                    <span className="light_color">{
                        !cfLabels.create_club_fundraiser_3rdplace ? '3rd Place' : cfLabels.create_club_fundraiser_3rdplace.label_name
                      }</span>
                    <input
                    type="number"
                    maxLength="50"
                    id="create_club_fundraiser_sports_third"
                    className="create_club_fundraiser_select_mini mini_margin_left col-xl-4 col-lg-4"
                    placeholder="15"
                    value={sportsThird}
                    data-type="sports_third"
                    min="0"
                    max="100"
                    onChange={(e) => {
                        return setSportsThird(e.target.value);
                    }}
                    />
                    <span className="mini_margin_left club_fundraiser_right">
                      <span className="mini_margin_left club_fundraiser_right">{(currency)} {calculateMaxPayout(sportsThird)}</span>
                    </span>
                  </div>
                  <div className="col-12 mt-4 club_fundraiser_col">
                    <span className="light_color">{
                        !cfLabels.create_club_fundraiser_club ? 'Club' : cfLabels.create_club_fundraiser_club.label_name
                      }</span>
                    <input
                    type="number"
                    maxLength="50"
                    id="create_club_fundraiser_club"
                    className="create_club_fundraiser_select_mini mini_margin_left col-xl-4 col-lg-4"
                    placeholder="10"
                    value={sportsClub}
                    data-type="sports_club"
                    min="0"
                    max="100"
                    onChange={(e) => {
                        return setSportsClub(e.target.value);
                    }}
                    />
                    <span className="mini_margin_left club_fundraiser_right">
                      <span className="mini_margin_left club_fundraiser_right">{(currency)} {calculateMaxPayout(sportsClub)} Potential Earnings</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-0 mt-4 create_club_fundraiser_btn">
              <div className="col-12">
                <ButtonPrimary
                  classValue="btn_primary btn_create_club_fundraiser"
                  datapath="my_competition"
                  type="submit"
                  form="form_club_fundraiser"
                  textValue={
                    !cfLabels.create_club_fundraiser_submit ? 'Submit' : cfLabels.create_club_fundraiser_submit.label_name
                  }
                  onClick={getClubFundraiserData}
                />
              </div>
            </div>
          </form>
        </div>
         )}
      </div>
      {loader}
      {alert}
      <Footer />
    </React.Fragment>
  );
}
