import React, { useEffect, useState } from "react";
import ButtonPrimary from "../buttons/ButtonPrimary";
import ButtonLight from "../buttons/buttonLight";
import "../safer-gambling/SaferGambling.css";
import { redirect } from "react-router-dom";
import axios from "axios";
import Parser from "html-react-parser";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import moment from "moment";

export default function WagerLimit(props) {
  const [wagerLimitStaticContent, setWagerLimitStaticContent] = useState("");
  const [loader, showLoader, hideLoader] = useLoading();
  const [alert, alert_notify] = useAlert();
  const [wagerDaily, setWagerDaily] = useState([]);
  const [wagerWeekly, setWagerWeekly] = useState([]);
  const [wagerMonthly, setWagerMonthly] = useState([]);
  var body, headers;
  var url = process.env.REACT_APP_API_URL;
  var session_key = localStorage.getItem("session_key");
  var dailyModifiedDate = "";
  var weeklyModifiedDate = "";
  var monthlyModifiedDate = "";
  var daily_hours_duration_wager = "25";
  var weekly_hours_duration_wager = "25";
  var monthly_hours_duration_wager = "25";
  var daily_amount = 0;
  var weekly_amount = 0;
  var monthly_amount = 0;
  var daily_wager_values = [5, 10, 30, 40, 50];
  var weekly_wager_vales = [5, 20, 50, 100, 150];
  var monthly_wager_vales = [10, 20, 50, 100, 150, 200, 250];
  var weekly_wager_filtered_values = [5, 20, 50, 100, 150];
  var monthly_wager_filtered_values = [10, 20, 50, 100, 150, 200, 250];

  const getStaticContent = () => {
    var static_content_url =
      "" + url + "/api/static_content/get_all_static_content";
    if (props.type === "description") {
      body = {
        page_meta_key: "sg_wager_limit",
      };
      headers = {};
    } else {
      body = {
        page_meta_key: "sg_wager_limit",
      };
      headers = {
        session_key: session_key,
      };
    }
    showLoader();
    axios
      .post(static_content_url, body, { headers })
      .then(function (response) {
        if (response.status === 200) {
          var content = response.data.Data.content.page_content;
          setWagerLimitStaticContent(content);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          redirect("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  const getWagerLimitInfo = (wager_type) => {
    var get_wager_limit_url = "" + url + "/api/safer_gambling/get_wager_limit";

    var body = {
      amount: "",
      duration: 1,
      modified_date: "",
      unit: wager_type,
    };

    var headers = { session_key: session_key };
    showLoader();
    axios
      .post(get_wager_limit_url, body, { headers })
      .then(function (response) {
        if (response.status === 200) {
          var data = response.data;
          if (data.Data.Wager_limit_info.length > 0) {
            if (data.Data.Wager_limit_info[0].unit === "DAYS") {
              setWagerDaily(data.Data.Wager_limit_info[0]);
            }
            if (data.Data.Wager_limit_info[0].unit === "MONTHS") {
              setWagerMonthly(data.Data.Wager_limit_info[0]);
            }
            if (data.Data.Wager_limit_info[0].unit === "WEEKS") {
              setWagerWeekly(data.Data.Wager_limit_info[0]);
            }
          }
        }
        hideLoader();
      })
      .catch((err) => {
        // console.log(err.response)
        if (err.response.status === 401) {
          redirect("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Message);
        }
        hideLoader();
      });
  };

  const setWagerLimit = (type, amount) => {
    var set_wager_url = `${url}/api/safer_gambling/set_wager_limit`;
    const headers = {
      session_key: session_key,
    };
    body = {
      amount: amount,
      duration: 1,
      modified_date: "",
      unit: type,
    };
    showLoader();
    axios
      .post(set_wager_url, body, { headers })
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          redirect("/submited/safer_gambling/wager");
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          redirect("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.GlobalError);
        }
        hideLoader();
      });
  };

  const submitWagerLimit = () => {
    var monthly_limit_wager = document.getElementById(
      "wager_limit_monthly_val"
    ).value;
    var weekly_limit_wager = document.getElementById(
      "wager_limit_weekly_val"
    ).value;
    var daily_limit_wager = document.getElementById(
      "wager_limit_daily_val"
    ).value;

    if (daily_hours_duration_wager > "24" && daily_limit_wager !== "0") {
      setWagerLimit("DAYS", daily_limit_wager);
    }
    if (weekly_hours_duration_wager > "24" && weekly_limit_wager !== "0") {
      setWagerLimit("WEEKS", weekly_limit_wager);
    }
    if (monthly_hours_duration_wager > "24" && monthly_limit_wager !== "0") {
      setWagerLimit("MONTHS", monthly_limit_wager);
    }
  };

  const resetWagerLimit = () => {
    var reset_wager_url = "" + url + "/api/safer_gambling/delete_wager_limit";

    var body = {};
    var headers = { session_key: session_key };
    showLoader();
    axios
      .post(reset_wager_url, body, { headers })
      .then((response) => {
        if (response.status === 200) {
          window.location.reload();
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          redirect("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Message);
        }
        hideLoader();
      });
  };

  if (wagerDaily.modified_date !== undefined) {
    dailyModifiedDate = wagerDaily.modified_date;
    daily_hours_duration_wager = moment().diff(
      moment(dailyModifiedDate),
      "hours"
    );
    daily_amount = Number(wagerDaily.amount);
  }

  if (wagerWeekly.modified_date !== undefined) {
    weeklyModifiedDate = wagerWeekly.modified_date;
    weekly_hours_duration_wager = moment().diff(
      moment(weeklyModifiedDate),
      "hours"
    );
    weekly_amount = Number(wagerWeekly.amount);
  }

  if (wagerMonthly.modified_date !== undefined) {
    monthlyModifiedDate = wagerMonthly.modified_date;
    monthly_hours_duration_wager = moment().diff(
      moment(monthlyModifiedDate),
      "hours"
    );
    monthly_amount = Number(wagerMonthly.amount);
  }

  weekly_wager_filtered_values = weekly_wager_vales.filter(
    (row) => row > daily_amount
  );
  monthly_wager_filtered_values = monthly_wager_vales.filter(
    (row) => row > daily_amount + weekly_amount
  );

  const handleWagerLimitSelect = (e) => {
    var weekly_limit = document.getElementById("wager_limit_weekly_val").value;
    var daily_limit = document.getElementById("wager_limit_daily_val").value;
    var type = e.target.getAttribute("data-type");
    var weekly_html = `<option value="0">Select Weekly Wager Limit</option>`;
    var monthly_html = `<option value="0">Select Monthly Wager Limit</option>`;

    if (type === "daily") {
      var weekly_result = weekly_wager_vales.filter(
        (row) => row > Number(daily_limit)
      );
      var monthly_result = monthly_wager_vales.filter(
        (row) => row > Number(daily_limit) + Number(weekly_limit)
      );

      weekly_result.map((row) => {
        return (weekly_html +=
          `<option value="` + row + `">&euro;` + row + `</option>`);
      });
      document.getElementById("wager_limit_weekly_val").innerHTML = weekly_html;

      monthly_result.map((row) => {
        return (monthly_html +=
          `<option value="` + row + `">&euro;` + row + `</option>`);
      });
      document.getElementById("wager_limit_monthly_val").innerHTML =
        monthly_html;
    }

    if (type === "weekly") {
      var weekly_result = weekly_wager_vales.filter(
        (row) => row > Number(daily_limit)
      );
      var monthly_result = monthly_wager_vales.filter(
        (row) => row > Number(daily_limit) + Number(weekly_limit)
      );

      monthly_result.map((row) => {
        return (monthly_html +=
          `<option value="` + row + `">&euro;` + row + `</option>`);
      });
      document.getElementById("wager_limit_monthly_val").innerHTML =
        monthly_html;
    }
  };

  useEffect(() => {
    getStaticContent();
    if (props.type !== "description") {
      getWagerLimitInfo("DAYS");
      getWagerLimitInfo("WEEKS");
      getWagerLimitInfo("MONTHS");
    }
  }, []);

  return (
    <React.Fragment>
      <div className="wager_limit safer_gambling_container mt-3 primary-background">
        {props.type === "gambling" ? (
          <div className="safer_title">{Parser(wagerLimitStaticContent)}</div>
        ) : (
          <div className="safer_title">{Parser(wagerLimitStaticContent)}</div>
        )}
        {props.type === "gambling" ? (
          <div className="gambling_container">
            <div className="gambling_center">
              <div className="gambling_select mb-4 mt-4">
                <label className="text-white">
                  {!props.labels.safer_gambling_daily_limit
                    ? "Daily Limit"
                    : props.labels.safer_gambling_daily_limit.label_name}
                </label>
                <select
                  id="wager_limit_daily_val"
                  disabled={daily_hours_duration_wager < 24 ? true : false}
                  onChange={handleWagerLimitSelect}
                  data-type="daily"
                >
                  <option value="0">Select Daily Wager Limit</option>
                  {daily_wager_values.map((row, index) => {
                    var selectedOption = "";
                    if (row == Number(wagerDaily.amount)) {
                      selectedOption = true;
                    } else {
                      selectedOption = false;
                    }
                    return (
                      <option value={row} key={index} selected={selectedOption}>
                        &euro; {row}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="gambling_select mb-4">
                <label className="text-white">
                  {!props.labels.safer_gambling_weekly_limit
                    ? "Weekly Limit"
                    : props.labels.safer_gambling_weekly_limit.label_name}
                </label>
                <select
                  id="wager_limit_weekly_val"
                  disabled={weekly_hours_duration_wager < 24 ? true : false}
                  onChange={handleWagerLimitSelect}
                  data-type="weekly"
                >
                  <option value="0">Select Weekly Wager Limit</option>
                  {weekly_wager_filtered_values.map((row, index) => {
                    var selectedOption = "";
                    if (row == Number(wagerWeekly.amount)) {
                      selectedOption = true;
                    } else {
                      selectedOption = false;
                    }
                    return (
                      <option value={row} key={index} selected={selectedOption}>
                        &euro; {row}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="gambling_select mb-4">
                <label className="text-white">
                  {!props.labels.safer_gambling_monthly_limit
                    ? "Monthly Limit"
                    : props.labels.safer_gambling_monthly_limit.label_name}
                </label>
                <select
                  id="wager_limit_monthly_val"
                  disabled={monthly_hours_duration_wager < 24 ? true : false}
                  onChange={handleWagerLimitSelect}
                  data-type="monthly"
                >
                  <option value="0">Select Monthly Wager Limit</option>
                  {monthly_wager_filtered_values.map((row, index) => {
                    var selectedOption = "";
                    if (row == Number(wagerMonthly.amount)) {
                      selectedOption = true;
                    } else {
                      selectedOption = false;
                    }
                    return (
                      <option value={row} key={index} selected={selectedOption}>
                        &euro; {row}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="gambling_btns">
                <ButtonLight
                  classValue="btn_light btn_reset_deposit_limit"
                  textValue={
                    !props.labels.safer_gambling_reset
                      ? "Reset"
                      : props.labels.safer_gambling_reset.label_name
                  }
                  onClick={resetWagerLimit}
                />
                <ButtonPrimary
                  classValue="btn_primary btn_submit_deposit_limit"
                  textValue={
                    !props.labels.safer_gambling_submit
                      ? "Submit"
                      : props.labels.safer_gambling_submit.label_name
                  }
                  onClick={submitWagerLimit}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {loader}
      {alert}
    </React.Fragment>
  );
}
