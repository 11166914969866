import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import "../my-competitions/Competitions.css";
import ProfileAccount from "../account/ProfileAccount";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import moment from "moment";

export default function Leaderboard() {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [matches, setMatches] = useState({
    matches_data: { remaining_matches: "", total_matches: "" },
  });
  const [status, setStatus] = useState("");
  const [league, setLeague] = useState("");
  const [loading, setLoading] = useState(true);
  const [sportId, setSportId] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [totalLeaderboards, setTotalLeaderboards] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [leaderboardLabels, setLeaderboardLabels] = useState([]);
  const [numberOfRound, setNumberOfRound] = useState(0);
  var url = process.env.REACT_APP_API_URL;
  const { sport, uid } = useParams();
  var leaderboard_status = "";
  const [loader, showLoader, hideLoader] = useLoading();
  const [alert, alert_notify] = useAlert();

  var total_pages = 0;
  var pages_length = 0;
  var pages_array = [];

  total_pages = totalLeaderboards;
  pages_length = Math.ceil(total_pages / 10);

  for (var i = 0; i < pages_length; i++) {
    pages_array.push(i + 1);
  }

  const getLeaderboardData = (data) => {
    var leaderboard_url = "" + url + "/api/leaderboard/get_golf_leaderboard";
    var session_key = localStorage.getItem("session_key");

    if (!data) {
      var body = {
        contest_uid: uid,
      };
    } else {
      var body = data;
    }

    var headers = {
      session_key: session_key,
    };
    showLoader();
    axios
      .post(leaderboard_url, body, { headers })
      .then((response) => {
        hideLoader();
        if (response.status === 200) {
          var result = response.data.Data.result;
          var remaining_matches = response.data.Data.remaining_matches;
          var total_matches = response.data.Data.total_matches;
          var status = response.data.Data.status;
          var start_date = response.data.Data.start_date;
          var current_date = moment().format("MM/DD/YYYY");
          var startDate = moment(start_date, "YYYY-MM-DD").format("MM/DD/YYYY");

          const date1 = new Date(current_date);
          const date2 = new Date(startDate);
          if (date1 > date2) {
            const diffTime = Math.abs(date1 - date2);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if (diffDays > 4) {
              var round_number = 4;
            } else {
              var round_number = diffDays;
            }
          } else {
            var round_number = 0;
          }

          setSportId(response.data.Data.sport_id);
          setLeaderboardData(result);
          setTotalLeaderboards(total_matches);
          setNumberOfRound(round_number);
          setMatches({
            ...matches,
            matches_data: {
              remaining_matches: remaining_matches,
              total_matches: total_matches,
            },
          });

          if (result.length > 0) {
            setLeague(result[0].tournament_name);
          }

          setStatus(status);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  const goToThisGamecardPage = (e) => {
    var path = e.currentTarget.getAttribute("data-path");
    var uid = e.currentTarget.getAttribute("data-uid");
    navigate("/golf/" + path + "/13/" + uid + "");
  };

  const fetchLeaderboardData = (e) => {
    var index = e.target.getAttribute("data-id");
    var mode = e.currentTarget.getAttribute("data-mode");
    if (!mode) {
      var data = {
        contest_uid: uid,
        current_page: index,
      };
      getLeaderboardData(data);
      setActivePage(Number(index));
    } else {
      var active_index = document
        .querySelector(".page_number.active")
        .getAttribute("data-id");
      if (mode === "prev") {
        if (active_index > 1) {
          var new_index = Number(active_index) - 1;
          var data = {
            contest_uid: uid,
            current_page: new_index,
          };
          getLeaderboardData(data);
          setActivePage(new_index);
        } else {
          var new_index = 1;
        }
      } else {
        if (active_index < pages_length) {
          var new_index = Number(active_index) + 1;
          var data = {
            contest_uid: uid,
            current_page: new_index,
          };
          getLeaderboardData(data);
          setActivePage(new_index);
        } else {
          var new_index = pages_length;
        }
      }
    }
  };

  const getLeaderboardLabels = (page_name) => {
    var leaderboard_labels_url =
      "" + url + "/api/static_content/get_all_labels";
    var body = {
      page_name: page_name,
    };
    axios
      .post(leaderboard_labels_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data.content;
          setLeaderboardLabels(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getLeaderboardLabels("leaderboard");
    getLeaderboardData();
    var w = window.innerWidth;
    if (w < 991) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    window.addEventListener("resize", () => {
      var w = window.innerWidth;
      var h = window.innerHeight;

      if (w > 991) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    });
  }, []);

  if (status === "0") {
    leaderboard_status = "Inactive";
  } else if (status === "1") {
    leaderboard_status = "Active";
  } else if (status === "2") {
    leaderboard_status = "Live";
  } else if (status === "3") {
    leaderboard_status = "Prize Distribution Done";
  } else if (status === "4") {
    leaderboard_status = "Canceled";
  } else if (status === "5") {
    leaderboard_status = "Completed";
  } else {
    leaderboard_status = "Active";
  }

  return (
    <React.Fragment>
      <Header />
      <ProfileAccount
        headerValue={
          !leaderboardLabels.leaderboard_my_competitions
            ? "My Competitions"
            : leaderboardLabels.leaderboard_my_competitions.label_name
        }
      />
      <div className="leaderboard_container primary-background">
        <div className="page_content_center">
          <div className=" m-0 leaderboard_header m-0 mt-5 align-items-center">
            <div
              className="d-inline-flex align-items-center justify-content-between gamecard_tab competition_tab "
              data-uid={uid}
              data-path="gamecard"
              onClick={goToThisGamecardPage}
              style={{ cursor: "pointer" }}
            >
              <span
                className="text-white"
                style={{ textTransform: "uppercase" }}
              >
                {!leaderboardLabels.leaderboard_gamecard_label
                  ? "Gamecard"
                  : leaderboardLabels.leaderboard_gamecard_label.label_name}
              </span>
              <span className="leaderboard_icon" style={{ marginRight: "0" }}>
                <img src="/assets/icons/gamecard.svg" alt="leaderboard-icon" />
              </span>
            </div>
            <div className="d-inline-flex align-items-center justify-content-between active_tab leaderboard_tab competition_tab">
              <span
                className="text-white"
                style={{ textTransform: "uppercase" }}
              >
                {!leaderboardLabels.leaderboard_leaderboard_label
                  ? "Gamecard"
                  : leaderboardLabels.leaderboard_leaderboard_label.label_name}
              </span>
              <span className="leaderboard_icon" style={{ marginRight: "0" }}>
                <img
                  src="/assets/icons/leaderboard.svg"
                  alt="leaderboard-icon"
                />
              </span>
            </div>
          </div>
          <div className="row mt-4 leaderboard_row leaderboard_status align-items-center">
            <div
              className="col-12 col-lg-4 col-xl-4"
              style={{ textTransform: "capitalize" }}
            >
              {!leaderboardLabels.leaderboard_status_label
                ? "Status"
                : leaderboardLabels.leaderboard_status_label.label_name}
              : {leaderboard_status}
            </div>
            <div className="col-12 col-lg-4 col-xl-4 leaderboard_league">
              <span className="leaderboard_league_icon">
                <img
                  className="public_icon"
                  src="/assets/icons/private.svg"
                  alt="public-icon"
                />
              </span>
              <span className="leaderbaord_league_name">
                PGA Tour: {league}{" "}
              </span>
            </div>
            <div className="col-12 col-lg-4 col-xl-4 leaderboard_remaining_matches">
              Round {numberOfRound}/4
            </div>
          </div>
          <div
            className="row mt-2 leaderboard_row leaderboard_info align-items-center golf_leaderboard golf_container"
            style={{ height: "45px" }}
          >
            <div className="col-3" style={{ textTransform: "capitalize" }}>
              {!leaderboardLabels.leaderboard_username_label
                ? "Username"
                : leaderboardLabels.leaderboard_username_label.label_name}
            </div>
            {/* <div className="col-2" style={{textTransform: 'capitalize', textAlign:'right'}}>
                {
                  'Round 1'
                }
            </div>
            <div className="col-2" style={{textTransform: 'capitalize', textAlign:'right'}}>
                {
                  'Round 2'
                }
            </div>
            <div className="col-2" style={{textTransform: 'capitalize', textAlign:'right'}}>
                {
                  'Round 3'
                }
            </div>
            <div className="col-2" style={{textTransform: 'capitalize', textAlign:'right'}}>
                {
                  'Round 4'
                }
            </div> */}
            <div
              className="col-3"
              style={{ textTransform: "capitalize", textAlign: "right" }}
            >
              {"Total Points"}
            </div>
            <div
              className="col-3"
              style={{ textTransform: "capitalize", textAlign: "right" }}
            >
              {"Positions"}
            </div>
            <div
              className="col-3"
              style={{ textTransform: "capitalize", textAlign: "right" }}
            >
              {"Prize"}
            </div>
          </div>
          <div className="leaderboard_table leaderboard_row">
            {leaderboardData.map((row, index) => {
              // if(isMobile){
              //   return (
              //     <>
              //       <div className="row m-0 mt-2 align-items-center golf_leaderboard g_mb_container" key={index}>
              //         <div className="col-4">Username </div>  {row.user_name}
              //       </div>
              //       <div className="row m-0 mt-2 align-items-center golf_leaderboard g_mb_container" key={'i'+index}>
              //         {/* <div className="row m-0">
              //           <div className="col-6 p-0 d-flex justify-content-start"> Round 1</div>
              //           <div className="col-6 p-0 d-flex justify-content-end"> {row.round_one_points} </div>
              //         </div>
              //         <div className="row m-0">
              //           <div className="col-6 p-0 d-flex justify-content-start"> Round 2</div>
              //           <div className="col-6 p-0 d-flex justify-content-end"> {row.round_two_points} </div>
              //         </div>
              //         <div className="row m-0">
              //           <div className="col-6 p-0 d-flex justify-content-start"> Round 3</div>
              //           <div className="col-6 p-0 d-flex justify-content-end"> {row.round_three_points} </div>
              //         </div>
              //         <div className="row m-0">
              //           <div className="col-6 p-0 d-flex justify-content-start"> Round 4</div>
              //           <div className="col-6 p-0 d-flex justify-content-end"> {row.round_four_points} </div>
              //         </div> */}
              //         <div className="row m-0">
              //           <div className="col-6 p-0 d-flex justify-content-start"> Total Points</div>
              //           <div className="col-6 p-0 d-flex justify-content-end"> {row.total_score} </div>
              //         </div>
              //         <div className="row m-0">
              //           <div className="col-6 p-0 d-flex justify-content-start"> Position</div>
              //           <div className="col-6 p-0 d-flex justify-content-end"> {row.rank} </div>
              //         </div>
              //         <div className="row m-0">
              //           <div className="col-6 p-0 d-flex justify-content-start"> Prize </div>
              //           <div className="col-6 p-0 d-flex justify-content-end"> {'-'} </div>
              //         </div>
              //       </div>
              //     </>
              //   )
              // }else{
              return (
                <div
                  className="row m-0 mt-2 align-items-center golf_leaderboard"
                  key={index}
                >
                  <div className="col-3">{row.user_name}</div>
                  {/* <div className="col-2" style={{textAlign: 'right'}}>{row.round_one_points ? row.round_one_points : 0}</div>
                    <div className="col-2" style={{textAlign: 'right'}}>{row.round_two_points ? row.round_two_points : 0}</div>
                    <div className="col-2" style={{textAlign: 'right'}}>{row.round_three_points ? row.round_three_points : 0}</div>
                    <div className="col-2" style={{textAlign: 'right'}}>{row.round_four_points ? row.round_four_points : 0}</div> */}
                  <div className="col-3" style={{ textAlign: "right" }}>
                    {row.total_score}
                  </div>
                  <div className="col-3" style={{ textAlign: "right" }}>
                    {row.rank ? row.rank : "-"}
                  </div>
                  <div className="col-3" style={{ textAlign: "right" }}>
                    {"-"}
                  </div>
                </div>
              );
              // }
            })}
          </div>
          {pages_array.length > 1 ? (
            <div className="pagination d-flex justify-content-end mt-3">
              <div
                className="page"
                data-mode="prev"
                onClick={fetchLeaderboardData}
              >
                <span>
                  <img src="/assets/icons/arrow-left.svg" alt="arrow-left" />
                </span>
              </div>
              {pages_array.map((row, index) => {
                return (
                  <div
                    className={
                      activePage === index + 1
                        ? "page page_number active"
                        : "page page_number "
                    }
                    key={index}
                    data-id={index + 1}
                    onClick={fetchLeaderboardData}
                  >
                    {row}
                  </div>
                );
              })}
              <div
                className="page"
                data-mode="next"
                onClick={fetchLeaderboardData}
              >
                <span>
                  <img src="/assets/icons/arrow-right.svg" alt="arrow-right" />
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <Footer />
      {loader}
      {alert}
    </React.Fragment>
  );
}
