import React, { useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "../my-competitions/Competitions.css";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Moment from "react-moment";
import useLoading from "../../hooks/UseLoading";
import moment from "moment";
import useAlert from "../../hooks/UseAlert";

export default function CompletedCompetition(props) {
  const [loading, setLoading] = useState(true);
  const [completedCompetitions, setCompletedCompetitions] = useState([]);
  const [checkData, setCheckData] = useState(false);
  const [privateCompetitions, setPrivateCompetitions] = useState([]);
  const [totalCompletedCompetitions, setTotalCompletedCompetitions] = useState(
    []
  );
  const [totalPrivateCompetitions, setTotalPrivateCompetitions] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [alert, alert_notify] = useAlert();
  const navigate = useNavigate();
  var competitions_titles = [
    "Sport",
    "League",
    "Entry Fee",
    "Entries",
    "End Date",
    "Position",
  ];
  var url = process.env.REACT_APP_API_URL;
  const [loader, showLoader, hideLoader] = useLoading();
  var total_pages = 0;
  var pages_length = 0;
  var pages_array = [];
  var allComps = [];

  const getTotalCompetitions = () => {
    var completed_competitions_url =
      "" + url + "/api/my_contest/completed_contest";
    var session_key = localStorage.getItem("session_key");
    var body = {};
    var headers = {
      session_key: session_key,
    };
    showLoader();
    axios
      .post(completed_competitions_url, body, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTotalCompletedCompetitions(response.data.Data.total);
          setLoading(false);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  const getTotalPrivateCompetitions = () => {
    var private_competitions_url = "" + url + "/api/my_contest/private_contest";
    var session_key = localStorage.getItem("session_key");
    var body = {};
    var headers = {
      session_key: session_key,
    };
    showLoader();
    axios
      .post(private_competitions_url, body, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTotalPrivateCompetitions(response.data.Data.data.total);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  const fetchCompletedCompetitions = (data) => {
    var completed_competitions_url =
      "" + url + "/api/my_contest/completed_contest";
    var session_key = localStorage.getItem("session_key");
    if (!data) {
      var body = {
        current_page: 1,
        items_perpage: 10,
      };
    } else {
      var body = data;
    }

    var headers = {
      session_key: session_key,
    };
    showLoader();
    axios
      .post(completed_competitions_url, body, { headers })
      .then((response) => {
        var data = response.data.Data.result;
        if (response.status === 200) {
          if (data !== undefined) {
            setCompletedCompetitions(data);
            setLoading(false);
            setCheckData(true);
          }
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
        console.log(err);
        navigate("/login");
        localStorage.removeItem("session_key");
      });
  };

  const fetchPrivateCompetitions = (data) => {
    var private_competitions_url = "" + url + "/api/my_contest/private_contest";
    var session_key = localStorage.getItem("session_key");
    if (!data) {
      var body = {
        current_page: 1,
        items_perpage: 10,
      };
    } else {
      var body = data;
    }
    var headers = {
      session_key: session_key,
    };
    showLoader();
    axios
      .post(private_competitions_url, body, { headers })
      .then((response) => {
        var data = response.data.Data.data.result;
        if (response.status === 200) {
          if (data !== undefined) {
            var data_array = [];
            var dateTimeFormat = "DD MMM YYYY HH:mm";
            data.map((value) => {
              var dateTimeUtc = moment.utc(value.end_at);
              var localDate = dateTimeUtc.local();
              var competitionEndAt = localDate.format(dateTimeFormat);
              var competitionEndAtUnix = moment(
                competitionEndAt,
                "DD MMM YYYY HH:mm"
              ).unix();
              var currentDate = moment().unix();
              if (currentDate > competitionEndAtUnix) {
                data_array.push(value);
              }
            });
            setPrivateCompetitions(data_array);
            setLoading(false);
            setCheckData(true);
          }
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
        console.log(err);
        navigate("/login");
        localStorage.removeItem("session_key");
      });
  };

  allComps = privateCompetitions.concat(completedCompetitions);
  allComps.sort((a, b) =>
    a.end_at > b.end_at ? -1 : b.end_at > a.end_at ? 1 : 0
  );

  useEffect(() => {
    getTotalCompetitions();
    getTotalPrivateCompetitions();
    fetchPrivateCompetitions();
    fetchCompletedCompetitions();
  }, []);

  const renderGameCardTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        Game Card
      </Tooltip>
    );
  };

  const renderLeaderBoardTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        Leaderboard
      </Tooltip>
    );
  };

  const fetchCompetitionsData = (e) => {
    var index = e.target.getAttribute("data-id");
    var mode = e.currentTarget.getAttribute("data-mode");
    if (!mode) {
      var data = {
        current_page: index,
        items_perpage: 10,
      };

      fetchCompletedCompetitions(data);
      fetchPrivateCompetitions(data);
      setActivePage(Number(index));
    } else {
      var active_index = document
        .querySelector(".page_number.active")
        .getAttribute("data-id");
      if (mode == "prev") {
        if (active_index > 1) {
          var new_index = Number(active_index) - 1;
          var data = {
            current_page: new_index,
            items_perpage: 10,
          };
          fetchCompletedCompetitions(data);
          fetchPrivateCompetitions(data);
          setActivePage(new_index);
        } else {
          var new_index = 1;
        }
      } else {
        if (active_index < pages_length) {
          var new_index = Number(active_index) + 1;
          var data = {
            current_page: new_index,
            items_perpage: 10,
          };
          fetchCompletedCompetitions(data);
          fetchPrivateCompetitions(data);
          setActivePage(new_index);
        } else {
          var new_index = pages_length;
        }
      }
    }
  };

  if (!loading) {
    if (!completedCompetitions) {
      setCompletedCompetitions([]);
      setPrivateCompetitions([]);
      setCheckData(false);
    }
  }

  const goToThisPage = (e) => {
    var path = e.currentTarget.getAttribute("data-path");
    var sport_id = e.currentTarget.getAttribute("data-sportid");
    var uid = e.currentTarget.getAttribute("data-uid");
    if (path === "gamecard") {
      navigate("/" + path + "/" + sport_id + "/" + uid + "");
    } else {
      navigate("/" + path + "/" + uid + "");
    }
  };

  total_pages = totalCompletedCompetitions;
  pages_length = Math.ceil(total_pages / 10);

  for (var i = 0; i < pages_length; i++) {
    pages_array.push(i + 1);
  }
  return (
    <React.Fragment>
      <div className="competitions mt-3">
        <div className="row competitions_icons_row">
          <span
            className="public_icon text-white"
            style={{ textTransform: "capitalize" }}
          >
            <img src="assets/icons/public.svg" alt="public-icon" />
            {!props.labels.completed_public_label
              ? "Public"
              : props.labels.completed_public_label.label_name}
          </span>
          <span
            className="private_icon text-white"
            style={{ textTransform: "capitalize" }}
          >
            <img src="assets/icons/private.svg" alt="public-icon" />
            {!props.labels.completed_private_label
              ? "Private"
              : props.labels.completed_private_label.label_name}
          </span>
        </div>
        <div className="row competitions_titles">
          <div className="competitions_public_private_col"></div>
          <div className="col-12 col-xl-2 col-lg-2">
            {!props.labels.my_comp_completed_sport
              ? "Sport"
              : props.labels.my_comp_completed_sport.label_name}
          </div>
          <div className="col">
            {!props.labels.completed_league_label
              ? "League"
              : props.labels.completed_league_label.label_name}
          </div>
          <div className="competitions_entry_fee">
            {!props.labels.my_comp_completed_entry_fee
              ? "Entry Fee"
              : props.labels.my_comp_completed_entry_fee.label_name}
          </div>
          <div className="competitions_entries">
            {!props.labels.my_comp_completed_entries
              ? "Entries"
              : props.labels.my_comp_completed_entries.label_name}
          </div>
          <div className="col-12 col-xl-2 col-lg-2">
            {!props.labels.completed_end_date_label
              ? "End Date"
              : props.labels.completed_end_date_label.label_name}
          </div>
          <div className="competition_position">
            {!props.labels.my_comp_completed_position
              ? "Position"
              : props.labels.my_comp_completed_position.label_name}
          </div>
          <div className="col-12 col-lg-1 col-xl-1 d-flex justify-content-center competition_icons"></div>
        </div>
        {!loading && checkData
          ? allComps.map((value, index) => {
              var sport = value.sport_name;
              var league = value.league_name;
              var league_country = value.league_country;
              var entry_fee = value.entry_fee;
              var position = value.rank;
              var position_live = value.result;
              var end_at = value.end_at;
              var contest_id = value.contest_id;
              var total_user_joined = value.total_user_joined;
              var size = value.size;
              var sport_id = value.sport_id;
              var uid = value.contest_uid;
              var contest_type = value.contest_type;

              if (contest_type == "private") {
                var contestTypeIcons = (
                  <img
                    src="assets/icons/private_primary.svg"
                    alt="private-icon"
                  />
                );
              } else {
                var contestTypeIcons = (
                  <img
                    src="assets/icons/public_primary.svg"
                    alt="public-icon"
                  />
                );
              }

              if (entry_fee === "0.00" || entry_fee === 0) {
                entry_fee = "Free to Play";
              }
              if (league_country != undefined && league_country != "") {
                league_country = league_country.toLowerCase();
              }
              if (league != undefined && league != "") {
                league = league.toLowerCase();
              }

              if (sport_id != "13") {
                var link = "/gamecard/" + sport_id + "/" + uid;
                var leaderboard_link = "/leaderboard/" + sport_id + "/" + uid;
              } else {
                var link = "/golf/gamecard/" + sport_id + "/" + uid;
                league_country = "";
                var leaderboard_link =
                  "/golf/leaderboard/" + sport_id + "/" + uid;
              }
              return (
                <div
                  className="row competition transaction m-0"
                  data-contestid={contest_id}
                  row-index={index}
                  key={index}
                >
                  <div className="col-12 col-lg-1 col-xl-1 competitions_public_private_col">
                    <span className="public_icon">{contestTypeIcons}</span>
                  </div>
                  <div className="col-12 col-lg-1 col-xl-1 d-flex justify-content-center competition_icons_mobile">
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderGameCardTooltip}
                    >
                      <Link
                        className="gamecard_icon"
                        data-path="gamecard"
                        data-sportid={sport_id}
                        data-uid={uid}
                        to={link}
                      >
                        <img
                          src="assets/icons/gamecard_primary.svg"
                          alt="game-card-icon"
                        />
                      </Link>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderLeaderBoardTooltip}
                    >
                      <Link
                        className="leaderboard_icon"
                        data-path="leaderboard"
                        data-sportid={sport_id}
                        data-uid={uid}
                        to={leaderboard_link}
                      >
                        <img
                          src="assets/icons/leaderboard_primary.svg"
                          alt="leaderboard-icon"
                        />
                      </Link>
                    </OverlayTrigger>
                  </div>
                  <div className="col-12 col-lg-2 col-xl-2 d-flex competition_col">
                    <span className="transaction_title col-5 col-lg-12 col-xl-12">
                      {competitions_titles[0]}
                    </span>
                    <span className="transaction_value col-7 col-lg-12 col-xl-12 d-flex">
                      <div className="competitions_public_private_col_mobile">
                        <span className="public_icon">
                          <img
                            src="/assets/icons/private.svg"
                            alt="private-icon"
                          />
                        </span>
                      </div>
                      {sport}
                    </span>
                  </div>
                  <div className="col d-flex">
                    <span className="transaction_title col-5 col-lg-12 col-xl-12">
                      {competitions_titles[1]}
                    </span>
                    <span
                      className="transaction_value col-7 col-lg-12 col-xl-12"
                      style={{ textTransform: "uppercase" }}
                    >
                      {league_country} {league}
                    </span>
                  </div>
                  <div className="competitions_entry_fee d-flex">
                    <span className="transaction_title col-5 col-lg-12 col-xl-12">
                      {competitions_titles[2]}
                    </span>
                    <span className="transaction_value col-7 col-lg-12 col-xl-12 alignText">
                      {entry_fee}
                    </span>
                  </div>
                  <div className="competitions_entries d-flex">
                    <span className="transaction_title col-5 col-lg-12 col-xl-12">
                      {competitions_titles[3]}
                    </span>
                    <span className="transaction_value col-7 col-lg-12 col-xl-12">
                      {total_user_joined}/{size}
                    </span>
                  </div>
                  <div className="col-12 col-xl-2 col-lg-2 d-flex competition_col">
                    <span className="transaction_title col-5 col-lg-12 col-xl-12">
                      {competitions_titles[4]}
                    </span>
                    <span className="transaction_value col-7 col-lg-12 col-xl-12">
                      <Moment format="DD MMM YYYY">{end_at}</Moment>
                    </span>
                  </div>
                  <div className="competition_position d-flex">
                    <span className="transaction_title col-5 col-lg-12 col-xl-12">
                      {competitions_titles[5]}
                    </span>
                    <span className="transaction_value col-7 col-lg-12 col-xl-12">
                      {contest_type == "private"
                        ? position
                        : position_live || "-"}
                    </span>
                  </div>
                  <div className="col-12 col-lg-1 col-xl-1 d-flex justify-content-center competition_icons">
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderGameCardTooltip}
                    >
                      <Link
                        className="gamecard_icon"
                        data-path="gamecard"
                        data-sportid={sport_id}
                        data-uid={uid}
                        to={link}
                      >
                        <img
                          src="assets/icons/gamecard_primary.svg"
                          alt="game-card-icon"
                        />
                      </Link>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderLeaderBoardTooltip}
                    >
                      <Link
                        className="leaderboard_icon"
                        data-path="leaderboard"
                        data-sportid={sport_id}
                        data-uid={uid}
                        to={leaderboard_link}
                      >
                        <img
                          src="assets/icons/leaderboard_primary.svg"
                          alt="leaderboard-icon"
                        />
                      </Link>
                    </OverlayTrigger>
                  </div>
                </div>
              );
            })
          : ""}
      </div>
      {pages_array.length > 1 ? (
        <div className="pagination d-flex justify-content-end mt-3">
          <div
            className="page"
            data-mode="prev"
            onClick={fetchCompetitionsData}
          >
            <span>
              <img src="/assets/icons/arrow-left.svg" alt="arrow-left" />
            </span>
          </div>
          {pages_array.map((row, index) => {
            return (
              <div
                className={
                  activePage === index + 1
                    ? "page page_number active"
                    : "page page_number "
                }
                key={index}
                data-id={index + 1}
                onClick={fetchCompetitionsData}
              >
                {row}
              </div>
            );
          })}
          <div
            className="page"
            data-mode="next"
            onClick={fetchCompetitionsData}
          >
            <span>
              <img src="/assets/icons/arrow-right.svg" alt="arrow-right" />
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      {loader}
      {alert}
    </React.Fragment>
  );
}
