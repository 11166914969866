import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ImageContainer from "../image-container/ImageContainer";
import LoginHeader from "../login/LoginHeader";
import "../challange/challange.css";
import axios from "axios";
import Parser from "html-react-parser";
import { useNavigate } from "react-router-dom";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import { Helmet } from "react-helmet";

export default function Challange() {
  const [loggedStatus, setLoggedStatus] = useState("");
  var url = process.env.REACT_APP_API_URL;
  const [challengeContentText, setChallengeContentText] = useState("");
  const [challengeName, setChallengeName] = useState("");
  const [loader, showLoader, hideLoader] = useLoading();
  const navigate = useNavigate();
  const [alert, alert_notify] = useAlert();

  useEffect(() => {
    var logged_status = localStorage.getItem("user_status");
    if (logged_status === "unlogged") {
      setLoggedStatus("unlogged");
    } else {
      setLoggedStatus("logged");
    }

    var challange_url = "" + url + "/api/static_content/get_all_static_content";
    const body = { page_meta_key: "challenge" };
    showLoader();
    axios
      .post(challange_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          var page_content = data.content.page_content;
          setChallengeContentText(page_content);
          var page_name = data.content.page_name;
          setChallengeName(page_name);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MATCH MY SCORE | Challenge</title>
        <meta
          name="description"
          content="Setup your own MATCH MY SCORE challenge. Create a private competition between your mates. Find out how here. "
        />
      </Helmet>
      {loggedStatus === "logged" ? <Header /> : <LoginHeader />}
      <ImageContainer />
      <div className="challange_container">
        <div className="page_content_center">
          <div className="challange_text">
            <h2 className="text-uppercase">{Parser(challengeName)}</h2>
          </div>
          {/* <div className="challange_club_fundraising">
                            <h4>CLUB FUNDRAISING & PRIVATE COMPETITIONS</h4>
                        </div>
                        <div className="challange_center_container">
                            <div className="row">
                                <div className="col-12 title">
                                    <span className="club_fundrising">Club Fundraising</span> -  <span className="cooming_soon">COMING SOON</span>
                                </div>
                                <div className="col-12">
                                    <div className="mt-4">
                                        Raise much needed funds for your club or association by setting up exclusive Competitions for your members. You control the cost of entry, the number of entrants and most importantly how much you want to keep for your club. All you have to do is advertise it to your members, we do the rest. 
                                    </div>
                                    <div className="mt-4">
                                        Fund raising can only be undertaken by a registered MATCH MY SCORE Administrator. 
                                    </div>
                                    <div className="mt-4">
                                        To become an Administrator you must be an officer of your club with the ability to verify your club's official bank account by transferring $5 into one of MATCH MY SCORE's accounts. This will be refunded in full to the same account with your first fundraising payment. 
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12 title">
                                    <span className="">Private Competitions</span>
                                </div>
                                <div className="mt-4">
                                    Set up your own exclusive Competition, just decide which event you want to play on from those available then decide how many people you want in your competition and what it’s going to cost to enter. You’ll receive a link by email to forward to whoever you want.
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12 title">
                                    <span className="">Challange Competitions</span>
                                </div>
                                <div className="mt-4">
                                    If you’re happy with our entry fees and numbers then a Challenge Competition is similar to a Private Competition, you’re simply replicating one of our competitions available to the public. It’s still your own exclusive competition, just click on Challenge when you know which event you’re going to play on.
                                </div>
                            </div>
                        </div> */}
          {Parser(challengeContentText)}
        </div>
      </div>
      <Footer />
      {loader}
      {alert}
    </React.Fragment>
  );
}
