import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-tooltip/dist/react-tooltip.css";
import React, { useEffect, useState, useMemo } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import OurMission from "./components/our-mission/OurMission";
import SaferGambling from "./components/safer-gambling/SaferGambling";
import Challange from "./components/challange/Challange";
import Rules from "./components/rules/Rules";
import Faqs from "./components/faqs/Faqs";
import TermsConditions from "./components/terms-conditions/TermsConditions";
import PrivacyPolicy from "./components/privacy-policy/PrivacyPolicy";
import Feedback from "./components/feedback/Feedback";
import Index from "./components/index/Index";
import IndexDraft from "./components/index/IndexDraft";
import Login from "./components/login/Login";
import Register from "./components/register/Register";
import RegisterMobileGhana from "./components/register-ghana/RegisterMobileGhana";
import RegisterMobileGhanaV2 from "./components/register-ghana/RegisterMobileGhanaV2";
import SMSVerifyGhana from "./components/register-ghana/SMSVerifyGhana";
import ForgotPassword from "./components/forgot-password/ForgotPassword";
import ScrollTop from "./components/index/ScrollTop";
import Home from "./components/home/Home";
import Profile from "./components/profile/Profile";
import Sumsub from "./components/profile/Sumsub";
import Account from "./components/account/Account";
import ActivateAccount from "./components/register/ActivateAccount";
import HowToPlay from "./components/how-to-play/HowToPlay";
import Notifications from "./components/notifications/Notifications";
import SportsLeagues from "./components/sports-leagues/SportsLeagues";
import Competitions from "./components/my-competitions/Competitions";
import GameCard from "./components/my-competitions/GameCard";
import Leaderboard from "./components/my-competitions/Leaderboard";
import GolfLeaderboard from "./components/my-competitions/GolfLeaderboard";
import CreatePrivateCompetition from "./components/private-competitions/CreatePrivateCompetition";
import CreateClubFundraiser from "./components/private-competitions/CreateClubFundraiser";
import MyCompetition from "./components/private-competitions/MyCompetition";
import ResetPassword from "./components/reset-password/ResetPassword";
import JoinCompetition from "./components/join-competition/JoinCompetition";
import SubmitedSaferGambling from "./components/safer-gambling/SubmitedSaferGambling";
import { UserContext } from "./components/user-context/UserContext";
import ThemeContext from "./providers/theme/ThemeContext";
import axios from "axios";
import useLoading from "./hooks/UseLoading";
import Loading from "./hooks/Loading";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Competition from "./components/competition/Competition";
import GolfGameCard from "./components/my-competitions/GolfGameCard";
import Leagues from "./components/leagues/Leagues";

const metaArray = [
  "/login",
  "/our_mission",
  "/faqs",
  "/how_to_play",
  "/forgot_password",
  "/rules",
  "/safer_gambling?type=description",
  "/challange",
  "/contact_us",
  "/privacy_policy",
  "/terms_conditions",
  "/our_mission",
];

function App() {
  var realityCheckInterval = null;
  const [loggedInStatus, setLoggedInStatus] = useState("NOT_LOGGED_IN");
  const [loggedUser, setLoggedUser] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [duration, setDuration] = useState(0);
  const [unit, setUnit] = useState("minutes");

  const providerValue = useMemo(
    () => ({ userData, setUserData }),
    [userData, setUserData]
  );
  const [loader, showLoader, hideLoader] = useLoading();
  var url = process.env.REACT_APP_API_URL;

  const checkLoggedInStatus = () => {
    var profile_url = "" + url + "/api/my_profile/my_profile";
    var session_key = localStorage.getItem("session_key");
    if (session_key) {
      const body = {};
      const headers = {
        session_key: session_key,
      };
      axios
        .post(profile_url, body, { headers })
        .then((response) => {
          console.log("request test");
          if (response.status === 200) {
            console.log("success test");
            var user_data = response.data.Data.user_profile;
            sessionStorage.setItem("activeTime", "" + moment().unix());

            if (user_data.duration != "") {
              if (user_data.unit == "HOURS") {
                var unit = "hours";
              } else {
                var unit = "minutes";
              }
              setDuration(user_data.duration);
              setUnit(unit);
              sessionStorage.setItem(
                "expiryTime",
                "" + moment().add(user_data.duration, unit).unix()
              );
              realityCheckInterval = setInterval(() => {
                realityCheck(user_data);
              }, 1000);
            }
            var actualUserDataStorage = JSON.parse(
              localStorage.getItem("userData")
            );

            if (actualUserDataStorage) {
              actualUserDataStorage.balance = user_data.balance;
              actualUserDataStorage.kyc_status = user_data.kyc_status;
              actualUserDataStorage.kyc_status = user_data.allow_kyc;
              localStorage.setItem(
                "userData",
                JSON.stringify(actualUserDataStorage)
              );
            }

            var userProfileData = {
              user_id: user_data.user_id,
              balance: user_data.balance,
              email: user_data.email,
              first_name: user_data.first_name,
              last_name: user_data.last_name,
              winning_balance: user_data.winning_balance,
              kyc_status: user_data.kyc_status,
              master_country_id: user_data.master_country_id,
              master_state_id: user_data.master_state_id,
              phone_no: user_data.phone_no,
              status: user_data.status,
              user_name: user_data.user_name,
              address1: user_data.address1,
              address2: user_data.address2,
              zip_code: user_data.zip_code,
              dob: user_data.dob,
              city: user_data.city,
              opt_in_email: user_data.opt_in_email,
              bank_statement_image: user_data.bank_statement_image_v2,
              passport_image: user_data.passport_image_v2,
              utility_bills_image: user_data.utility_bills_image_v2,
              default_currency: user_data.default_currency,
              count_notification: user_data.unread_notification,
              allow_kyc: user_data.allow_kyc,
            };

            setUserData(userProfileData);
            localStorage.setItem("userData", JSON.stringify(userProfileData));
            setLoggedInStatus("LOGGED_IN");
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoggedInStatus("NOT_LOGGED_IN");
          localStorage.removeItem("showReminder");
          setLoading(false);
        });
    } else {
      setLoggedInStatus("NOT_LOGGED_IN");
      localStorage.removeItem("showReminder");
      setLoading(false);
      if (window.location.pathname != "/")
        sessionStorage.setItem("back_url", window.location.pathname);
    }
  };

  useEffect(() => {
    checkLoggedInStatus();
    var loggedUserData = JSON.parse(localStorage.getItem("userData"));
    if (!loggedUserData) {
      setLoggedUser(false);
    } else {
      setLoggedUser(true);
    }
  }, []);

  const realityCheck = (user_data) => {
    var user_status = localStorage.getItem("user_status");
    var expired_time = sessionStorage.getItem("expiryTime");
    var current_date = moment().unix();
    if (user_status != "unlogged") {
      if (expired_time) {
        if (current_date > moment.unix(expired_time).unix()) {
          if (user_data.unit == "HOURS") {
            var unit = "hours";
          } else {
            var unit = "minutes";
          }
          handleShow();
          sessionStorage.setItem(
            "expiryTime",
            "" + moment().add(user_data.duration, unit).unix()
          );
        }
      }
    } else {
      localStorage.removeItem("userData");
      sessionStorage.removeItem("activeTime");
      sessionStorage.removeItem("expiryTime");
      clearInterval(realityCheckInterval);
    }
  };

  return (
    <BrowserRouter>
      <UserContext.Provider value={providerValue}>
        <ThemeContext>
          <ScrollTop />
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Reality Check</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              You have been played for {duration + " " + unit}
            </Modal.Body>
          </Modal>
          <Routes>
            <Route path="/index" element={<Index />} />
            <Route path="/new" element={<IndexDraft />} />
            <Route path="/home" element={<Home />} />
            <Route path="/sports_leagues/:sportId" element={<Leagues />} />
            {/* {!loading ? (
            loggedInStatus === "NOT_LOGGED_IN" ? (
              <Route path="/" exact element={Index} />
            ) : (
              <Route path="/" exact element={Home} />
            )
          ) : (
            <Loading />
          )} */}
            <Route
              path="/"
              exact
              element={
                loading ? (
                  <Loading />
                ) : loggedInStatus === "NOT_LOGGED_IN" ? (
                  <Index />
                ) : (
                  <Home />
                )
              }
            />

            <Route path="/our_mission" element={<OurMission />} />
            <Route path="/safer_gambling" element={<SaferGambling />} />
            <Route path="/challange" element={<Challange />} />
            <Route path="/rules" element={<Rules />} />
            <Route path="/faqs" element={<Faqs />} />
            <Route path="/terms_conditions" element={<TermsConditions />} />
            <Route path="/privacy_policy" element={<PrivacyPolicy />} />
            <Route path="/contact_us" element={<Feedback />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register-ghana" element={<RegisterMobileGhana />} />
            <Route
              path="/register-ghana-v2"
              element={<RegisterMobileGhanaV2 />}
            />
            {/* <Route path="/register-ghana/verify" element={<SMSVerifyGhana />} /> */}
            <Route
              path="/register-ghana/verify/:number"
              element={<SMSVerifyGhana />}
            />
            <Route path="/register" element={<RegisterMobileGhanaV2 />} />
            <Route path="/forgot_password" element={<ForgotPassword />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/sumsub_verification" element={<Sumsub />} />
            <Route path="/account" element={<Account />} />
            <Route path="/how_to_play" element={<HowToPlay />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route
              path="/sports_leagues/:sportId/:leagueId"
              element={<SportsLeagues />}
            />
            <Route
              path="/sports_leagues/:sportId/:leagueId/:contestId"
              element={<SportsLeagues />}
            />
            <Route
              path="/competition/:sportId/:contestId"
              element={<SportsLeagues />}
            />
            <Route path="/my_competitions" element={<Competitions />} />
            <Route path="/gamecard/:id/:uid" element={<GameCard />} />
            <Route path="/golf/gamecard/:id/:uid" element={<GolfGameCard />} />
            <Route path="/leaderboard/:sport/:uid" element={<Leaderboard />} />
            <Route
              path="/golf/leaderboard/:sport/:uid"
              element={<GolfLeaderboard />}
            />
            <Route
              path="/private_competition/:sportId/:leagueId"
              element={<CreatePrivateCompetition />}
            />
            {/* <Route path="/club_fundraiser/:sportId" element={<CreateClubFundraiser />} /> */}
            <Route
              path="/club_fundraiser/:sportId/:leagueId"
              element={<CreateClubFundraiser />}
            />

            <Route path="/my_competition" element={<MyCompetition />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route
              path="/activate-account/:token"
              element={<ActivateAccount />}
            />
            <Route path="/join/:contestId" element={<JoinCompetition />} />
            <Route path="/privateinvites" element={<JoinCompetition />} />
            <Route path="/competition" element={<Competition />} />
            <Route
              path="/submited/safer_gambling/:type"
              element={<SubmitedSaferGambling />}
            />
            <Route path="*" to="/" />
          </Routes>
          {loader}
        </ThemeContext>
      </UserContext.Provider>
    </BrowserRouter>
  );
}

export default App;
