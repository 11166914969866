import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ImageContainer from "../image-container/ImageContainer";
import LoginHeader from "../login/LoginHeader";
import { Helmet } from "react-helmet";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import Parser from "html-react-parser";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../competition/Competition.css";

export default function Competition() {
  const [loggedStatus, setLoggedStatus] = useState("");
  const [competitionContent, setCompetitionContent] = useState("");
  const [competitionName, setCompetitionName] = useState("");
  var url = process.env.REACT_APP_API_URL;
  const [loader, showLoader, hideLoader] = useLoading();
  const [alert, alert_notify] = useAlert();
  const navigate = useNavigate();

  useEffect(() => {
    var logged_status = localStorage.getItem("user_status");
    if (logged_status === "unlogged") {
      setLoggedStatus("unlogged");
    } else {
      setLoggedStatus("logged");
    }
    var competition_url =
      "" + url + "/api/static_content/get_all_static_content";
    const body = { page_meta_key: "competition" };

    showLoader();
    axios
      .post(competition_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          var page_content = data.content.page_content;
          setCompetitionContent(page_content);
          var page_name = data.content.page_name;
          setCompetitionName(page_name);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MATCH MY SCORE | Competition</title>
        <meta
          name="description"
          content="Here are the rules about the competitions, entry fees and competition prizes. Get all the game play rules here. "
        />
      </Helmet>
      {loggedStatus === "logged" ? <Header /> : <LoginHeader />}
      <ImageContainer />
      <div className="footer_competiton_container row m-0 justify-content-between">
        <div className="page_content_center">
          <div className="footer_competition_title col-md-12">
            <h2 className="text-uppercase">{Parser(competitionName)}</h2>
          </div>
          <div className="footer_competitions_content col-md-12">
            {Parser(competitionContent)}
          </div>
        </div>
      </div>
      <Footer />
      {loader}
      {alert}
    </React.Fragment>
  );
}
