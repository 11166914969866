import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import useComingSoonModal from "../../hooks/useComingSoonModal";

const SportsBanner = ({ activeSport = null }) => {
  var url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const { state } = useLocation();
  const { openModal, Modal } = useComingSoonModal();
  const [sportsBanner, setSportsBanner] = useState([]);
  useEffect(() => {
    fetch();
  }, []);
  const fetch = async () => {
    const { data } = await axios.get(
      `${url}/api/second_landing_page/get_sports_banner`
    );
    let sportsRes = data?.data?.sportsBanner;
    setSportsBanner(sportsRes);
  };
  useEffect(() => {}, [sportsBanner]);
  return (
    <>
      <div class="p-3 d-flex justify-content-center m-auto text-center primary-background">
        <div class="row">
          {sportsBanner?.map((sport, index) => {
            return (
              <div
                class="col d-flex justify-content-center align-items-center"
                style={{
                  borderRight:
                    index !== sportsBanner.length - 1
                      ? "1px solid #9a9a9a"
                      : "",
                }}
              >
                <span
                  className={`display_name_text ${
                    activeSport === sport.sport_id ? "display_name_active" : ""
                  }`}
                  onClick={(e) => {
                    if (sport.sport_id === 0) {
                      openModal();
                    } else {
                      if (sport?.link?.length > 0) {
                        navigate(sport.link);
                      } else {
                        navigate(`/sports_leagues/${sport.sport_id}`, {
                          state: {
                            sport_name:
                              sport?.link?.length > 0
                                ? sport.sport_name
                                : sport?.name,
                            id: sport.sport_id,
                          },
                        });
                      }
                    }
                  }}
                >
                  {sport?.name}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <Modal />
    </>
  );
};

export default SportsBanner;
