import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import "../notifications/Notifications.css";
import ProfileAccount from "../account/ProfileAccount";
import ButtonPrimary from "../buttons/ButtonPrimary";
import axios from "axios";
import useLoading from "../../hooks/UseLoading";
import { useNavigate, useParams } from "react-router-dom";
import useAlert from "../../hooks/UseAlert";
import moment from "moment";

export default function Notifications() {
  const [notificationsLabels, setNotificationsLabels] = useState([]);
  const [contestUid, setContestUid] = useState([]);
  const [notificationIds, setNotificationsIds] = useState([]);
  const [sportId, setSportIs] = useState([]);
  const [notificationsData, setNotificationsData] = useState([]);
  const [notifications, setNotifications] = useState(false);
  var url = process.env.REACT_APP_API_URL;
  const [loader, showLoader, hideLoader] = useLoading();
  const navigate = useNavigate();
  const [alert, alert_notify] = useAlert();
  const [loading, setLoading] = useState(true);

  const getAllNotifications = () => {
    var notifications_labels_url =
      "" + url + "/api/static_content/get_all_labels";
    var body = {
      page_name: "notifications",
    };
    axios
      .post(notifications_labels_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data.content;
          setNotificationsLabels(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Fetch all notifivations
  const fetchAllNotifications = () => {
    var notification_url =
      "" + url + "/api/notifications/get_all_notifications";
    var session_key = localStorage.getItem("session_key");
    var body = {
      current_page: 1,
      items_perpage: 10,
    };
    var headers = {
      session_key: session_key,
    };
    showLoader();
    axios
      .post(notification_url, body, { headers })
      .then((response) => {
        hideLoader();
        if (response.status === 200) {
          var result = response.data.Data.Notifications;
          setNotifications(result);
          setNotificationsData(result);
          var notification_ids = [];
          result.map((notification) => {
            var contest_uid = notification.contest_uid;
            setContestUid(contest_uid);
            var sport_id = notification.sport_id;
            setSportIs(sport_id);
            if (notification.is_read === "0") {
              notification_ids.push(notification.notification_id);
            }
            if (notification_ids.length > 0) {
              setNotificationsIds(notification_ids);
            }
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  // Notifications as read
  const markUnreadNotificationsAsRead = () => {
    var notification_is_read_url = "" + url + "/api/notifications/is_read";
    var session_key = localStorage.getItem("session_key");
    var body = {};
    var headers = {
      session_key: session_key,
    };
    showLoader();
    axios
      .post(notification_is_read_url, body, { headers })
      .then((response) => {
        hideLoader();
        if (response.status === 200) {
          var result = response.data.Data.result;
          var loggedUserData = JSON.parse(localStorage.getItem("userData"));
          loggedUserData.count_notification = 0;
          localStorage.setItem("userData", JSON.stringify(loggedUserData));
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  // Accept contest invitation
  const acceptInvitation = (contestUid) => {
    var accept_invitation_url =
      "" + url + "/api/contest_invite/accept_invitation";
    var session_key = localStorage.getItem("session_key");
    var body = {
      contest_uid: contestUid,
    };
    var headers = {
      session_key: session_key,
    };
    showLoader();
    axios
      .post(accept_invitation_url, body, { headers })
      .then((response) => {
        if (response.status === 200) {
          // navigate("/gamecard/" + sportId + "/" + contestUid);
          alert_notify("success", "Invitation accepted successfully");
          window.location.reload();
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.GlobalError);
        }
        hideLoader();
      });
  };

  // Reject invitation
  const rejectInvitation = (contestUid, notificationIndex) => {
    var reject_invitation_url =
      "" + url + "/api/contest_invite/reject_invitation";
    var session_key = localStorage.getItem("session_key");
    var body = {
      contest_uid: contestUid,
    };
    var headers = {
      session_key: session_key,
    };
    showLoader();
    axios
      .post(reject_invitation_url, body, { headers })
      .then((response) => {
        hideLoader();
        if (response.status === 200) {
          // navigate("/gamecard/" + sportId + "/" + contestUid);
          alert_notify("success", "Invitation rejected successfully");
          window.location.reload();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.GlobalError);
        }
        hideLoader();
      });
  };

  useEffect(() => {
    getAllNotifications();
    fetchAllNotifications();
    markUnreadNotificationsAsRead();
  }, []);

  return (
    <React.Fragment>
      <Header />
      <div className="notifications_container">
        <ProfileAccount
          headerValue={
            !notificationsLabels.notification_page_label
              ? "NOTIFICATIONS"
              : notificationsLabels.notification_page_label.label_name
          }
        />
        <div className="my_notification_container primary-background">
          <div className="page_content_center">
            <div className="my_notifications_container">
              {notifications.length > 0 ? (
                <div>
                  {!loading
                    ? notificationsData.map((notification, index) => {
                        var contest_uid = notification.contest_uid;
                        var created_date = notification.created_date;
                        var notification_type = notification.notification_type;
                        var contest_type = notification.contest_type;
                        var contest_name = notification.contest_name;
                        var first_name = notification.first_name;
                        var last_name = notification.last_name;
                        var email = notification.email;
                        var organisation_name = notification.organisation_name;
                        var message = notification.notification;
                        var expired = notification.expired;

                        var dateTimeFormat = "DD MMM YYYY HH:mm";
                        var dateTimeUtc = moment.utc(created_date);
                        var localDate = dateTimeUtc.local();
                        var notification_date =
                          localDate.format(dateTimeFormat);
                        var message_html = "";
                        var notification_html = "";
                        var invitation_html = "";

                        if (
                          notification_type === "CONTEST_INVITATION" &&
                          contest_type === "club"
                        ) {
                          message_html = (
                            <div className="text-white">
                              Club fundraiser invite from:{" "}
                              <span className="text-white">
                                {organisation_name}
                              </span>
                            </div>
                          );
                        } else if (
                          notification_type === "CONTEST_INVITATION" &&
                          contest_type !== "club" &&
                          contest_name === "Private Contest"
                        ) {
                          message_html = (
                            <div>
                              Private contest invite from:{" "}
                              <span>
                                {first_name.toUpperCase() +
                                  "-" +
                                  last_name.toUpperCase()}
                              </span>
                            </div>
                          );
                        } else if (
                          notification_type === "CONTEST_INVITATION" &&
                          contest_type !== "club" &&
                          contest_name !== "Private Contest"
                        ) {
                          message_html = (
                            <div>
                              Challenge from: <span>{email}</span>
                            </div>
                          );
                        } else if (notification_type == "KYC") {
                          message_html = <div> KYC </div>;
                        }
                        if (notification_type === "CONTEST_INVITATION") {
                          notification_html = <div>{message}</div>;
                        } else if (notification_type === "KYC") {
                          notification_html = <div>{message}</div>;
                        } else if (
                          notification_type === "ADMIN_FREE_TEXT_NOTIFICATION"
                        ) {
                          notification_html = <div>{message}</div>;
                        } else if (notification_type === "CONTEST_CANCELLED") {
                          message_html = (
                            <div className="text-white">
                              Competition Cancelled
                            </div>
                          );
                          notification_html = <div>{message}</div>;
                        }
                        if (
                          notification_type == "CONTEST_INVITATION" &&
                          notification.contest_invite_status == 1
                        ) {
                          invitation_html = (
                            <div>
                              <p className="text-white">Invitation Accepted</p>
                            </div>
                          );
                        } else if (notification.contest_invite_status == 2) {
                          invitation_html = (
                            <div>
                              <p className="text-white">Invitation Rejected</p>
                            </div>
                          );
                        } else if (notification.expired == 1) {
                          invitation_html = (
                            <div>
                              <p className="text-white">
                                Competition has expired
                              </p>
                            </div>
                          );
                        } else if (notification.can_join == 0) {
                          invitation_html = (
                            <div>
                              <p className="text-white">Competition is full</p>
                            </div>
                          );
                        } else if (notification_type == "KYC") {
                          invitation_html = "";
                        }
                        return (
                          <div
                            className="row primary-background"
                            data-contestid={contest_uid}
                            key={index}
                          >
                            <div className="col-12 col-lg-3 col-xl-3">
                              <span className="text-white">
                                {notification_date}
                              </span>
                            </div>
                            <div className="col-12 col-xl-6 col-lg-6">
                              <span className="text-white">
                                <span className="notification_title text-white">
                                  {message_html}
                                </span>
                                <br />
                                <span className="text-white">
                                  {notification_html}
                                </span>
                              </span>
                            </div>
                            <div className="col-12 col-xl-2 col-lg-2 m-2">
                              {notification.can_join == 1 &&
                              notification.contest_invite_status == 0 &&
                              expired != 1 ? (
                                <div>
                                  <ButtonPrimary
                                    classValue={
                                      "btn_view_all_sports mb-2 btn_primary header_btn active"
                                    }
                                    textValue={"Accept"}
                                    onClick={() =>
                                      acceptInvitation(contest_uid)
                                    }
                                  />
                                  <ButtonPrimary
                                    classValue={
                                      "btn_view_all_sports btn_primary header_btn active"
                                    }
                                    textValue={"Reject"}
                                    onClick={() =>
                                      rejectInvitation(contest_uid, index)
                                    }
                                  />
                                </div>
                              ) : (
                                invitation_html
                              )}
                              <div className="m-2"></div>
                            </div>
                            <hr />
                          </div>
                        );
                      })
                    : ""}
                </div>
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  <span className="no_notifications_message">
                    There are no notifications to be shown.
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {loader}
      {alert}
      <Footer />
    </React.Fragment>
  );
}
