import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../login/Login.css";
import axios from "axios";
import { useThemeProvider } from "../../providers/theme/ThemeContext";

export default function LoginHeader(props) {
  const { colors } = useThemeProvider();
  const [showMenu, setShowMenu] = useState(false);
  const [logo, setLogo] = useState("");
  const [openHowToPlay, setHowToPlay] = useState(false);
  const [loginHeaderLabels, setLoginHeadersLabels] = useState([]);
  const indexMenu = useRef();
  var url = process.env.REACT_APP_API_URL;

  const openHowToPlayContainer = () => {
    setHowToPlay(!openHowToPlay);
  };

  const getHeaderLabels = () => {
    var header_labels_url = "" + url + "/api/static_content/get_all_labels";
    var body = {
      page_name: "header",
    };
    axios
      .post(header_labels_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data.content;
          setLoginHeadersLabels(data);
          setLogo(response.data.Data.logo);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getHeaderLabels();
    var btn_open_index_menu = document.querySelector(".btn_open_index_menu");
    var btn_open_index_menu_child = document.querySelector(
      ".btn_open_index_menu"
    ).childNodes[0];
    document.addEventListener("click", (e) => {
      if (
        e.target !== btn_open_index_menu &&
        e.target !== btn_open_index_menu_child
      ) {
        if (indexMenu.current && !indexMenu.current.contains(e.target)) {
          setShowMenu(false);
        }
      }
    });
  }, []);

  return (
    <React.Fragment>
      <div
        className="login_header_container"
        style={{
          //backgroundImage : "url(/assets/image/concrete-wall.png)"
          backgroundColor: colors.primary,
        }}
      >
        <nav className="navbar nav navbar-expand-lg navbar-light">
          <Link to="/index" className="logo_icon">
            <span>
              {logo !== undefined ? (
                <img
                  src={`${process.env.REACT_APP_AWS_ASSETS_URL}tenants/${logo}`}
                  alt="top-predictor"
                  className="top_predictor_logo"
                />
              ) : null}
            </span>
          </Link>
          <button
            className="navbar-toggler btn_open_index_menu"
            type="button"
            onClick={() => setShowMenu(!showMenu)}
            aria-controls="header-navbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <img src="/assets/icons/navbar.svg" alt="navbar-icon" />
          </button>
          <div
            ref={indexMenu}
            style={showMenu ? { display: "block" } : { display: "none" }}
            className={"collapse navbar-collapse"}
            id="header-navbar"
          >
            <div className="navbar-nav">
              <div className="navbar_button_container">
                <Link
                  to="/how_to_play"
                  className={
                    props.howToPlayValue
                      ? "btn_how_to_play active"
                      : "btn_how_to_play"
                  }
                  onClick={openHowToPlayContainer}
                >
                  HOW TO PLAY
                </Link>
                <Link
                  to="/login"
                  className={
                    props.loginValue ? "btn_login active" : "btn_login"
                  }
                  style={{ textTransform: "uppercase" }}
                >
                  {!loginHeaderLabels.login
                    ? "LOGIN"
                    : loginHeaderLabels.login.label_name}
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </React.Fragment>
  );
}
