import React, { useEffect, useState } from "react";
import ButtonPrimary from "../buttons/ButtonPrimary";
import "../safer-gambling/SaferGambling.css";
import { redirect } from "react-router-dom";
import axios from "axios";
import Parser from "html-react-parser";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";

export default function Timeout(props) {
  const [timeoutStaticContent, setTimeoutStaticContent] = useState("");
  const [loader, showLoader, hideLoader] = useLoading();
  const [alert, alert_notify] = useAlert();
  var body, headers;
  var url = process.env.REACT_APP_API_URL;
  var session_key = localStorage.getItem("session_key");

  const getStaticContent = () => {
    var static_content_url =
      "" + url + "/api/static_content/get_all_static_content";
    if (props.type === "description") {
      body = {
        page_meta_key: "sg_timeout",
      };
      headers = {};
    } else {
      body = {
        page_meta_key: "sg_timeout",
      };
      headers = {
        session_key: session_key,
      };
    }
    showLoader();
    axios
      .post(static_content_url, body, { headers })
      .then(function (response) {
        if (response.status === 200) {
          var content = response.data.Data.content.page_content;
          setTimeoutStaticContent(content);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          redirect("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  const submitTimeOut = () => {
    var select_time_out = document.getElementById("select_time_out").value;
    if (select_time_out === "6") {
      body = {
        duration: select_time_out,
        unit: "Hours",
      };
    } else {
      body = {
        duration: select_time_out,
        unit: "Days",
      };
    }
    const headers = {
      session_key: session_key,
    };
    var url = `${process.env.REACT_APP_API_URL}/api/safer_gambling/self_timeout`;
    showLoader();
    axios
      .post(url, body, { headers })
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          localStorage.setItem(
            "timeout_duration",
            JSON.stringify(body.duration)
          );
          localStorage.setItem("timeout_unit", JSON.stringify(body.unit));
          redirect("/submited/safer_gambling/timeout");
          setTimeout(() => {
            redirect("/login");
          }, 2000);
        } else if (response.status === 401) {
          redirect("/login");
        } else {
          console.log(response.error);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          redirect("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Message);
        }
        hideLoader();
      });
  };

  useEffect(() => {
    getStaticContent();
    localStorage.removeItem("timeout_duration");
    localStorage.removeItem("timeout_unit");
  }, []);

  return (
    <React.Fragment>
      <div className="timeout safer_gambling_container mt-3 primary-background">
        <div className="safer_title">{Parser(timeoutStaticContent)}</div>
        {props.type === "gambling" ? (
          <div className="gambling_container">
            <div className="gambling_center">
              <div className="gambling_select mb-4 mt-4">
                <label className="text-white">
                  {!props.labels.safer_gambling_timeout
                    ? "Timeout"
                    : props.labels.safer_gambling_timeout.label_name}
                </label>
                <select id="select_time_out">
                  <option value="0">Select Timeout Here </option>
                  <option value="30">30 Days</option>
                  <option value="7">7 Days</option>
                  <option value="1">1 Day</option>
                  <option value="6">6 Hours</option>
                </select>
              </div>
              <div className="gambling_btns">
                <ButtonPrimary
                  classValue="btn_primary btn_submit_deposit_limit"
                  textValue={
                    !props.labels.safer_gambling_submit
                      ? "Submit"
                      : props.labels.safer_gambling_submit.label_name
                  }
                  onClick={submitTimeOut}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {loader}
      {alert}
    </React.Fragment>
  );
}
