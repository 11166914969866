import React, {useState, useEffect} from 'react'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import SuccesNotification from '../../hooks/SuccesNotification'
import { useParams, redirect } from 'react-router-dom'
import axios from 'axios'
import useLoading from '../../hooks/UseLoading';
import useAlert from '../../hooks/UseAlert';


export default function SubmitedSaferGambling(props) {
    var url                                                 = process.env.REACT_APP_API_URL
    let { type }                                            = useParams();
    const [loader, showLoader, hideLoader]                  = useLoading();
    const [alert, alert_notify]                             = useAlert();
    var session_key                                         = localStorage.getItem("session_key");
    const [depositDaily, setDepositDaily]                   = useState([])
    const [depositMonthly, setDepositMonthly]               = useState([])
    const [depositWeekly, setDepositWeekly]                 = useState([])
    const [wagerDaily, setWagerDaily]                       = useState([])
    const [wagerMonthly, setWagerMonthly]                   = useState([])
    const [wagerWeekly, setWagerWeekly]                     = useState([])
    const [realityCheck, setRealityCheck]                   = useState([])
    const [loading, setLoading]                             = useState(true)
    const [timeoutDuration, setTimeoutDuration]             = useState()
    const [timeoutUnit, setTimeoutUnit]                     = useState()


    const getDepositLimitInfo = (deposit_type) => {
        var static_content_url = `${url}/api/safer_gambling/get_deposit_limit`

        var body = { 
            amount : "",
            duration : 1,   
            modified_date : "", 
            unit: deposit_type
        };

        var headers = {  'session_key': session_key };
        showLoader();
        axios.post(static_content_url, body, { headers })
        .then(function (response) {
            if(response.status === 200){
                var data = response.data
                if(data.Data.Deposit_limit_info.length > 0){
                    if(data.Data.Deposit_limit_info[0].unit === 'DAYS'){
                        setDepositDaily(data.Data.Deposit_limit_info[0])
                    }
                    if(data.Data.Deposit_limit_info[0].unit === 'MONTHS'){
                        setDepositMonthly(data.Data.Deposit_limit_info[0])
                    }
                    if(data.Data.Deposit_limit_info[0].unit === 'WEEKS'){
                        setDepositWeekly(data.Data.Deposit_limit_info[0])
                    }
                }
                setLoading(false)
            }
            hideLoader()
        })
        .catch((err)=>{
            // console.log(err.response)
            if(err.response.status === 401){
                redirect('/login')
                localStorage.removeItem("session_key");
            }else{
                alert_notify('error', err.response.data.Message)
            }
            hideLoader();
        });
    }

    const getWagerLimitInfo = (wager_type) => {
        var get_wager_limit_url = ""+url+"/api/safer_gambling/get_wager_limit"

        var body = {
            amount: "",
            duration: 1,
            modified_date: "",
            unit: wager_type,
        }

        var headers = {  'session_key': session_key };
        showLoader();
        axios.post(get_wager_limit_url, body, { headers })
        .then(function (response) {
            if(response.status === 200){
                var data = response.data
                if(data.Data.Wager_limit_info.length > 0){
                    if(data.Data.Wager_limit_info[0].unit === 'DAYS'){
                        setWagerDaily(data.Data.Wager_limit_info[0])
                    }
                    if(data.Data.Wager_limit_info[0].unit === 'MONTHS'){
                        setWagerMonthly(data.Data.Wager_limit_info[0])
                    }
                    if(data.Data.Wager_limit_info[0].unit === 'WEEKS'){
                        setWagerWeekly(data.Data.Wager_limit_info[0])
                    }
                }
                setLoading(false)
            }
            hideLoader()
        })
        .catch((err)=>{
            // console.log(err.response)
            if(err.response.status === 401){
                redirect('/login')
                localStorage.removeItem("session_key");
            }else{
                alert_notify('error', err.response.data.Message)
            }
            hideLoader();
        });
    }

    const getRealityCheckInfo = () =>{
        var reality_check_url = ""+url+"/api/safer_gambling/get_reality_check"

        var body = {}
        var headers = {  'session_key': session_key };
        showLoader();
        axios.post(reality_check_url, body, { headers })
        .then(function (response) {
            if(response.status === 200){
                var data = response.data
                if(data.Data.Reality_check_info !== undefined){
                    setRealityCheck(data.Data.Reality_check_info[0])
                }
                setLoading(false)
            }
            hideLoader()
        })
        .catch((err)=>{
            // console.log(err.response)
            if(err.response.status === 401){
                redirect('/login')
                localStorage.removeItem("session_key");
            }else{
                alert_notify('error', err.response.data.Message)
            }
            hideLoader();
        });

    }

    useEffect(()=>{
        if(type === 'deposit'){
            getDepositLimitInfo('DAYS');
            getDepositLimitInfo('WEEKS');
            getDepositLimitInfo('MONTHS');
        }

        if(type === 'wager'){
            getWagerLimitInfo('DAYS');
            getWagerLimitInfo('WEEKS');
            getWagerLimitInfo('MONTHS');
        }

        if(type === 'realitycheck'){
            getRealityCheckInfo()
        }

        if(type === 'timeout'){
            var timeout_duration = localStorage.getItem('timeout_duration')
            if(timeout_duration !== null){
                setTimeoutDuration(timeout_duration)
            }
            var timeout_unit = localStorage.getItem('timeout_unit')
            if(timeout_unit !== null){
                setTimeoutUnit(timeout_unit)
            }
        }
    }, [])

    return (
        <React.Fragment>
            <Header/>
                <div className="safer_gambling_container">
                    <div className="page_content_center">
                        <div className="gambling_container">
                            <SuccesNotification title="" content="Your request has been received."/>
                            <div className="gambling_center">
                                {
                                    type === 'deposit' ? 
                                    <div className="submited_values mt-5">
                                        <h4 className="text-center">DEPOSIT LIMIT</h4>
                                        <div className="gambling_select mb-4 mt-4">
                                            <label>Daily Limit</label>
                                            {
                                               depositDaily !== undefined ? <input type="text" value={depositDaily.amount} id="deposit_submited_daily_value" readOnly/> : ''  
                                            }
                                        </div>
                                        <div className="gambling_select mb-4">
                                            <label>Weekly Limit</label>
                                            {
                                               depositWeekly !== undefined ? <input type="text" value={depositWeekly.amount} id="deposit_submited_weekly_value" readOnly/> : ''  
                                            }
                                        </div>
                                        <div className="gambling_select mb-4">
                                            <label>Monthly Limit</label>
                                            {
                                                depositMonthly !== undefined ? <input type="text" value={depositMonthly.amount} id="deposit_submited_monthly_value" readOnly/> : ''
                                            }
                                            
                                        </div>
                                    </div> : ''
                                }
                                {
                                    type === 'wager' ? 
                                    <div className="submited_values mt-5">
                                        <h4 className="text-center">WAGER LIMIT</h4>
                                        <div className="gambling_select mb-4 mt-4">
                                            <label>Daily Limit</label>
                                            {
                                                wagerDaily !== undefined ? <input type="text" value={wagerDaily.amount} id="wager_submited_daily_value" readOnly/> : ''
                                            }
                                            
                                        </div>
                                        <div className="gambling_select mb-4">
                                            <label>Weekly Limit</label>
                                            {
                                                wagerWeekly !== undefined ?  <input type="text" value={wagerWeekly.amount} id="wager_submited_weekly_value" readOnly/> : ''
                                            }
                                           
                                        </div>
                                        <div className="gambling_select mb-4">
                                            <label>Monthly Limit</label>
                                            {
                                                wagerMonthly !== undefined ? <input type="text" value={wagerMonthly.amount} id="wager_submited_monthly_value" readOnly/> : ''
                                            }
                                            
                                        </div>
                                    </div> : ''
                                }
                                {
                                    type === 'realitycheck' ?
                                    <div className="submited_values mt-5">
                                        <h4 className="text-center">REALITY CHECK</h4>
                                        <div className="gambling_select mb-4 mt-4">
                                            <label>Period of Time</label>
                                            {
                                                realityCheck !== undefined ? <input type="text" value={realityCheck.duration + ' ' + realityCheck.unit} id="reality_check_submited_value" readOnly/> : ''
                                            }
                                        </div>
                                    </div> : ''
                                }
                                {
                                    type === 'timeout' ? 
                                    <div className="submited_values mt-5">
                                        <h4 className="text-center">TIMEOUT</h4>
                                        <div className="gambling_select mb-4 mt-4">
                                            <label>Timeout</label>
                                            {
                                                (timeoutDuration !== undefined && timeoutUnit !== undefined) ? <input type="text" value={timeoutDuration + " " + timeoutUnit} id="reality_check_submited_value" readOnly/> : ''
                                            }
                                            
                                        </div>
                                    </div> : ''
                                }
                                {
                                    type === 'selfexclusion' ? 
                                    <div className="submited_values mt-5">
                                        
                                    </div> : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            <Footer/>
            {loader}
            {alert}
        </React.Fragment>
    )
}
