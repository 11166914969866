import React, {useEffect, useState} from 'react'
import ButtonPrimary from '../buttons/ButtonPrimary'
import useLoading from '../../hooks/UseLoading'
import useAlert from '../../hooks/UseAlert';
import '../profile/Profile.css'
import axios from 'axios'
import { redirect } from 'react-router-dom'
import SuccesNotification from '../../hooks/SuccesNotification'

function encryptPassword(password){
    return btoa(password);
}

export default function ChangePassword(props) {

    const [validPassword, setValidPassowrd]                 = useState(false)
    const [validNewPassword, setValidNewPassowrd]           = useState(false)
    const [validConfirmPassword, setValidConfirmPassword]   = useState(false)
    const [userEmail, setUserEmail]                         = useState('')
    const [successStatus, setSuccesStatus]                  = useState(false)
    var url                                                 = process.env.REACT_APP_API_URL
    const [alert, alert_notify]                             = useAlert();
    const [loader, showLoader, hideLoader]                  = useLoading();
    var session_key                                         = localStorage.getItem("session_key");
    

    var loggedUserData = JSON.parse(localStorage.getItem('userData'))

    const getPasswordChangeData = () =>{
        var current_passord     = document.getElementById('input_change_current_password').value
        var new_password        = document.getElementById('input_change_new_password').value
        var confirm_password    = document.getElementById('input_change_confirm_password').value

        if(current_passord !== ""){
            setValidPassowrd(true)
        }else{
            setValidPassowrd(false)
        }
        if(new_password !== ""){
            setValidNewPassowrd(true)
        }else{
            setValidNewPassowrd(false)
        }        
        if(confirm_password !== ""){
            setValidConfirmPassword(true)
        }else{
            setValidConfirmPassword(false)
        }

    }

    const submitPasswordChange = (e) =>{
        e.preventDefault()
        var change_password_url = ""+url+"/api/my_profile/add_update_password"

        if(validPassword && validNewPassword && validConfirmPassword){
            var current_passord     = document.getElementById('input_change_current_password').value
            var new_password        = document.getElementById('input_change_new_password').value
            var confirm_password    = document.getElementById('input_change_confirm_password').value

            if(encryptPassword(new_password) === encryptPassword(confirm_password)){
                var body = {
                    "new_password": encryptPassword(new_password),
                    "old_password": encryptPassword(current_passord),
                    "req": "changePassword"   
                }

                var headers = {
                    "session_key"  : session_key
                }
                showLoader()
                axios.post(change_password_url, body, {headers})
                .then(response => {
                    if(response.status === 200){
                        alert_notify('success', response.data.Message)
                        setSuccesStatus(true)
                    }
                    hideLoader()
                })
                .catch((err)=>{
                    if(err.response.status === 401){
                        redirect('/login')
                        localStorage.removeItem("session_key");
                     }else{
                        alert_notify('error', err.response.data.Message.change_old_password)
                    }
                    hideLoader();
                });
            }else{
                alert_notify('error', 'New password must be same with confirm password.')
            }
        }else{
            alert_notify('error', 'Please type required data.')
        }
    }

    useEffect(() => {
        if(loggedUserData !== null){
            setUserEmail(loggedUserData.email)
        }
        setSuccesStatus(false)
    }, [])

    return (
        <React.Fragment>
            <div className="change_password_container">
                <div className="center_container">
                    {
                        successStatus ? <SuccesNotification title="Email sent" content={"Please check your inbox or spam folder at "+userEmail+" for a link to update your password."}/> :
                            <div className="chnage_password_center">
                                <form id="form_change_password" onSubmit={submitPasswordChange}>
                                    <label className="lbl_change_password" style={{textTransform: 'uppercase'}}>
                                    {
                                        !props.labels.update_form_cp_page_title ? 'CHANGE PASSWORD' : props.labels.update_form_cp_page_title.label_name
                                    }
                                    </label>
                                    <div className="row mt-row">
                                        <div className="col-12">
                                            <input type="password" className="profile_input" id="input_change_current_password" placeholder=      
                                            {
                                                !props.labels.update_form_cp_curr_pwd_label ? 'Current Password' : props.labels.update_form_cp_curr_pwd_label.label_name
                                            }/>
                                        </div>
                                    </div>
                                    <div className="row mt-row">
                                        <div className="col-12">
                                            <input type="password" className="profile_input" id="input_change_new_password" placeholder= {
                                                !props.labels.update_form_cp_new_pwd_label ? 'New Password' : props.labels.update_form_cp_new_pwd_label.label_name
                                            }/>
                                        </div>
                                    </div>
                                    <div className="row mt-row">
                                        <div className="col-12">
                                            <input type="password" className="profile_input" id="input_change_confirm_password" placeholder={
                                                !props.labels.update_form_cp_conf_pwd_label ? 'Confirm Password' : props.labels.update_form_cp_conf_pwd_label.label_name
                                            }/>
                                        </div>
                                    </div>
                                    <div className="row mt-row">
                                        <div className="col-12 update_password_btn_container">
                                            <ButtonPrimary textValue={
                                                !props.labels.update_form_cp_submit_btn ? 'Update Password' : props.labels.update_form_cp_submit_btn.label_name
                                            } classValue="btn_primary btn_update_password" form="form_change_password" type="submit" onClick = {getPasswordChangeData}/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                    }
                </div>
            </div>
            {alert}
            {loader}
        </React.Fragment>
    )
}
