import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import "../user-menu/UserMenu.css";

const UserMenu = React.forwardRef((props, ref) => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState([]);

  const goToThisPage = (e) => {
    var path = e.target.getAttribute("data-path");
    var mode = e.target.getAttribute("data-mode");
    if (mode === "logout") {
      localStorage.setItem("user_status", "unlogged");
      localStorage.removeItem("userData");
      localStorage.removeItem("showReminder");
      sessionStorage.removeItem("activeTime");
      sessionStorage.removeItem("expiryTime");
      navigate("/" + path + "");
      window.location.reload();
    } else {
      navigate("/" + path + "");
    }
  };

  useEffect(() => {
    var loggedUserData = JSON.parse(localStorage.getItem("userData"));
    if (loggedUserData !== null) {
      var userId = loggedUserData.user_id;
      setUserId(userId);
    }
  }, []);

  return (
    <React.Fragment>
      <div className="user_menu_center position-relative">
        <div
          ref={ref}
          className="user_menu"
          style={props.displayMenu ? { display: "block" } : { display: "none" }}
        >
          <button
            className="btn_myprofile header_label"
            onClick={goToThisPage}
            data-path="profile"
          >
            {!props.labels.my_profile
              ? "My Profile"
              : props.labels.my_profile.label_name}
          </button>
          <button
            className="btn_myaccount header_label"
            onClick={goToThisPage}
            data-path="account"
          >
            {!props.labels.my_account
              ? "My Account"
              : props.labels.my_account.label_name}
          </button>
          <button
            className="btn_safergabmling header_label"
            onClick={goToThisPage}
            data-path="safer_gambling?type=gambling"
          >
            {!props.labels.safer_gambling
              ? "Safer Gambling"
              : props.labels.safer_gambling.label_name}
          </button>
          <button
            className="btn_logout header_label"
            onClick={goToThisPage}
            data-path="index"
            data-mode="logout"
          >
            {!props.labels.logout ? "Log Out" : props.labels.logout.label_name}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
});

export default UserMenu;
