import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ButtonSecondary from "../buttons/ButtonSecondary";
import "../my-competitions/Competitions.css";
import LiveCompetition from "./LiveCompetition";
import CompletedCompetition from "./CompletedCompetition";
import ProfileAccount from "../account/ProfileAccount";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "../buttons/ButtonPrimary";

export default function Competitions() {
  const navigate = useNavigate();

  const [competitionType, setCompetitionType] = useState("");
  const [competitionTypesLabels, setCompetitionTypeLabels] = useState([]);
  const [liveLabels, setLiveLabels] = useState([]);
  const [completedLabels, setCompletedLabels] = useState([]);
  const [joinContestTempClicked, setJoinContestTempClicked] = useState(false);
  var competitionType_storage = JSON.parse(
    localStorage.getItem("competitionType")
  );
  var url = process.env.REACT_APP_API_URL;
  var session_key = localStorage.getItem("session_key");

  const handleCompetitionType = (e) => {
    var type = e.target.getAttribute("datatype");
    setCompetitionType(type);
    localStorage.setItem("competitionType", JSON.stringify(type));
  };

  const getCompetitionsLabels = (page_name) => {
    var competitions_labels_url =
      "" + url + "/api/static_content/get_all_labels";
    var body = {
      page_name: page_name,
    };
    axios
      .post(competitions_labels_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data.content;
          if (page_name == "header") {
            setCompetitionTypeLabels(data);
          }
          if (page_name == "my competitions / live") {
            setLiveLabels(data);
          }
          if (page_name == "my competitions / completed") {
            setCompletedLabels(data);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCompetitionsLabels("header");
    getCompetitionsLabels("my competitions / live");
    getCompetitionsLabels("my competitions / completed");
    setCompetitionType("live");
  }, []);

  // the following method has been lifted from Challenge.js (joinContest) so that we can add a hard-coded panel for Ghana users to join a competition following mobile signup
  const joinContestTemp = (e) => {
    if (joinContestTempClicked) {
      return; // join in progress
    }
    setJoinContestTempClicked(true);

    var contest_id = "1995";
    var contest_uid = "bLxpCJTi2";
    var contest_size = "300";

    var league_id = "73";
    var entry_fee = "0.00";
    var sport_id = "1";

    var join_contest_url = `${url}/api/lobby/join_contest`;
    const body = {
      contest_id: contest_id,
      league_id: league_id,
      entry_fee: entry_fee,
      contest_size: contest_size,
    };

    const headers = { session_key: session_key };

    //showLoader();
    axios
      .post(join_contest_url, body, { headers })
      .then((response) => {
        //hideLoader();
        if (response.status === 200 && response.data.ResponseCode == 200) {
          var data = response.data.Data;
          //alert_notify("success", data.message);
          setTimeout(() => {
            if (sport_id != 13) {
              navigate("/gamecard/" + sport_id + "/" + contest_uid);
            } else {
              navigate("/golf/gamecard/" + sport_id + "/" + contest_uid);
            }
          }, 1500);
        }
      })
      .catch((err) => {
        console.log({ err });
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          if (
            err.response.data.Message.includes(
              "You have already submitted the maximum number of entries allowed"
            )
          ) {
            //alert_notify("success", "returning to competition now");

            setTimeout(() => {
              if (sport_id != 13) {
                navigate("/gamecard/" + sport_id + "/" + contest_uid);
              } else {
                navigate("/golf/gamecard/" + sport_id + "/" + contest_uid);
              }
            }, 2000);
          } else {
            //alert_notify("error", err.response.data.Message);
          }
        }
        //hideLoader();
      });
  };

  return (
    <React.Fragment>
      <Header />
      <ProfileAccount
        headerValue={
          competitionType == "live"
            ? !liveLabels.live_competions_page_label
              ? "LIVE COMPETITIONS"
              : liveLabels.live_competions_page_label.label_name
            : !completedLabels.completed_competitions_page_label
            ? "COMPLETED COMPETITIONS"
            : completedLabels.completed_competitions_page_label.label_name
        }
      />
      <div className="my_competitions_container primary-background">
        <div className="page_content_center">
          {/* show until, and including the date below */}
          {new Date("2023-05-27") >= new Date().setHours(0, 0, 0, 0) && (
            <div
              onClick={() => joinContestTemp(null)}
              className="banner-single-comp"
            >
              <div className="banner-content">
                {/* <h3>Predict this weekend's results and Win Data</h3>
                Win mobile data prizes.{" "}

                <span class="signup-text">Join the competition now</span> */}

                <h3>Free EPL competition. 9GB data to our winners.</h3>
                {/* Win mobile data prizes.{" "} */}

                <span class="signup-text">Click here and play now</span>
              </div>
            </div>
          )}

          <div className="my_comps_btns">
            <ButtonSecondary
              classValue={
                competitionType === "live"
                  ? "btn_secondary btn_live_competitions active"
                  : "btn_secondary btn_live_competitions"
              }
              onClick={handleCompetitionType}
              textValue={
                !competitionTypesLabels.my_competitions_live
                  ? "LIVE"
                  : competitionTypesLabels.my_competitions_live.label_name
              }
              datatype="live"
            />
            <ButtonSecondary
              classValue={
                competitionType === "completed"
                  ? "btn_secondary btn_completed_competitions active"
                  : "btn_secondary btn_completed_competitions"
              }
              onClick={handleCompetitionType}
              textValue={
                !competitionTypesLabels.my_competitions_completed
                  ? "COMPLETED"
                  : competitionTypesLabels.my_competitions_completed.label_name
              }
              datatype="completed"
            />
          </div>
          {competitionType === "live" ? (
            <LiveCompetition labels={liveLabels} />
          ) : (
            <CompletedCompetition labels={completedLabels} />
          )}
          <div>
            <div className="find_comp_container px-5 mt-5 text-center">
              <button
                class="btn_view_all_sports btn_primary header_btn"
                onClick={() => navigate("/home")}
              >
                <span>JOIN A COMPETITION</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}
