import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import SportsBanner from "../home/SportsBanner";
import { Helmet } from "react-helmet";
import Footer from "../footer/Footer";
import UseLoading from "../../hooks/UseLoading";
import UseAlert from "../../hooks/UseAlert";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ButtonPrimary from "../buttons/ButtonPrimary";

const Leagues = () => {
  const url = process.env.REACT_APP_API_URL;
  const [loader, showLoader, hideLoader] = UseLoading();
  const [alert, alert_notify] = UseAlert();
  const [leagues, setLeagues] = useState([]);
  const [leagueId, setLeagueId] = useState(null);
  const { sportId } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  useEffect(() => {
    secondLandingPage();
  }, [sportId]);
  const secondLandingPage = () => {
    var session_key = localStorage.getItem("session_key");
    var secondLandingUrl = `${url}/api/second_landing_page/get_leagues`;
    const headers = {
      session_key: session_key,
    };
    showLoader();
    axios
      .post(secondLandingUrl, { sport_id: sportId }, { headers })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.data.content) {
            console.log(response.data.data.content);
            setLeagues(response.data.data.content);
          }
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MATCH MY SCORE</title>
        <meta
          name="description"
          content="Sports Newest and Most Interactive Prediction Game"
        />
      </Helmet>
      <Header showSports={true} />
      <SportsBanner activeSport={state?.id} />
      <div className="primary-background">
        <div className="page_content_center p-4">
          {state?.sport_name ? (
            <h1 className="coming_soon_text" style={{ fontSize: "25px" }}>
              Select League
            </h1>
          ) : null}
          {leagues.length > 0 ? (
            <div className="p-5">
              {leagues.map((league) => {
                return (
                  <h1
                    className="display_name_text fs-5 py-1"
                    onClick={(e) =>
                      navigate(`/sports_leagues/${sportId}/${league.league_id}`)
                    }
                  >
                    {league.league_name == "SERIE A" &&
                    league.league_country == "BRAZIL"
                      ? `${league.league_country} ${league.league_name}`
                      : league.league_name}
                  </h1>
                );
              })}
            </div>
          ) : (
            <div className="p-4 h-30">
              <h1 className="text-center coming_soon_text fs-1">
                Competitions Coming Soon...
              </h1>
            </div>
          )}
        </div>
      </div>
      <Footer />
      {loader}
      {alert}
    </React.Fragment>
  );
};

export default Leagues;
