import React, { useEffect, useState, useRef } from "react";
import Footer from "../footer/Footer";
import "../index/Index.css";
import IndexSlider from "../index/IndexSlider";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "../buttons/ButtonPrimary";
import ButtonSecondary from "../buttons/ButtonSecondary";
import axios from "axios";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import LandingPage from "../landing-page/LandingPage";
import useComingSoonModal from "../../hooks/useComingSoonModal";
import Summary from "./Summary";

export default function Index() {
  const [openHowToPlay, setHowToPlay] = useState(false);
  const [logo, setLogo] = useState();
  const [showMenu, setShowMenu] = useState(false);
  const [indexHeaderLabels, setIndexHeaderLabels] = useState([]);
  const [indexLabels, setIndexLabels] = useState([]);
  const [loader, showLoader, hideLoader] = useLoading();
  const navigate = useNavigate();
  const [secondLandingPageHorizontalData, setSecondLandingPageHorizontalData] =
    useState([]);
  const [landingPageData, setLandingPageData] = useState([]);
  const [secondLandingPageVerticalData, setSecondLandingPageVerticalData] =
    useState([]);
  const [alert, alert_notify] = useAlert();
  const { openModal, Modal } = useComingSoonModal();

  var secondLandingPageHorizontalDataArray = [];
  var secondLandingPageVerticalDataArray = [];
  const indexMenu = useRef();
  var url = process.env.REACT_APP_API_URL;

  const openHowToPlayContainer = () => {
    setHowToPlay(!openHowToPlay);
  };

  const getIndexLabels = (page) => {
    var header_labels_url = "" + url + "/api/static_content/get_all_labels";
    var body = {
      page_name: page,
    };
    axios
      .post(header_labels_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data.content;
          if (page == "header") {
            console.log(data);
            setIndexHeaderLabels(data);
            setLogo(response.data.Data.logo);
          }
          if (page == "login form") {
            setIndexLabels(data);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const goToThisPage = (e) => {
    var path = e.currentTarget.getAttribute("datapath");
    navigate("/" + path + "");
  };

  useEffect(() => {
    secondLandingPage();
    landingPage();
    getIndexLabels("header");
    getIndexLabels("login form");
    localStorage.setItem("user_status", "unlogged");
    localStorage.removeItem("session_key");
    localStorage.removeItem("userData");
    localStorage.removeItem("showReminder");
    var btn_open_index_menu = document.querySelector(".btn_open_index_menu");
    var btn_open_index_menu_child = document.querySelector(
      ".btn_open_index_menu"
    ).childNodes[0];
    document.addEventListener("click", (e) => {
      if (
        e.target !== btn_open_index_menu &&
        e.target !== btn_open_index_menu_child
      ) {
        if (indexMenu.current && !indexMenu.current.contains(e.target)) {
          setShowMenu(false);
        }
      }
    });
  }, []);

  const secondLandingPage = () => {
    var session_key = localStorage.getItem("session_key");
    var secondLandingUrl = `${url}/api/second_landing_page/get_all_second_page_content`;
    const body = {};
    const headers = {
      session_key: session_key,
    };
    showLoader();
    axios
      .get(secondLandingUrl, body, { headers })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.data.content) {
            var content = response.data.data.content;
            for (var i = 0; i < content.length; i++) {
              var contentRow = content[i];
              if (contentRow.full_width == "0") {
                secondLandingPageVerticalDataArray.push(contentRow);
              } else {
                secondLandingPageHorizontalDataArray.push(contentRow);
              }
            }
            setSecondLandingPageHorizontalData(
              secondLandingPageHorizontalDataArray
            );
            setSecondLandingPageVerticalData(
              secondLandingPageVerticalDataArray
            );
          }
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  const landingPage = () => {
    var session_key = localStorage.getItem("session_key");
    var landingUrl = `${url}/api/landing_page/get_all_landing_page_content`;
    const body = {};
    const headers = {
      session_key: session_key,
    };
    showLoader();
    axios
      .get(landingUrl, body, { headers })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.data.content) {
            var content = response.data.data.content;
            setLandingPageData(content);
          }
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };
  return (
    <React.Fragment>
      <div className="index_container">
        <div className="index_header primary-background">
          <nav className="navbar nav navbar-expand-lg navbar-light">
            <Link to="/" className="logo_icon">
              <span>
                {logo !== undefined ? (
                  <img
                    src={`${process.env.REACT_APP_AWS_ASSETS_URL}tenants/${logo}`}
                    alt="brand-logo"
                    className="top_predictor_logo"
                  />
                ) : null}
              </span>
            </Link>
            <button
              className="navbar-toggler btn_open_index_menu"
              type="button"
              onClick={() => setShowMenu(!showMenu)}
              aria-controls="header-navbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <img src="assets/icons/navbar.svg" alt="navbar-icon" />
            </button>
            <div
              ref={indexMenu}
              style={showMenu ? { display: "block" } : { display: "none" }}
              className={"collapse navbar-collapse"}
              id="header-navbar"
            >
              <div className="navbar-nav">
                <div className="navbar_button_container">
                  <Link
                    to="/how_to_play"
                    className={
                      openHowToPlayContainer
                        ? "btn_how_to_play active"
                        : "btn_how_to_play"
                    }
                    onClick={openHowToPlayContainer}
                  >
                    HOW TO PLAY
                  </Link>
                  <Link to="/login" className="btn_go_to_login">
                    {!indexHeaderLabels.create_private_competition
                      ? "CREATE PRIVATE COMPETITION"
                      : indexHeaderLabels.create_private_competition.label_name}
                  </Link>
                  <Link
                    onClick={(e) => openModal()}
                    className="btn_go_to_login"
                  >
                    {!indexHeaderLabels.coming_soon
                      ? "COMING SOON"
                      : indexHeaderLabels.coming_soon.label_name}
                  </Link>
                </div>
              </div>
            </div>
          </nav>
          <div className="index_center">
            <img
              src="assets/image/player.png"
              alt="top-predictor"
              className="guy_happy_mobile_logo"
            />
            <img
              src="assets/image/player_mobile.png"
              alt="top-predictor"
              className="player_mobile"
            />
            <div className="sports_newest_container page_center">
              <span className="sports">SPORTS</span>
              <span className="newest">NEWEST &amp;</span>
              <span className="interactive">MOST INTERACTIVE</span>
              <span className="prediciton">PREDICTION GAME!</span>
            </div>
          </div>
          <div
            className="index_header_center"
            style={{ backgroundImage: "url(assets/image/green_banner.png)" }}
          >
            <div className="index_center account_btns">
              <ButtonPrimary
                classValue="btn_primary btn_index_signup mb-4"
                textValue={
                  !indexLabels.login_form_sign_up
                    ? "SIGN UP"
                    : indexLabels.login_form_sign_up.label_name
                }
                onClick={goToThisPage}
                datapath="register"
              />
              <ButtonSecondary
                classValue="btn_secondary btn_index_login"
                textValue={
                  !indexLabels.login ? "LOGIN" : indexLabels.login.label_name
                }
                onClick={goToThisPage}
                datapath="login"
              />
            </div>
          </div>
          <div className="index_header_bottom"></div>
        </div>
      </div>
      <div className="primary-background">
        <Summary />
        <LandingPage landingPageData={landingPageData}> </LandingPage>
        <IndexSlider
          loggedStatus={false}
          verticalPage={secondLandingPageVerticalData}
        />
      </div>
      {/* <div
        className="index_video_player"
        style={{ backgroundImage: "url(assets/image/concrete-wall.png)" }}
      >
        <div
          className="index_video_container"
          style={{ maxWidth: "1440px", margin: "0 auto", height: "100%" }}
        >
          <ReactPlayer
            width={"100%"}
            url="https://www.youtube.com/watch?v=JtNPr2ofeb4"
            muted={true}
            playing={false}
            controls={true}
            style={{ display: "flex", margin: "auto", width: "100%" }}
          />
        </div>
      </div> */}
      <Footer />
      {alert}
      {loader}
      <Modal />
    </React.Fragment>
  );
}
