import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Parser from "html-react-parser";
import { useEffect } from "react";
import axios from "axios";
const useComingSoonModal = () => {
  const [show, setShow] = useState(false);
  const [modalContent, setModalContent] = useState("<br/>");
  useEffect(() => {
    var challange_url = `${process.env.REACT_APP_API_URL}/api/static_content/get_all_static_content`;
    const body = { page_meta_key: "coming_soon_popup" };
    axios.post(challange_url, body).then((response) => {
      if (response.status === 200) {
        var data = response.data.Data;
        setModalContent(data.content.page_content);
      }
    });
  }, []);
  const openModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const ModalComponent = () => {
    return (
      <Modal show={show} onHide={closeModal}>
        <Modal.Body className="p-4 primary-background">
          {Parser(modalContent)}
        </Modal.Body>
      </Modal>
    );
  };

  return { openModal, closeModal, Modal: ModalComponent };
};

export default useComingSoonModal;
