import { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Tooltip from "react-bootstrap/Tooltip";
import AccountHeader from "../account-header/AccountHeader";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ButtonSecondary from "../buttons/ButtonSecondary";
import Moment from "react-moment";
import useLoading from "../../hooks/UseLoading";
import axios from "axios";
import ButtonPrimary from "../buttons/ButtonPrimary";
import useAlert from "../../hooks/UseAlert";
import "../my-competitions/Competitions.css";
import ProfileAccount from "../account/ProfileAccount";

export default function JoinCompetition() {
  const [privateCompetitions, setPrivateCompetitions] = useState([]);
  const [pendingCompetitions, setPendingCompetitions] = useState([]);
  const [notification, setNotifiations] = useState([]);
  const [loading, setLoading] = useState(true);
  //   const [checkData, setCheckData] = useState(false);
  const [competitionType, setCompetitionType] = useState("");
  const [loader, showLoader, hideLoader] = useLoading();
  const [alert, alert_notify] = useAlert();
  var loggedUserData = JSON.parse(localStorage.getItem("userData"));
  const [started, setStarted] = useState(false);
  const navigate = useNavigate();
  const { contestId } = useParams();
  var competitions_titles = [
    "Sport",
    "League",
    "Entry Fee",
    "Entries",
    "End Date",
    "Position",
  ];
  var url = process.env.REACT_APP_API_URL;

  var competitionType_storage = JSON.parse(
    localStorage.getItem("competitionType")
  );
  var url = process.env.REACT_APP_API_URL;
  var session_key = localStorage.getItem("session_key");

  const handleCompetitionType = (e) => {
    var type = e.target.getAttribute("datatype");
    setCompetitionType(type);
    localStorage.setItem("competitionType", JSON.stringify(type));
  };

  const joinContest = () => {
    var session_key = localStorage.getItem("session_key");
    var body = { contest_uid: contestId };
    var headers = {
      session_key: session_key,
    };
    axios
      .post(url + "/api/contest_invite/add_private_contest_invite", body, {
        headers,
      })
      .then((result) => {
        if (result.status === 200) {
          getPrivateContests();
          getPendingContests();
          getAllNotfications();
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
          sessionStorage.setItem("back_url", "/join/" + contestId);
        }
        if (error?.response?.data?.Message === "Already joined.") {
          getPrivateContests();
          getPendingContests();
          getAllNotfications();
        }
        if (
          error?.response?.data?.Message ===
          "Match has started. You cannot join now."
        ) {
          setStarted(true);
        }
        console.log(error);
      });
  };

  const getPrivateContests = () => {
    var session_key = localStorage.getItem("session_key");
    var body = {
      current_page: 1,
      items_perpage: 10,
    };
    var headers = {
      session_key: session_key,
    };
    axios
      .post(url + "/api/my_contest/private_contest", body, { headers })
      .then((result) => {
        setPrivateCompetitions(result?.data?.Data?.data?.result);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        }
      });
  };

  const getPendingContests = () => {
    var session_key = localStorage.getItem("session_key");
    var body = {
      current_page: 1,
      items_perpage: 10,
      onlyPrivateContests: true,
    };
    var headers = {
      session_key: session_key,
    };
    axios
      .post(url + "/api/my_contest/pending_private_contest", body, { headers })
      .then((result) => {
        setPendingCompetitions(result?.data?.Data?.data?.result);
        setLoading(false);
      })
      .catch((error) => {});
  };

  const getAllNotfications = () => {
    var session_key = localStorage.getItem("session_key");
    var body = {
      current_page: 1,
      items_perpage: 10,
      onlyPrivateContests: true,
    };
    var headers = {
      session_key: session_key,
    };
    axios
      .post(url + "/api/notifications/get_all_notifications", body, { headers })
      .then((result) => {
        setNotifiations(result?.data?.Data?.data?.result);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        }
        alert_notify("error", error?.response?.data.Message);
      });
  };
  useEffect(() => {
    if (!loggedUserData) {
      navigate("/login");
    }
    contestId ? joinContest() : getPrivateContests();
  }, [contestId]);

  const renderGameCardTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        Game Card
      </Tooltip>
    );
  };

  const renderLeaderBoardTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        Leaderboard
      </Tooltip>
    );
  };

  const goToThisPage = (e) => {
    var path = e.currentTarget.dataset.path;
    var sport_id = e.currentTarget.dataset.sportid;
    var uid = e.currentTarget.dataset.uid;
    if (path === "gamecard") {
      if (sport_id == 13) {
        navigate(`/golf/gamecard/${sport_id}/${uid}`);
      } else {
        navigate("/" + path + "/" + sport_id + "/" + uid + "");
      }
    } else {
      navigate("/" + path + "/" + uid + "");
    }
  };

  const acceptInvitation = (uid, id) => {
    showLoader();
    var session_key = localStorage.getItem("session_key");
    var body = { contest_uid: contestId };
    var headers = {
      session_key: session_key,
    };
    axios
      .post(url + "/api/contest_invite/accept_invitation", body, {
        headers,
      })
      .then((result) => {
        if (id == 13) {
          navigate(`/golf/gamecard/${id}/${uid}`);
        } else {
          navigate(`/gamecard/${id}/${uid}`);
        }
        hideLoader();
      })
      .catch((error) => {
        alert_notify("error", error?.response?.data.Message);
        hideLoader();
        console.log(error);
      });
  };

  return (
    <>
      <Header />
      <ProfileAccount headerValue="MY COMPETITIONS" />
      <div className="my_competitions_container primary-background">
        <div className="page_content_center">
          {started ? (
            <div className="match_started_container">
              <span className="match_started_message">
                Match has started. You cannot join now.
              </span>
              <Link
                className="btn_primary create_new_private"
                to="/private_competition/0/0"
              >
                <span>Create new competition</span>
              </Link>
            </div>
          ) : (
            <div>
              <div className="row competitions_titles">
                <div className="competitions_public_private_col"></div>
                <div className="col">Sport</div>
                <div className="col">League</div>
                <div className="competitions_entry_fee">Entry Fee</div>
                <div className="competitions_entries">Entries</div>
                <div className="col">End Date</div>
                <div className="competitions_positions">Position</div>
                <div className="col-1"></div>
              </div>
              {!loading
                ? pendingCompetitions.map((value, index) => {
                    var sport = value.sport_name;
                    var league = value.league_name;
                    var league_country = value.league_country;
                    var entry_fee = value.entry_fee;
                    var position = value.result;
                    var end_at = value.end_at;
                    var contest_id = value.contest_id;
                    var total_user_joined = value.total_user_joined;
                    var size = value.size;
                    var sport_id = value.sport_id;
                    var uid = value.contest_uid;
                    var owner_id = value.owner_id;

                    if (entry_fee === "0.00" || entry_fee === 0) {
                      entry_fee = "Free to Play";
                    }
                    if (league_country !== undefined && league_country !== "") {
                      league_country = league_country.toUpperCase();
                    }
                    if (league !== undefined && league !== "") {
                      league = league.toUpperCase();
                    }

                    return (
                      <div
                        className="row competition transaction m-0"
                        data-contestid={contest_id}
                        row-index={index}
                        key={index}
                      >
                        <div className="competitions_public_private_col">
                          <span className="public_icon">
                            <img
                              src="/assets/icons/private_primary.svg"
                              alt="public-icon"
                            />
                          </span>
                        </div>
                        <div className="col">
                          <span className="transaction_title">
                            {competitions_titles[0]}
                          </span>
                          <span className="transaction_value">{sport}</span>
                        </div>
                        <div className="col">
                          <span className="transaction_title">
                            {competitions_titles[1]}
                          </span>
                          <span
                            className="transaction_value"
                            style={{ textTransform: "uppercase" }}
                          >
                            {league_country} {league}
                          </span>
                        </div>
                        <div className="competitions_entry_fee">
                          <span className="transaction_title">
                            {competitions_titles[2]}
                          </span>
                          <span className="transaction_value">{entry_fee}</span>
                        </div>
                        <div className="competitions_entries">
                          <span className="transaction_title">
                            {competitions_titles[3]}
                          </span>
                          <span className="transaction_value">
                            {total_user_joined}/{size}
                          </span>
                        </div>
                        <div className="col">
                          <span className="transaction_title">
                            {competitions_titles[4]}
                          </span>
                          <span className="transaction_value">
                            <Moment format="DD MMM YYYY">{end_at}</Moment>
                          </span>
                        </div>
                        <div className="competitions_positions">
                          <span className="transaction_title">
                            {competitions_titles[5]}
                          </span>
                          <span className="transaction_value">
                            {position || "-"}
                          </span>
                        </div>
                        <div className="col-12 col-lg-1 col-xl-1 d-flex justify-content-center">
                          <ButtonPrimary
                            textValue="ACCEPT"
                            classValue="btn_primary accept"
                            disabled={false}
                            onClick={() => acceptInvitation(uid, sport_id)}
                          />
                        </div>
                      </div>
                    );
                  })
                : ""}
              {!loading
                ? privateCompetitions.map((value, index) => {
                    var sport = value.sport_name;
                    var league = value.league_name;
                    var league_country = value.league_country;
                    var entry_fee = value.entry_fee;
                    var position = value.result;
                    var end_at = value.end_at;
                    var contest_id = value.contest_id;
                    var total_user_joined = value.total_user_joined;
                    var size = value.size;
                    var sport_id = value.sport_id;
                    var uid = value.contest_uid;
                    var owner_id = value.owner_id;

                    if (entry_fee === "0.00" || entry_fee === 0) {
                      entry_fee = "Free to Play";
                    }
                    if (league_country !== undefined && league_country !== "") {
                      league_country = league_country.toLowerCase();
                    }
                    if (league !== undefined && league !== "") {
                      league = league.toLowerCase();
                    }

                    return (
                      <div
                        className="row competition transaction m-0"
                        data-contestid={contest_id}
                        row-index={index}
                        key={index}
                      >
                        <div className="competitions_public_private_col">
                          <span className="public_icon">
                            <img
                              src="/assets/icons/private.svg"
                              alt="public-icon"
                            />
                          </span>
                        </div>
                        <div className="col">
                          <span className="transaction_title">
                            {competitions_titles[0]}
                          </span>
                          <span className="transaction_value">{sport}</span>
                        </div>
                        <div className="col">
                          <span className="transaction_title">
                            {competitions_titles[1]}
                          </span>
                          <span
                            className="transaction_value"
                            style={{ textTransform: "uppercase" }}
                          >
                            {league_country} {league}
                          </span>
                        </div>
                        <div className="competitions_entry_fee">
                          <span className="transaction_title">
                            {competitions_titles[2]}
                          </span>
                          <span className="transaction_value">{entry_fee}</span>
                        </div>
                        <div className="competitions_entries">
                          <span className="transaction_title">
                            {competitions_titles[3]}
                          </span>
                          <span className="transaction_value">
                            {total_user_joined}/{size}
                          </span>
                        </div>
                        <div className="col">
                          <span className="transaction_title">
                            {competitions_titles[4]}
                          </span>
                          <span className="transaction_value">
                            <Moment format="DD MMM YYYY">{end_at}</Moment>
                          </span>
                        </div>
                        <div className="competitions_positions">
                          <span className="transaction_title">
                            {competitions_titles[5]}
                          </span>
                          <span className="transaction_value">
                            {position || "-"}
                          </span>
                        </div>
                        <div className="col-12 col-lg-1 col-xl-1 d-flex justify-content-center">
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderGameCardTooltip}
                          >
                            <span
                              className="gamecard_icon"
                              data-path="gamecard"
                              data-sportid={sport_id}
                              data-uid={uid}
                              onClick={goToThisPage}
                            >
                              <img
                                src="/assets/icons/gamecard_primary.svg"
                                alt="game-card-icon"
                              />
                            </span>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderLeaderBoardTooltip}
                          >
                            <span
                              className="leaderboard_icon"
                              data-path="leaderboard"
                              data-sportid={sport_id}
                              data-uid={uid}
                              onClick={goToThisPage}
                            >
                              <img
                                src="/assets/icons/leaderboard_primary.svg"
                                alt="leaderboard-icon"
                              />
                            </span>
                          </OverlayTrigger>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          )}
        </div>
      </div>
      <Footer />
      {loader}
    </>
  );
}
