import React, { useEffect, useState } from "react";
import ButtonLight from "../buttons/buttonLight";
import ButtonPrimary from "../buttons/ButtonPrimary";
import "../profile/Profile.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import parse from "html-react-parser";

export default function GeneralInformation(props) {
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [sumsubInfo, setSumsubInfo] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [KYCStatus, setKYCStatus] = useState(0);
  const [userData, setUserData] = useState({
    phone_no: "",
    default_currency: "EUR",
  });
  const [uploadType, setUploadType] = useState("");
  var url = process.env.REACT_APP_API_URL;
  var session_key = localStorage.getItem("session_key");
  const navigate = useNavigate();
  const [loader, showLoader, hideLoader] = useLoading();
  const [alert, alert_notify] = useAlert();
  var type = "";
  var user_kyc_status = "";
  var loggedUserData = JSON.parse(localStorage.getItem("userData"));
  var sls_api_url = process.env.REACT_APP_SLS_API_URL;
  const accept_file_types = [
    "image/png",
    "image/jpeg",
    "image/jgp",
    "application/pdf",
  ];
  const sumsub_labels = {
    FORGERY: "Forgery attempt has been made",
    DOCUMENT_TEMPLATE:
      "Documents supplied are templates, downloaded from internet",
    LOW_QUALITY:
      "Documents have low-quality that does not allow definitive conclusions to be made",
    SPAM: "An applicant has been created by mistake or is just a spam user (irrelevant images were supplied)",
    NOT_DOCUMENT:
      "Documents supplied are not relevant for the verification procedure",
    SELFIE_MISMATCH:
      "A user photo (profile image) does not match a photo on the provided documents",
    ID_INVALID:
      "A document that identifies a person (like a passport or an ID card) is not valid",
    FOREIGNER:
      "When a client does not accept applicants from a different country or e.g. without a residence permit",
    DUPLICATE:
      "This applicant was already created for this client, and duplicates are not allowed by the regulations",
    BAD_AVATAR: "When avatar does not meet the client's requirements",
    WRONG_USER_REGION:
      "When applicants from certain regions/countries are not allowed to be registered",
    INCOMPLETE_DOCUMENT:
      "Some information is missing from the document, or it's partially visible",
    BLACKLIST: "User is blacklisted",
    UNSATISFACTORY_PHOTOS:
      "There were problems with the photos, like poor quality or masked information",
    DOCUMENT_PAGE_MISSING:
      "Some pages of a document are missing (if applicable)",
    DOCUMENT_DAMAGED: "Document is damaged",
    REGULATIONS_VIOLATIONS: "Regulations violations",
    INCONSISTENT_PROFILE:
      "Data or documents of different persons were uploaded to one applicant",
    PROBLEMATIC_APPLICANT_DATA:
      "Applicant data does not match the data in the documents",
    ADDITIONAL_DOCUMENT_REQUIRED:
      "Additional documents required to pass the check",
    AGE_REQUIREMENT_MISMATCH:
      "Age requirement is not met (e.g. cannot rent a car to a person below 25yo)",
    EXPERIENCE_REQUIREMENT_MISMATCH:
      "Not enough experience (e.g. driving experience is not enough)",
    CRIMINAL: "The user is involved in illegal actions",
    WRONG_ADDRESS:
      "The address from the documents doesn't match the address that the user entered",
    GRAPHIC_EDITOR: "The document has been edited by a graphical editor",
    DOCUMENT_DEPRIVED: "The user has been deprived of the document",
    COMPROMISED_PERSONS:
      "The user does not correspond to Compromised Person Politics",
    PEP: "The user belongs to the PEP category",
    ADVERSE_MEDIA: "The user was found in the adverse media",
    FRAUDULENT_PATTERNS: "Fraudulent behavior was detected",
    SANCTIONS: "The user was found on sanction lists",
    NOT_ALL_CHECKS_COMPLETED: "All checks were not completed",
    FRONT_SIDE_MISSING: "Front side of the document is missing",
    BACK_SIDE_MISSING: "Back side of the document is missing",
    SCREENSHOTS: "The user uploaded screenshots",
    BLACK_AND_WHITE: "The user uploaded black and white photos of documents",
    INCOMPATIBLE_LANGUAGE: "The user should upload translation of his document",
    EXPIRATION_DATE: "The user uploaded expired document",
    UNFILLED_ID: "The user uploaded the document without signatures and stamps",
    BAD_SELFIE: "The user uploaded a bad selfie",
    BAD_VIDEO_SELFIE: "The user uploaded a bad video selfie",
    BAD_FACE_MATCHING: "Face check between document and selfie failed",
    BAD_PROOF_OF_IDENTITY: "The user uploaded a bad ID document",
    BAD_PROOF_OF_ADDRESS: "The user uploaded a bad proof of address",
    BAD_PROOF_OF_PAYMENT: "The user uploaded a bad proof of payment",
    SELFIE_WITH_PAPER:
      "The user should upload a special selfie (e.g. selfie with paper and date on it)",
    OTHER: "Some unclassified reason",
    REQUESTED_DATA_MISMATCH:
      "Provided info doesn't match with recognized from document data",
    OK: "Custom reject label",
    COMPANY_NOT_DEFINED_STRUCTURE:
      "Could not establish the entity's control structure",
    COMPANY_NOT_DEFINED_BENEFICIARIES:
      "Could not identify and duly verify the entity's beneficial owners",
    COMPANY_NOT_VALIDATED_BENEFICIARIES: "Beneficiaries are not validated",
    COMPANY_NOT_DEFINED_REPRESENTATIVES: "Representatives are not defined",
    COMPANY_NOT_VALIDATED_REPRESENTATIVES: "Representatives are not validated",
  };
  var event = new Date();
  var maxYear = event.setFullYear(event.getFullYear() - 18);

  const getCountries = () => {
    var countries_url = `${url}/api/auth/get_country`;
    const body = {};
    const headers = {
      session_key: session_key,
    };
    showLoader();
    axios
      .post(countries_url, body, { headers })
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data.country;
          setCountries(data);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  const getCities = () => {
    var country_id = userData.master_country_id;
    if (country_id !== "0" && country_id !== undefined) {
      var master_country_id = country_id;
    } else {
      if (loggedUserData !== null) {
        var master_country_id = loggedUserData.master_country_id;
      }
    }
    setUserData({ ...userData, master_country_id: master_country_id });
    var cities_url = `${url}/api/my_profile/state_by_country`;
    const body = {
      master_country_id: master_country_id,
    };
    const headers = {
      session_key: session_key,
    };
    showLoader();
    axios
      .post(cities_url, body, { headers })
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data.state;
          setCities(data);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  const updateProfile = (e) => {
    e.preventDefault();
    var update_profile_url = "" + url + "/api/my_profile/update_profile";

    var phone_no = document.getElementById("input_profile_phone_no").value;

    if (userData.master_country_id === "38") {
      if (Number(userData.phone_no.length) == 12) {
        var cvn = userData.phone_no.charAt(0);
        if (cvn != "+") {
          alert_notify("error", "Invalid phone number!");
          return false;
        } else if (userData.phone_no.charAt(1) != 1) {
          alert_notify("error", "Invalid phone number!");
          console.log(";ot");
          return false;
        }
      } else if (Number(userData.phone_no.length) >= 11) {
        var cvn = userData.phone_no.charAt(0);
        if (cvn != 1) {
          alert_notify("error", "Invalid phone number!");
          return false;
        }
      }

      if (userData.phone_no.length > 13) {
        alert_notify(
          "error",
          "The Phone number field cannot exceed 11 characters in length."
        );
        return false;
      } else if (userData.phone_no.length <= 9) {
        alert_notify(
          "error",
          "The Phone number field cannot exceed 10 characters in length."
        );
        return false;
      }
    }

    var body = {
      opt_in_email: "1",
      phone_no: phone_no,
    };
    const headers = {
      session_key: session_key,
    };
    showLoader();
    axios
      .post(update_profile_url, body, { headers })
      .then((response) => {
        if (response.status === 200) {
          alert_notify("success", response.data.Message);
          setUserData({
            ...userData,
            phone_no: body.phone_no,
          });
          var actualUserDataStorage = JSON.parse(
            localStorage.getItem("userData")
          );
          actualUserDataStorage.phone_no = userData.phone_no;
          // actualUserDataStorage.kyc_status = userData.kyc_status;
          localStorage.setItem(
            "userData",
            JSON.stringify(actualUserDataStorage)
          );
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          for (var key in err.response.data.Message) {
            alert_notify("error", err.response.data.Message[key]);
            break;
          }
        }
        hideLoader();
      });
  };

  const handleInputChange = (e) => {
    type = e.target.name;
    setUserData({ ...userData, [type]: e.target.value });
  };

  const handleStateChange = (e) => {
    var state_id = document.getElementById("input_profile_state").value;
    setUserData({ ...userData, master_state_id: state_id });
  };

  const uploadDocument = (data) => {
    var upload_url = "" + url + "/api/upload_image/Kyc_document_upload";

    var body = data;
    const headers = {
      session_key: session_key,
    };
    showLoader();
    axios
      .post(upload_url, body, { headers })
      .then((response) => {
        alert_notify("success", "Document uploaded successfully");
        var actualUserDataStorage = JSON.parse(
          localStorage.getItem("userData")
        );
        if (uploadType == "passport_image") {
          setUserData({ ...userData, passport_image: true });
          actualUserDataStorage.passport_image = true;
        }

        if (uploadType == "utility_bills_image") {
          setUserData({ ...userData, utility_bills_image: true });
          actualUserDataStorage.utility_bills_image = true;
        }

        if (uploadType == "bank_statement_image") {
          setUserData({ ...userData, bank_statement_image: true });
          actualUserDataStorage.bank_statement_image = true;
        }
        localStorage.setItem("userData", JSON.stringify(actualUserDataStorage));
        hideLoader();
      })
      .catch((err) => {
        hideLoader();
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
      });
  };

  const handleDocumentUpload = (e) => {
    var type = e.currentTarget.getAttribute("type");
    handleFileClick(type);
  };

  const handleFileClick = (type) => {
    if (type == "passport") {
      document.getElementById("profile_upload_passport").click();
      setUploadType("passport_image");
    }
    if (type == "utility-bills") {
      document.getElementById("profile_upload_utility_bills").click();
      setUploadType("utility_bills_image");
    }
    if (type == "bank-statement") {
      document.getElementById("profile_upload_bank_statement").click();
      setUploadType("bank_statement_image");
    }
  };

  const uploadFile = ({ target: { files } }) => {
    if (files[0] !== undefined) {
      var fileType = files[0].type;
      var fileSize = files[0].size;
      var fileToUpload;

      if (!accept_file_types.includes(fileType)) {
        alert_notify(
          "error",
          "Unsupported file format. Only JPG, PNG and PDF files are allowed"
        );
        return false;
      } else if ((fileSize / 1024 / 1024).toFixed(4) > 4) {
        alert_notify("error", "The file size should be less than 4 MB");
        return false;
      } else {
        var formData = new FormData();
        console.log(files[0]);
        formData.append("columnData", files[0]);
        formData.append("columnName", uploadType);
        uploadDocument(formData);
      }
    }
  };

  const handleDOB = (date) => {
    if (date !== null) {
      var dob = moment(date).format("MMM DD, YYYY");
      setUserData({ ...userData, ["dob"]: dob });
    }
  };

  const getRejectedInfo = () => {
    var sumsub_messages = `${url}/api/my_profile/sumsub_info`;
    const body = {};
    const headers = {
      session_key: session_key,
    };
    showLoader();
    axios
      .post(sumsub_messages, body, { headers })
      .then((response) => {
        if (response.status === 200) {
          var data = response.data?.Data;
          var info = "";
          if (data?.id) {
            var message = JSON.parse(data.message);
            info += `<div style="min-height: 100px;"> Reasons why your KYC was rejected are:
              <ol>
              `;
            for (var i = 0; i < message.length; i++) {
              info +=
                `<li style="color: red;"> ` +
                sumsub_labels[message[i]] +
                `</l1>`;
            }
            console.log(message);
          } else {
            info = "No info";
          }
          console.log(info);
          setSumsubInfo(info);
          setShowModal(true);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };
  useEffect(() => {
    getCountries();
    getCities();

    if (loggedUserData != null) {
      setUserData({
        ...userData,
        address1:
          loggedUserData.address1 === null ? "" : loggedUserData.address1,
        address2:
          loggedUserData.address2 === null ? "" : loggedUserData.address2,
        city: loggedUserData.city === "null" ? "" : loggedUserData.city,
        dob: loggedUserData.dob === null ? "" : loggedUserData.dob,
        email: loggedUserData.email === null ? "" : loggedUserData.email,
        first_name:
          loggedUserData.first_name === null ? "" : loggedUserData.first_name,
        last_name:
          loggedUserData.last_name === null ? "" : loggedUserData.last_name,
        master_country_id:
          loggedUserData.master_country_id === null
            ? ""
            : loggedUserData.master_country_id,
        master_state_id:
          loggedUserData.master_state_id === null
            ? ""
            : loggedUserData.master_state_id,
        opt_in_email:
          loggedUserData.opt_in_email === null
            ? ""
            : loggedUserData.opt_in_email,
        phone_no:
          loggedUserData.phone_no === null ? "" : loggedUserData.phone_no,
        user_name:
          loggedUserData.user_name === null ? "" : loggedUserData.user_name,
        zip_code:
          loggedUserData.zip_code === null ? "" : loggedUserData.zip_code,
        kyc_status:
          loggedUserData.kyc_status === null ? "" : loggedUserData.kyc_status,
        allow_kyc:
          loggedUserData.allow_kyc === null ? true : loggedUserData.allow_kyc,
        bank_statement_image:
          loggedUserData.bank_statement_image === null
            ? ""
            : loggedUserData.bank_statement_image,
        passport_image:
          loggedUserData.passport_image === null
            ? ""
            : loggedUserData.passport_image,
        utility_bills_image:
          loggedUserData.utility_bills_image === null
            ? ""
            : loggedUserData.utility_bills_image,
        default_currency:
          loggedUserData.default_currency === null
            ? "EUR"
            : loggedUserData.default_currency,
      });
    }
  }, []);

  if (userData.kyc_status === "0") {
    user_kyc_status = "PENDING KYC";
  }

  if (userData.kyc_status === "1") {
    user_kyc_status = "IN PROGRESS KYC";
  }

  if (userData.kyc_status === "2") {
    user_kyc_status = "APPROVED KYC";
  }

  if (userData.kyc_status === "3") {
    user_kyc_status = "REJECTED KYC";
  }

  if (userData.kyc_status === "4") {
    user_kyc_status = "REFER KYC";
  }
  if (userData.dob !== "") {
    var dobValue = userData.dob;
  } else {
    var dobValue = dobValue;
  }

  var actualUserDataStorage = JSON.parse(localStorage.getItem("userData"));
  if (
    actualUserDataStorage.dob !== "" &&
    actualUserDataStorage.dob !== "0000-00-00"
  ) {
    var disabledDOB = true;
  } else {
    var disabledDOB = false;
  }

  if (userData.kyc_status === "2") {
    var disabledUsername = true;
    var disabledFirstName = true;
    var disabledLastName = true;
    var disabledAddress = true;
    var disabledAddress2 = true;
    var disabledCountry = true;
    var disabledCity = true;
    var disabledPostCode = true;
    var disabledState = true;
    var disabledDateBirth = true;
  }
  var disabledUsername = true;
  return (
    <React.Fragment>
      <div className="profile_container primary-background">
        <div className="page_content_center">
          <div className="account_status">
            <div className="d-flex align-items-center account_status_container">
              <div
                className="account"
                style={{ textTransform: "uppercase", marginRight: "5px" }}
              >
                {!props.labels.update_form_account_status
                  ? "ACCOUNT STATUS"
                  : props.labels.update_form_account_status.label_name}
                : {user_kyc_status}
              </div>
              {userData.kyc_status == 3 ? (
                <button
                  type="button"
                  className="btn d-flex justify-content-center"
                  style={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    color: "#DC143C",
                  }}
                  onClick={getRejectedInfo}
                >
                  <i className="fas fa-info-circle fa-lg"></i>
                </button>
              ) : (
                ""
              )}
            </div>
            <div>
              {(userData.kyc_status == 0 || userData.kyc_status == 3) &&
              userData.allow_kyc ? (
                <ButtonLight
                  textValue={
                    !props.labels.approve_your_kyc
                      ? "Approve your kyc"
                      : props.labels.approve_your_kyc.label_name
                  }
                  classValue="btn_light btn_cancel_profile"
                  onClick={() => {
                    const win = window.open("/sumsub_verification", "_blank");
                    win.focus();
                  }}
                  style={{ textTransform: "uppercase" }}
                />
              ) : (
                ""
              )}
              <ButtonLight
                textValue={
                  !props.labels.update_form_cp_page_title
                    ? "CHANGE PASSWORD"
                    : props.labels.update_form_cp_page_title.label_name
                }
                classValue="btn_light btn_change_password"
                onClick={props.handleClick}
                style={{ textTransform: "uppercase" }}
              />
            </div>
          </div>
          <div className="general_information_container">
            <label style={{ textTransform: "uppercase" }}>
              {!props.labels.update_form_gi_page_title
                ? "GENERAL INFORMATION"
                : props.labels.update_form_gi_page_title.label_name}
            </label>
            <div className="general_information primary-background">
              <form id="profile_general_info_form" onSubmit={updateProfile}>
                <div className="row m-0">
                  <div className="col-12 col-lg-8 col-xl-8 general_info_data">
                    <div className="col-12 d-flex align-items-center mb-3">
                      <div className="general_info_label text-white">
                        Full name:
                      </div>
                      <div
                        className="text-white"
                        style={{ textTransform: "capitalize" }}
                      >
                        {userData.first_name} {userData.last_name}
                      </div>
                    </div>
                    <div className="col-12 d-flex align-items-center mb-3">
                      <div className="general_info_label text-white">
                        User Name:
                      </div>
                      <div className="text-white">{userData.user_name}</div>
                    </div>
                    <div className="col-12 d-flex align-items-center mb-3">
                      <div className="general_info_label text-white">
                        {!props.labels.update_form_gi_date_of_birth
                          ? "Date of Birth"
                          : props.labels.update_form_gi_date_of_birth
                              .label_name}
                        :
                      </div>
                      <div className="text-white">{userData.dob}</div>
                    </div>
                    <div className="col-12 d-flex align-items-center mb-3">
                      <div className="general_info_label text-white">
                        Email:
                      </div>
                      <div className="text-white">{userData.email}</div>
                    </div>
                    <div className="col-12 d-flex align-items-center mb-3">
                      <div className="general_info_label text-white">
                        Mobile:
                      </div>
                      <div>
                        <input
                          type="tel"
                          className="profile_input"
                          id="input_profile_phone_no"
                          placeholder="(1)(000)(000-0000)"
                          name="phone_no"
                          value={userData.phone_no}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-8 col-lg-4 col-xl-4 general_info_address">
                    <div className="general_info_label mb-3 text-white">
                      Address:
                    </div>
                    <div className="mb-3 text-white"> {userData.address1} </div>
                    <div className="mb-3 text-white">
                      {userData.city}{" "}
                      {cities.map((row, index) => {
                        var selected =
                          row.master_state_id === userData.master_state_id
                            ? row.name
                            : "";
                        return selected;
                      })}{" "}
                      {userData.zip_code} {""}
                    </div>
                    <div className="text-white">
                      {countries.map((row, index) => {
                        var selected =
                          row.master_country_id === userData.master_country_id
                            ? row.country_name
                            : "";
                        return selected;
                      })}
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="row general_info_save_container">
              <ButtonPrimary
                textValue={
                  !props.labels.update_form_gi_submit_btn
                    ? "SAVE PROFILE"
                    : props.labels.update_form_gi_submit_btn.label_name
                }
                classValue="btn_primary btn_save_profile"
                form="profile_general_info_form"
                type="submit"
                style={{ textTransform: "uppercase" }}
              />
            </div>
            <Modal
              show={showModal}
              onHide={() => {
                setShowModal(false);
              }}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Info</Modal.Title>
              </Modal.Header>
              <Modal.Body>{parse(sumsubInfo)}</Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
      {loader}
      {alert}
    </React.Fragment>
  );
}
