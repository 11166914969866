import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../home/Home.css";
import axios from "axios";
import useLoading from "../../hooks/UseLoading";
import { Helmet } from "react-helmet";

const AllSports = React.forwardRef((props, ref) => {
  var url = process.env.REACT_APP_API_URL;
  const [activeSubType, setActiveSubType] = useState("");
  const [sportData, setSportData] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeLeague, setActiveLeague] = useState("");
  const navigate = useNavigate();
  const [loader, showLoader, hideLoader] = useLoading();

  const handleSportLeague = (e) => {
    var sport_id = e.target.getAttribute("data-id");
    if (sport_id === activeLeague) {
      setActiveLeague("");
    } else {
      setActiveLeague(sport_id);
    }
  };

  useEffect(() => {
    document.addEventListener(
      "click",
      (e) => {
        if (e.target.classList.contains("type")) {
          var type = e.target.getAttribute("type");
          setActiveSubType(type);
          if (type === activeSubType) {
            setActiveSubType("");
          }
        } else {
          setActiveSubType("");
        }
      },
      false
    );

    var session_key = localStorage.getItem("session_key");
    var view_all_sport_url = `${url}/api/common/sidebar`;

    const body = {};
    const headers = {
      session_key: session_key,
    };
    showLoader();
    axios
      .get(view_all_sport_url, body, { headers })
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          data.sort((a, b) => (a.sport_name > b.sport_name ? 1 : -1));
          setSportData(data);
          var leagues_array = [];
          for (var i = 0; i < data.length; i++) {
            var row = data[i];
            var states = row.leagues;
            if (states != null) {
              for (const property in states) {
                for (var k = 0; k < states[property].length; k++) {
                  leagues_array.push(states[property][k]);
                  setLeagues(leagues_array);
                }
              }
            }
          }
          setLoading(false);
        }
        hideLoader();
      })
      .catch((err) => {
        hideLoader();
        console.log(err);
        navigate("/login");
        localStorage.removeItem("session_key");
      });
  }, [url, navigate]);

  return (
    <React.Fragment>
      <div
        ref={ref}
        className="all_sports_container"
        style={props.displayValue ? { display: "block" } : { display: "none" }}
      >
        <div className="page_content_center">
          <div className="sports_container">
            <div className="sport">
              {!loading
                ? 
                leagues.length > 0 ?
                  sportData.map((sportData, index) => {
                    if(sportData.leagues !== null){
                      var sport_first_letter = sportData.sport_name.charAt(0);
                      return (
                        <div
                          className="type"
                          id={sportData.id}
                          title-index={index}
                          key={index}
                        >
                          <span className="title">{sport_first_letter}</span>
                          <span
                            className="sport_type"
                            type={sportData.sport_name}
                            data-id={sportData.sport_id}
                            onClick={handleSportLeague}
                          >
                            {sportData.sport_name}
                          </span>
                          {leagues.map((league, index) => {
                            if (league.sport_id === sportData.sport_id) {
                              return (
                                <div
                                  className="sport_league"
                                  key={index}
                                  style={
                                    activeLeague === league.sport_id
                                      ? { display: "block" }
                                      : { display: "none" }
                                  }
                                >
                                  <span
                                    className="subtype allsports_subtype"
                                    data-leagueid={league.league_id}
                                    data-sportid={league.sport_id}
                                  >
                                    {league.league_country} - {league.league_name}
                                  </span>
                                </div>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </div>
                      );
                    }
                  })  : <span className="no_active_competitions">We don't have active competitions</span>
                : ""}
            </div>
          </div>
        </div>
        <div className="allsports_bottom"></div>
      </div>
      {loader}
    </React.Fragment>
  );
});

export default AllSports;
