import React, { Component, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import { useThemeProvider } from "../../providers/theme/ThemeContext";

export default function Footer() {
  const navigate = useNavigate();
  const { colors } = useThemeProvider();
  var url = process.env.REACT_APP_API_URL;
  const [footer_content_text, setFooterContentText] = useState("");
  const [loader, showLoader, hideLoader] = useLoading();
  const [alert, alert_notify] = useAlert();
  const [saferUrl, setSaferUrl] = useState("/safer_gambling?type=description");
  const [footerLabels, setFooterLabels] = useState([]);

  const getFooterLabels = () => {
    var footer_labels_url = "" + url + "/api/static_content/get_all_labels";
    var body = {
      page_name: "footer",
    };
    showLoader();
    axios
      .post(footer_labels_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data.content;
          setFooterLabels(data);
        }
        hideLoader();
      })
      .catch((err) => {
        console.log(err);
        hideLoader();
      });
  };

  useEffect(() => {
    getFooterLabels();
    var loggedUserData = JSON.parse(localStorage.getItem("userData"));
    if (!loggedUserData) {
      setSaferUrl("/safer_gambling?type=description");
    } else {
      setSaferUrl("/safer_gambling?type=gambling");
    }
    var footer_url = "" + url + "/api/static_content/get_all_labels";
    const headers = {};
    const body = { page_name: "footer" };
    showLoader();
    axios
      .post(footer_url, body, { headers })
      .then((response) => {
        if (response.status === 200) {
          var data = response?.data?.Data;
          setFooterContentText(data?.content?.copyright?.label_name);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  }, []);

  return (
    <React.Fragment>
      <div className="footer">
        <footer
          className="page-footer"
          style={{ maxWidth: "", margin: "0 auto" }}
        >
          <div className="text-md-left">
            <div className="row footer_container m-0">
              <div className="col-md-8 footer_content p-0">
                <ul>
                  <NavLink
                    to="/our_mission"
                    className="footer_nav_link"
                    style={({ isActive }) =>
                      isActive ? { color: "#FFFFFF" } : undefined
                    }
                  >
                    <li className="footer_content_li">
                      {!footerLabels.our_mission
                        ? "Our Mission"
                        : footerLabels.our_mission.label_name}
                    </li>
                  </NavLink>
                  <NavLink
                    to={saferUrl}
                    className="footer_nav_link"
                    style={({ isActive }) =>
                      isActive ? { color: "#FFFFFF" } : undefined
                    }
                  >
                    <li>
                      {!footerLabels.footer_safer_gambling
                        ? "Safer Gambling"
                        : footerLabels.footer_safer_gambling.label_name}
                    </li>
                  </NavLink>
                  <NavLink
                    to="/competition"
                    className="footer_nav_link"
                    style={({ isActive }) =>
                      isActive ? { color: "#FFFFFF" } : undefined
                    }
                  >
                    <li>
                      {!footerLabels.competition
                        ? "Competition"
                        : footerLabels.competition.label_name}
                    </li>
                  </NavLink>
                  <NavLink
                    to="/challange"
                    className="footer_nav_link"
                    style={({ isActive }) =>
                      isActive ? { color: "#FFFFFF" } : undefined
                    }
                  >
                    <li>
                      {!footerLabels.challenge
                        ? "Challenge"
                        : footerLabels.challenge.label_name}
                    </li>
                  </NavLink>
                  <NavLink
                    to="/rules"
                    className="footer_nav_link"
                    style={({ isActive }) =>
                      isActive ? { color: "#FFFFFF" } : undefined
                    }
                  >
                    <li>
                      {!footerLabels.rules
                        ? "Rules"
                        : footerLabels.rules.label_name}
                    </li>
                  </NavLink>
                  <NavLink
                    to="/faqs"
                    className="footer_nav_link"
                    style={({ isActive }) =>
                      isActive ? { color: "#FFFFFF" } : undefined
                    }
                  >
                    <li>
                      {!footerLabels.faq ? "FAQs" : footerLabels.faq.label_name}
                    </li>
                  </NavLink>
                  <NavLink
                    to="/terms_conditions"
                    className="footer_nav_link"
                    style={({ isActive }) =>
                      isActive ? { color: "#FFFFFF" } : undefined
                    }
                  >
                    <li>
                      {!footerLabels.terms_and_conditions
                        ? "Terms & Conditions"
                        : footerLabels.terms_and_conditions.label_name}
                    </li>
                  </NavLink>
                  <NavLink
                    to="/privacy_policy"
                    className="footer_nav_link"
                    style={({ isActive }) =>
                      isActive ? { color: "#FFFFFF" } : undefined
                    }
                  >
                    <li>
                      {!footerLabels.privacy_policy
                        ? "Privacy Policy"
                        : footerLabels.privacy_policy.label_name}
                    </li>
                  </NavLink>
                  <NavLink
                    to="/contact_us"
                    className="footer_nav_link"
                    style={({ isActive }) =>
                      isActive ? { color: "#FFFFFF" } : undefined
                    }
                  >
                    <li>
                      {!footerLabels.feedback
                        ? "Feedback"
                        : footerLabels.feedback.label_name}
                    </li>
                  </NavLink>
                </ul>
                {/* <p className="footer_content_text">{footer_content_text}</p> */}
                <div className="payments_logos">
                  <img
                    src="/assets/icons/visa.svg"
                    alt="Visa"
                    className="visa_logo"
                  />
                  <img
                    src="/assets/icons/etr.svg"
                    alt="Gigadat"
                    className="gigadat_logo"
                  />
                </div>
              </div>
              <div className="col-md-4 mb-md-0 mb-3 footer_logos_container p-0">
                <div className="age_allowed_container"> 18+ </div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.begambleaware.org/"
                >
                  <img
                    src="/assets/icons/gamble-aware.svg"
                    alt="Gamble Aware Icon"
                    className="footer_icon"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-copyright copyright text-center">
            <span className="d-block">
              {!footerLabels.copyright
                ? " &copy; 2015-2021 TPG Technology Ltd. ALL RIGHTS RESERVED."
                : footerLabels.copyright.label_name}
            </span>
          </div>
        </footer>
      </div>
      {loader}
      {alert}
    </React.Fragment>
  );
}
