import React, { useState, useEffect } from "react";
import AccountHeader from "../account-header/AccountHeader";
import ButtonPrimary from "../buttons/ButtonPrimary";
import "./ForgotPassword.css";
import Footer from "../footer/Footer";
import LoginHeader from "../login/LoginHeader";
import useLoading from "../../hooks/UseLoading";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useAlert from "../../hooks/UseAlert";
import SuccesNotification from "../../hooks/SuccesNotification";
import { Helmet } from "react-helmet";
import { emailRegex } from "../../utils/validations";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [forgotPasswordLabels, setForgotPasswordLabels] = useState([]);
  const [loader, showLoader, hideLoader] = useLoading();
  const navigate = useNavigate();
  var url = process.env.REACT_APP_API_URL;
  const [alert, alert_notify] = useAlert();
  const [successStatus, setSuccesStatus] = useState(false);
  var loggedUserData = JSON.parse(localStorage.getItem("userData"));

  const getForgotPasswordData = () => {
    // var forgot_email = document.getElementById("forgot_password_email").value;
    if (email !== "") {
      document
        .getElementById("input_forgot_email_required")
        .classList.add("d-none");
      setValidEmail(true);
    } else {
      document
        .getElementById("input_forgot_email_required")
        .classList.remove("d-none");
      setValidEmail(false);
    }
  };

  const handleForgotPasswordSubmit = (e) => {
    e.preventDefault();
    var forgot_password_url = "" + url + "/api/auth/forgot_password";

    // var forgot_email = document.getElementById("forgot_password_email").value;

    let body = {};
    if (emailRegex.test(email)) {
      body = {
        forgotemail: email,
      };
    } else {
      body = {
        phone_no: email.split(" ").join(""),
      };
    }
    var headers = {};

    if (validEmail) {
      showLoader();
      axios
        .post(forgot_password_url, body, headers)
        .then(function (response) {
          hideLoader();
          if (response.status === 200) {
            if (body?.forgotemail) {
              setSuccesStatus(true);
              setTimeout(() => {
                navigate("/login");
              }, 3000);
            } else {
              if (response.data?.data) {
              }
              navigate("/register-ghana/verify/" + email);
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate("/login");
          } else {
            alert_notify("error", err.response.data.Message);
          }
          hideLoader();
        });
    } else {
      console.log("FORGOT PASSWORD ERROR");
    }
  };

  const getForgotPasswordLabels = () => {
    var forgot_password_labels_url =
      "" + url + "/api/static_content/get_all_labels";
    var body = {
      page_name: "forgot password form",
    };
    axios
      .post(forgot_password_labels_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data.content;
          setForgotPasswordLabels(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getForgotPasswordLabels();
    localStorage.removeItem("userData");
    localStorage.removeItem("showReminder");
    if (loggedUserData) {
      navigate("/home");
    }
    setSuccesStatus(false);
  }, [loggedUserData]);
  var forgotClassVal =
    "btn_forgot_password_user btn_account_action btn_primary";

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MATCH MY SCORE | Forgot Password</title>
        <meta
          name="description"
          content="Forgot password to MATCH MY SCORE. Enetr your email and reset your password here."
        />
      </Helmet>
      <LoginHeader loginValue={false} />
      <AccountHeader
        headerValue={
          !forgotPasswordLabels.fp_form_page_title
            ? "Forgot Passowrd"
            : forgotPasswordLabels.fp_form_page_title.label_name
        }
        firstTextValue="To reset your password please enter the phone number associated with your account. "
        secondTextValue="An SMS will be sent to you with instructions on how to complete changing your password."
      />
      <div className="forgot_password_container primary-background">
        {successStatus ? (
          <SuccesNotification
            title="Email sent"
            content={
              "Please check your inbox or spam folder for a link to reset your password."
            }
          />
        ) : (
          <div className="page_content_center">
            <form
              id="forgot_password_form"
              onSubmit={handleForgotPasswordSubmit}
            >
              <div className="row justify-content-center">
                <div className="col-12 col-lg-5 col-xl-5 mt-4">
                  <input
                    type="email"
                    className="forgot_password_email"
                    maxLength={200}
                    placeholder={
                      !forgotPasswordLabels.fp_form_email_label
                        ? "Email"
                        : forgotPasswordLabels.fp_form_email_label.label_name
                    }
                    id="forgot_password_email"
                    style={{ width: "100%" }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <span
                    className="required_message d-none"
                    id="input_forgot_email_required"
                  >
                    {!forgotPasswordLabels.err_fp_form_email_req
                      ? "Email is required"
                      : forgotPasswordLabels.err_fp_form_email_req.label_name}
                  </span>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-12 col-lg-5 col-xl-5 mt-4 d-flex reset_password_container">
                  <ButtonPrimary
                    classValue={forgotClassVal}
                    textValue={
                      !forgotPasswordLabels.fp_form_submit_btn
                        ? "Reset Password"
                        : forgotPasswordLabels.fp_form_submit_btn.label_name
                    }
                    onClick={getForgotPasswordData}
                    // d={
                    //   !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                    //     email
                    //   )
                    // }
                  />
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
      <Footer />
      {loader}
      {alert}
    </React.Fragment>
  );
}
