import React, {useEffect, useState} from 'react'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import GeneralInformation from '../profile/GeneralInformation'
import ChangePassword from '../profile/ChangePassword'
import ProfileAccount from '../account/ProfileAccount'
import axios from 'axios'

export default function Profile() {
    const [profileState, setProfileState] = useState(true)
    const [generalInformationLabels, setGeneralInformationLabels] = useState([])
    var url = process.env.REACT_APP_API_URL;

    const handleProfileState = () => {
        setProfileState(!profileState)
    }

    const getProfileLabels = (page_name) =>{
        var profile_labels_url = ""+url+"/api/static_content/get_all_labels"
        var body = {
          "page_name" : page_name
        }
        axios.post(profile_labels_url, body)
        .then((response) =>{
          if(response.status === 200){
            var data = response.data.Data.content;
            setGeneralInformationLabels(data)
          }
        })
        .catch((err)=>{
          console.log(err)
        })
    }

    useEffect(()=>{
        getProfileLabels('update profile form')
    }, [])
    return (
        <React.Fragment>
            <Header/>
            <ProfileAccount  headerValue={
              !generalInformationLabels.update_form_my_profile_label ? 'My Profile' : generalInformationLabels.update_form_my_profile_label.label_name
            } />
            { profileState ? <GeneralInformation handleClick={handleProfileState} labels={generalInformationLabels}/> :  <ChangePassword labels={generalInformationLabels}/>}
            <Footer/>
        </React.Fragment>
    )
}
