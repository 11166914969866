import React, { useState, useEffect } from "react";
import ButtonPrimary from "../../buttons/ButtonPrimary";
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import "../sports/Challange.css";
import axios from "axios";
import UseLoading from "../../../hooks/UseLoading";
import UseAlert from "../../../hooks/UseAlert";
import moment from "moment";
import Timer from "./Timer";
import ButtonSecondary from "../../buttons/ButtonSecondary";
import { kFormatter } from "../../../utils";

export default function Challange() {
  const [searchParams] = useSearchParams();
  const [selectedLeague, setSelectedLeague] = useState("");
  const [sessionKey, setSessionKey] = useState("");
  const [reload, setReload] = useState(true);
  const [loader, showLoader, hideLoader] = UseLoading();
  const [competitions, setCompetitions] = useState([]);
  const [alert, alert_notify] = UseAlert();
  const { search, pathname } = useLocation();
  const { leagueId, sportId, contestId } = useParams();
  if (sportId == 13) {
    var challenges_titles = [
      "Tournament",
      "Style",
      "Live",
      "First Prize",
      "Prize Pool",
      "Entry Fee",
      "Entries",
    ];
  } else {
    var challenges_titles = [
      "League",
      "Style",
      "Live",
      "First Prize",
      "Prize Pool",
      "Entry Fee",
      "Entries",
    ];
  }

  const navigate = useNavigate();
  var url = process.env.REACT_APP_API_URL;
  useEffect(() => {
    console.log(search);
    const match = search.match(/leauge_id=(.*)/);
    const league_id = match?.[1];
    var session_key = localStorage.getItem("session_key");
    var content_url = `${url}/api/lobby/fetch_contest`;
    setSessionKey(session_key);

    const body = {
      league_id: leagueId,
      sport_id: sportId,
      contest_id: contestId,
    };
    const headers = { session_key: session_key };
    showLoader();
    axios
      .post(content_url, body, { headers })
      .then((response) => {
        if (response.status === 200) {
          setCompetitions(response.data.Data.contest);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          sessionStorage.setItem("back_url", window.location.pathname);
          if (searchParams.get("affiliate")) {
            localStorage.setItem("affiliateCompetition", pathname);
            navigate(`/register?affiliate=${searchParams.get("affiliate")}`);
          } else {
            navigate("/login");
          }
          localStorage.removeItem("session_key");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  }, [leagueId, reload]);

  // Join Contest
  const joinContest = (
    contest_id,
    league_id,
    contest_size,
    entry_fee,
    contest_uid,
    sport_id
  ) => {
    var join_contest_url = `${url}/api/lobby/join_contest`;
    const body = {
      contest_id: contest_id,
      league_id: league_id,
      entry_fee: entry_fee,
      contest_size: contest_size,
    };

    const headers = { session_key: sessionKey };

    showLoader();
    axios
      .post(join_contest_url, body, { headers })
      .then((response) => {
        hideLoader();
        if (response.status === 200 && response.data.ResponseCode == 200) {
          var data = response.data.Data;
          alert_notify("success", data.message);
          setTimeout(() => {
            if (sport_id != 13) {
              navigate("/gamecard/" + sport_id + "/" + contest_uid);
            } else {
              navigate("/golf/gamecard/" + sport_id + "/" + contest_uid);
            }
          }, 1500);
        }
      })
      .catch((err) => {
        console.log({ err });
        if (err.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("session_key");
        } else {
          if (
            err.response.data.Message.includes(
              "You have already submitted the maximum number of entries allowed"
            )
          ) {
            alert_notify("success", "returning to competition now");

            setTimeout(() => {
              if (sport_id != 13) {
                navigate("/gamecard/" + sport_id + "/" + contest_uid);
              } else {
                navigate("/golf/gamecard/" + sport_id + "/" + contest_uid);
              }
            }, 2000);
          } else {
            alert_notify("error", err.response.data.Message);
            setTimeout(() => {
              setReload(!reload);
            }, 1500);
          }
        }
        hideLoader();
      });
  };

  return (
    <React.Fragment>
      <div className="my_challenge_container">
        <div className="page_center">
          <div className="challenges mt-5">
            <div className="row challenges_icons_row">
              <span className="public_icon text-white">
                <img src="/assets/icons/public.svg" alt="public-icon" />
                Public
              </span>
              <span className="private_icon text-white">
                <img src="/assets/icons/private.svg" alt="public-icon" />
                Private
              </span>
            </div>
            <div
              className="row challenges_titles text-center"
              style={{ borderRadius: "10px" }}
            >
              <div className="challanges_icons_col"></div>
              {challenges_titles.map((title, index) => (
                <div
                  className={`col ${
                    index == 0
                      ? "tournament_league_name"
                      : index == 1
                      ? "competition_style"
                      : ""
                  }`}
                >
                  {title}
                </div>
              ))}
              <div className="col"></div>
            </div>
            {competitions.length > 0 ? (
              competitions.map((value, index) => {
                var startDateTimeUtc = moment.utc(value.start_at);
                var startLocalDate = startDateTimeUtc.local();
                var endDateTimeUtc = moment.utc(value.end_at);
                if (value.used_adhoc == "0") {
                  var prize_pool = (
                    value.prize_pool -
                    (value.prize_pool * Number(value.site_rake)) / 100
                  ).toFixed(2);
                } else {
                  var prize_pool = value.prize_pool;
                }
                return (
                  <div
                    className="row challenge transaction m-0 mb-2"
                    id={"challange_row_" + index}
                    data-contestid={value.contest_id}
                    data-contestsize={value.size}
                    data-leagueid={value.league_id}
                    data-entryfee={value.entry_fee}
                    data-contestuid={value.contest_uid}
                    data-sportid={value.sport_id}
                    row-index={index}
                    key={index}
                    style={{ borderRadius: "10px" }}
                  >
                    <div className="challanges_icons_col d-flex justify-content-center">
                      <img
                        src="/assets/icons/public_primary.svg"
                        alt="public-icon"
                      />
                    </div>
                    <div className="col d-flex text-center tournament_league_name">
                      <span className="transaction_title col-5 col-lg-12 col-xl-12">
                        {challenges_titles[0]}
                      </span>
                      <span className="transaction_value col-8 col-lg-12 col-xl-12 f-bold flex-fill">
                        {value.league_name}
                      </span>
                    </div>
                    <div className="col d-flex text-center competition_style">
                      <span className="transaction_title col-5 col-lg-12 col-xl-12">
                        {challenges_titles[1]}
                      </span>
                      <span className="transaction_value col-7 col-lg-12 col-xl-12 f-bold">
                        {value.style === null || value.style === ""
                          ? "-"
                          : value.style}
                      </span>
                    </div>
                    <div className="col d-flex text-center">
                      <span className="transaction_title col-5 col-lg-12 col-xl-12">
                        {challenges_titles[2]}
                      </span>
                      <span className="transaction_value col-7 col-lg-12 col-xl-12 f-bold">
                        <Timer expiryTimestamp={startLocalDate} />
                      </span>
                    </div>
                    <div className="col d-flex text-center">
                      <span className="transaction_title col-5 col-lg-12 col-xl-12">
                        {challenges_titles[3]}
                      </span>
                      <span className="transaction_value col-7 col-lg-12 col-xl-12 f-bold">
                        €{" "}
                        {(JSON.parse(value.prize_composition)["1"] / 100) *
                          prize_pool}
                      </span>
                    </div>
                    <div className="col d-flex text-center">
                      <span className="transaction_title col-5 col-lg-12 col-xl-12">
                        {challenges_titles[4]}
                      </span>
                      <span className="transaction_value col-7 col-lg-12 col-xl-12 f-bold">
                        € {prize_pool}
                      </span>
                    </div>
                    <div className="col d-flex text-center">
                      <span className="transaction_title col-5 col-lg-12 col-xl-12">
                        {challenges_titles[5]}
                      </span>
                      <span className="transaction_value col-7 col-lg-12 col-xl-12 f-bold">
                        € {value.entry_fee}
                      </span>
                    </div>
                    <div className="col d-flex text-center">
                      <span className="transaction_title col-5 col-lg-12 col-xl-12">
                        {challenges_titles[6]}
                      </span>
                      <span className="transaction_value col-7 col-lg-12 col-xl-12 f-bold">
                        {kFormatter(value.total_user_joined)} /{" "}
                        {kFormatter(value.size)}
                      </span>
                    </div>
                    <div className="col d-flex text-center justify-content-center">
                      <ButtonSecondary
                        onClick={(e) =>
                          joinContest(
                            value.contest_id,
                            value.league_id,
                            value.size,
                            value.entry_fee,
                            value.contest_uid,
                            value.sport_id
                          )
                        }
                        classValue="btn_primary btn-join"
                        textValue="ENTER"
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <h5 className="no_available_leagues">
                We don't have competitions for this league right now
              </h5>
            )}
          </div>
        </div>
      </div>
      {alert}
      {loader}
    </React.Fragment>
  );
}
