import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ImageContainer from "../image-container/ImageContainer";
import LoginHeader from "../login/LoginHeader";
import "./our_mission.css";
import axios from "axios";
import Parser from "html-react-parser";
import { useNavigate } from "react-router-dom";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import { Helmet } from "react-helmet";

export default function OurMission() {
  const [loggedStatus, setLoggedStatus] = useState("");
  const [ourMissiontext, setOurMissionText] = useState("");
  const [ourMissionName, setOurMissionName] = useState("");
  var url = process.env.REACT_APP_API_URL;
  const [loader, showLoader, hideLoader] = useLoading();
  const navigate = useNavigate();
  const [alert, alert_notify] = useAlert();

  useEffect(() => {
    var logged_status = localStorage.getItem("user_status");
    if (logged_status === "unlogged") {
      setLoggedStatus("unlogged");
    } else {
      setLoggedStatus("logged");
    }

    var our_mission_ulr =
      "" + url + "/api/static_content/get_all_static_content";
    const body = { page_meta_key: "our_mission" };
    showLoader();
    axios
      .post(our_mission_ulr, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          var page_content = data.content.page_content;
          setOurMissionText(page_content);
          var page_name = data.content.page_name;
          setOurMissionName(page_name);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  }, [url]);
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MATCH MY SCORE | Mission</title>
        <meta
          name="description"
          content="Our philosophy is to provide players with a revolutionary, dynamic, entertaining and immersive product that will keep them engaged throughout the length of each competition."
        />
      </Helmet>
      {loggedStatus === "logged" ? <Header /> : <LoginHeader />}
      <ImageContainer />
      <div className="our_mission_container row m-0 justify-content-between">
        <div className="page_content_center">
          <div className="our_mission_title col-md-12">
            <h2 className="text-uppercase">{Parser(ourMissionName)}</h2>
          </div>
          <div className="our_mission_content col-md-12">
            {Parser(ourMissiontext)}
            {/* <div className="mt-3">
                            Our philosophy is to provide players with a revolutionary, dynamic, entertaining and immersive product that will keep them engaged throughout the length of each competition. Our unique functionality coupled with our joker and ace wildcards ensure players are never out of the running to win a prize. We wanted to deliver a product that finally bridges the divide between traditional betting, fantasy sports and lotteries by incorporating the optimum features of each.
                        </div>
                        <div className="mt-3">
                            We believe the game's interactive nature, coupled with the ability to generate big global jackpots for low stakes and bespoke functionality, ensures excitement for all players who, regardless of their favourite sport are fully engaged throughout the duration of each competition, and by interacting with our live leaderboards will always know where they stand in a competition, and what they need to do to grab a cash prize, or cement their place at the top.
                        </div>
                        <div className="mt-3">
                            We have installed a controlled entry & staking system to optimise responsible gambling conditions and ensure a level playing field for all. We do not believe the 'sharks' should have free reign to swallow up the 'minnows'.
                        </div>
                        <div className="mt-3">
                            We are also huge believers in grass roots sports. Our model is a fantastic tool for clubs to generate much needed funds by setting up their own exclusive competitions for their members and players to enter, please contact <b>info@toppredictor.com</b> for further details.
                        </div> */}
          </div>
        </div>
      </div>
      <Footer />
      {loader}
      {alert}
    </React.Fragment>
  );
}
